import React, {useEffect, useState, useRef, forwardRef} from 'react';



function useOutsideAlerter(ref, call) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      call();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const Select = (props, context) => {
  const inputSearch = useRef(null);
  const {id, options, multiSelect, selected, onChange, optionValue, optionLabel, addAll, placeholder, style, maxHeight} = props;
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  useOutsideAlerter(wrapperRef, () => setOpen(false));

  Select.handleClickOutside = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) setOpen(false);
  };

  useEffect(() => {
    if(open && inputSearch.current) inputSearch.current.focus();
  }, [open])

  let optionsT = [...options];
  optionsT = addAll ? [...[{[optionValue]: 0, [optionLabel]: 'ALL'}], ...optionsT] : optionsT;
  let selectedObj = optionsT.filter(o => multiSelect ? selected.includes(o[optionValue]) : o[optionValue] === selected);
  if (!multiSelect)
    selectedObj = selectedObj.length ? selectedObj[0] : {[optionLabel]: placeholder || 'Select'};

  optionsT = optionsT.sort((a, b) => {
    if (!multiSelect) {
      return -((a[optionValue] === selected && 1) - (b[optionValue] === selected && 1));
    } else {
      return -((selected.includes(a[optionValue]) && 1) - (selected.includes(b[optionValue]) && 1));
    }
  });

  return (
    <>
      {/* <div class="down-arrow"><img src="img/down-select.svg" alt=""></div> */}
      {/* <select id={id} onChange={val => onChange(val)} value={selected}>
        {optionsT.map((option, i) => line(option, i))}
      </select> */}
      <div ref={wrapperRef} className="ms-parent " style={{...{width: '210px'},...style}}>
        <button onClick={() => setOpen(!open)} type="button" className="ms-choice">
          <span>{multiSelect ? (selectedObj.length < 5 ? selectedObj.map(el => el[optionLabel]).join(", ") : `${selectedObj.length} items selected.`) : selectedObj[optionLabel]}</span>
          <div className="open" />
        </button>
        {multiSelect && selected[0] !== 0 && <button className={"select-close-icon"} onClick={() => onChange({})}>
          <i className="fas fa-times" style={{color: "red", cursor: "pointer"}} />
        </button>}
        {open ? (
          <div className="ms-drop bottom" style={{display: 'block'}}>
            <div className="ms-search">
              <input
                ref={inputSearch}
                type="text"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                value={search}
                onChange={val => setSearch(val.target.value)}
              />
            </div>
            <ul style={{maxHeight: maxHeight || '250px'}}>
              {optionsT
                .filter(o =>
                  o[optionLabel]
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase()),
                )
                .map((o, i) => (
                  <li
                    key={i}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!multiSelect) {
                        setOpen(false);
                      }
                      if (o[optionValue] === 0) {
                        onChange({})
                        return;
                      }
                      onChange({...o, value: o[optionValue], label: o[optionLabel]}, o[optionValue], o);
                    }}
                    className={`hide-radio ${(!multiSelect && selected === o[optionValue]) || (multiSelect && selected.includes(o[optionValue])) ? 'selected' : ''}`}>
                    <label>
                      <input type="radio" value={o[optionValue]} data-name="selectItem" defaultChecked="checked" />
                      <span>{o[optionLabel]}</span>
                    </label>
                  </li>
                ))}

              {/* <li className="ms-no-results" style={{display: 'none'}}>
              No matches found
            </li> */}
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
};

Select.defaultProps = {
  onChange: [],
  options: [],
  optionValue: 'value',
  optionLabel: 'label',
};

export default Select;
