const AppHelpers = {
  isPositiveFloat(n) {
    return Math.sign(n);
  },

  priceFormat(price,min=0,max=12) {

    var dp = Math.floor(6 - Math.log10(Math.abs(price)));

    dp = (dp < min ? min : (dp > max ? max : dp));

    return price.toFixed(dp).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  },

  numberFormat(number) {
    var SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];
    // what tier? (determines SI symbol)
    var tier = (Math.log10(number) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number && number.toFixed(2);

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return (scaled || 0).toFixed(2) + ' ' + (suffix || '');
  },

  // Counts precision of the number (When used increases values with input step arrows rounding makes some problems)
  countPrecision(a) {
    if (typeof a !== "number" || !isFinite(a)) return 0;
    let e = 1, p = 0;
    while (Math.round(a * e) / e !== a) { e *= 10; p++; }
    return p;
  },

  priceChange(oldPrice, newPrice) {
    if (oldPrice) {
      if (oldPrice > newPrice) {
        return '#C13A39';
      } else if (oldPrice < newPrice) {
        return '#5D8B43';
      }
    }
  },
  socketSubs(arr) {
    const res = [];
    arr.forEach(row => {
      let arr = row.split('~');
      !res.find(i => i && i === (arr[0] + '~' + arr[2]).replace('5', '11')) && res.push((arr[0] + '~' + arr[2]).replace('5', '11'));
    });
    return res;
  },

  /**
   * Find time difference in user readable manner (5 months ago etc..)
   *
   * @param target Target time to compare to
   * @return {string}
   */
  timeDifference(target) {
    const msPerMinute = 60 ;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    // Find difference between now and target time
    const elapsed = Date.now()/1000 - target;


    if (elapsed < msPerMinute) {
      return Math.round(elapsed/1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
      return Math.round(elapsed/msPerMinute) + ' minutes ago';
    }

    else if (elapsed < msPerDay ) {
      return Math.round(elapsed/msPerHour ) + ' hours ago';
    }

    else if (elapsed < msPerMonth) {
      return Math.round(elapsed/msPerDay) + ' days ago';
    }

    else if (elapsed < msPerYear) {
      return Math.round(elapsed/msPerMonth) + ' months ago';
    }

    else {
      return Math.round(elapsed/msPerYear ) + ' years ago';
    }
  }
};

export default AppHelpers;
