import React from 'react';
import {Link} from 'react-router-dom';
import {useAuth0} from "../../../helpers/Auth0";
import Utils from "../../../helpers/Utils";

const isMobile = Utils.checkMobile();

const TopUserMenu = () => {
  const {isAuthenticated, user, logout, loginWithRedirect} = useAuth0();

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return (
    <div className="user-menu">
      {isAuthenticated && (
        <a style={{cursor: 'pointer'}}>
          <div className="user-menu-img" style={{width: 45}}>
            <img id={'userImage'} src={user.picture || '/img/user-default.svg'} alt="" />
          </div>
          <div
            className="user-name"
            style={{display: 'inline-block', width: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
            {isMobile ? '' : user.username || (user.email && user.email.split("@")[0]) || ''}
            <img className="actionable-img" src="/img/arrow-down.svg" alt="" />
          </div>
        </a>
      )}
      {isAuthenticated && (
        <div className="user-menu-dropdown">
          <ul>
            <li>
              <Link to={'/profile'}>Settings</Link>
            </li>
            <li>
            <Link to={'/watch-list'}>Watchlists</Link>
            </li>
            <li>
              <Link to={'/exchanges'}>Exchanges</Link>
            </li>
            <li>
              <a
                onClick={() => {
                  logout();
                }}>
                <img src="/img/logout.svg" alt="" />
                Logout
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default TopUserMenu;
