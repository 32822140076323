import React, { Component } from 'react'

export default class ContactForm extends Component {
    render() {
        return (
            <div className="contact-forms">
                <div className="contact-forms-inner">
                    <h3 className="contact-head">Contact Form</h3>
                    {/* <p class="contact-text">Nulla dapibus quis sapien in faucibus. Curabitur blandit, velit eu varius pulvinar, massa justo pellentesque massa, eu dapibus erat enim vitae felis</p> */}
                    <div className="form-area">
                        <div className="border-bottom-input">
                            <input type="text" placeholder="Email" />
                        </div>
                        <div className="border-bottom-input">
                            <input type="text" placeholder="Subject" />
                        </div>
                        <div className="border-bottom-input">
                            <textarea cols={30} rows={5} placeholder="Message" defaultValue={""} />
                        </div>
                    </div>
                    <div className="captcha">
                        <img src="img/captcha.png" alt="" />
                    </div>
                    <div className="white-btn">
                        <button className="skew-anim-black" type="submit">SEND</button>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        )
    }
}
