import AppHelpers from "../../helpers/AppHelpers";
import Set from "sorted-set";
import Api from "../index";
import moment from "moment";

const getOrderBookUrl = () => "wss://ws-feed.pro.coinbase.com";
class CoinBaseApi {

    constructor() {}

    generateSet(snapshot, bids) {
        const set = new Set({
            // what the primary index should be (defaults to identity function)
            hash: function(entry) {
                return entry[0];
            },
            // how to order the set (defaults to string-friendly comparator)
            compare: function(a, b) {
                // descending numeric sort
                return a[0] - b[0];
            }
        });

        // Feed a map function to set for to be used in front-end
        if (bids) {
            set.map = function map(callback) {
                return this.values().slice(this.values.length-20).map(callback);
            };
        } else {
            set.map = function map(callback) {
                return this.values().slice(0, 20).map(callback);
            };
        }


        snapshot.forEach(el => {
            // Generate total for data
            el[2] = AppHelpers.priceFormat(el[0] * el[1]);
            set.add(el);
        });

        return set;
    }

    updateBook(updates, handler, type) {
        handler(oldOrderbook => {
            let isChanged = false;
            // Apply updates
            updates.forEach( el => {
                if (el[1] == 0) { // Left as == because it gives "0.00000" == 0 true
                    isChanged = isChanged || oldOrderbook.del(el);
                } else if ((type === 'buy' && el[0] > oldOrderbook.head()[0]) || (type === 'sell' && el[0] < oldOrderbook.tail()[0]) || oldOrderbook.length < 20) { // Generate total data and apply
                    el[2] = AppHelpers.priceFormat(el[0] * el[1]);
                    isChanged = true;
                    oldOrderbook.add(el);
                }
            });

            // Return updated handler
            return isChanged ? Object.assign(new Set(), oldOrderbook) : oldOrderbook;
        });
    }

    subscribeOrderbook(symbol, buyHandler, sellHandler) {
        const conn = new WebSocket(getOrderBookUrl(symbol));
        conn.onclose = function () {};
        conn.onopen = function () {
            const message = {
                "type": "subscribe",
                "product_ids": [ symbol ],
                "channels": [
                    "level2"
                ]
            }
            conn.send(JSON.stringify(message));
        }
        this.conn = conn;
        this.buyHandler = buyHandler;
        this.sellHandler = sellHandler;

        this.conn.onmessage = ((event) => {
            // Parse message to JSON
            const json = JSON.parse(event.data);
            // Subscription ack message
            if (json.type === 'subscriptions') return;

            // If message type is snapshot generate sets and set them
            if (json.type === 'snapshot') {
                const bids = this.generateSet(json.bids.slice(0, 30), true);
                const asks = this.generateSet(json.asks.slice(0, 30));
                this.buyHandler(bids);
                this.sellHandler(asks);
            } else {
                // If updates came execute updates
                const buyUpdates = json.changes.filter(el => el[0] === 'buy').map(el => [el[1], el[2]]);
                const sellUpdates = json.changes.filter(el => el[0] === 'sell').map(el => [el[1], el[2]]);

                // Update bids orderbook
                if (buyUpdates.length > 0) {
                    this.updateBook(buyUpdates, this.buyHandler, 'buy');
                }

                // Update sell orderbook
                if (sellUpdates.length > 0) {
                    this.updateBook(sellUpdates, this.sellHandler, 'sell');
                }
            }
        });
    }

    getTrades(symbol, limit) {
        return Api.getPromise(`products/${symbol}/trades`, "https://api-public.sandbox.pro.coinbase.com/").then(res => {
            return res.slice(0, limit).map(el => {
                // Map trades response to common type (CCXT-Rest)
                return {
                    price: el.price,
                    amount: el.size,
                    timestamp: moment(el.time).unix(),
                    side: el.side
                };
            });
        }).catch(err => {
            console.error("Error while getting Binance trades", err);
            return [];
        });
    }

    destroyConnection() {
        this.conn && this.conn.close();
    }
}

export default CoinBaseApi;
