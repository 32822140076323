import io from 'socket.io-client';
import Links from "../constants/Links";

const socket = io.connect(Links.REAL_TIME_SERVER);
const SocketHelpers = {
  /**
   * Subscribe to given data array
   *
   * @param data E.g. List of symbols to subscribe
   */
  subscribe(data) {
    socket.emit('m', {action: 'subscribe', data: data});
  },

  /**
   * Handle messages directly with callback. This is required for removing specific callback. E.g. remove
   * index real time updates when you leave that page. Right now all the coin updating real time time data handlers
   * are piling up.
   *
   * @param data Where to subscribe
   * @param callback Callback to handle messages
   */
  coinsRaw(data, callback) {
    this.subscribe(data);
    socket.on('m', callback);
  },

  /**
   * Close connections to specific listeners with given callback
   *
   * @param data Data to be unsubscribed from
   * @param callback Callback to handle messages
   */
  close(data, callback) {
    // Clean listeners
    socket.emit('m', {action: 'unsubscribe', data: data });
    socket.off('m', callback);
  },
};

export default SocketHelpers;
