import React, {Component} from 'react';
import {connect} from 'react-redux';
import ContactForm from '../../components/Forms/ContactForm';
import SquareBannerArea from '../../components/BannerAreas/SquareBannerArea';

export class Contact extends Component {
  render() {
    return (
      <section className="contact-area padding-sm-container">
        <div className="general-header">Contact</div>
        <div className="contact-form-and-banners">
          <ContactForm />
          <SquareBannerArea />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    state,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contact);
