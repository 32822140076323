import React, {useEffect, useState} from 'react';
import {useAuth0} from "../../helpers/Auth0";

const StepUpAuthentication = props => {
    const { checkScope, component } = props;
    const { stepUpAuthorize, scopes } = useAuth0();
    return (
        (scopes && scopes.includes(checkScope)) ? component :
            <div style={{height: "80vh", width: "100vw", display: "flex"}}>
                <section style={{textAlign: 'center', minHeight: "100%", width: "100%", display: "flex", backgroundImage: "url('/img/blur_upgrade_your_plan.png')"}} className="contact-area padding-sm-container">
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column' ,margin: "auto", color: '#fff'}} className="contact-form-and-banners">
                        <h4>To access this page you need to authenticate your account with second factor. Please click 2FA Authentication to continue...</h4>
                        <h3>
                            <a style={{cursor: "pointer", marginRight: "1em"}} onClick={() => stepUpAuthorize()}>2FA Authentication</a>
                            <a style={{cursor: "pointer", marginLeft: "1em"}} target="_blank" rel="noopener noreferrer" href="https://cryptoindexseries.com">Contact Us</a>
                        </h3>
                    </div>
                </section>
            </div>

    );
};

export default StepUpAuthentication;
