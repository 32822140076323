import React from 'react';
import {useAuth0} from "../../helpers/Auth0";


const NotAuthorizedPage = props => {
    const {logout} = useAuth0();
    return (
        <section style={{textAlign: 'center', minHeight: "100%", marginTop: "10px", backgroundImage: "url('/img/blur_upgrade_your_plan.png')"}} className="contact-area padding-sm-container">
            <div className="general-header"><h2></h2></div>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', color: '#fff'}} className="contact-form-and-banners">
                <h4>You are not authorized.</h4>
                <h3>
                    <a style={{cursor: "pointer", marginRight: "1em"}} onClick={() => logout()}>Logout</a>
                    <a style={{cursor: "pointer", marginLeft: "1em"}} target="_blank" rel="noopener noreferrer" href="https://cryptoindexseries.com">Contact Us</a>
                </h3>
            </div>
        </section>
    );
};

export default NotAuthorizedPage;
