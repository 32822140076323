import React from 'react';
import UserApi from "../../../../api/UserApi";

const ConfirmationAlert = props => {
  const {item, show, close, fetchAlerts} = props;

  const onDelete = () => {
    if (item.alert_id) {
      UserApi.deleteAlert(item.alert_id).then(res => {
        if (fetchAlerts) fetchAlerts();
        close();
      });
    }
  };
  return (
    <div className={`general-modal ${show ? 'active' : ''}`} id="delete-modal">
      <div className="vAlignWr">
        <div className="vAlign">
          <div className="white-modal-inner set-alert">
            <div className="modal-blue-header">
              Delete
              <div onClick={close} className="close-modal" data-target="delete-modal">
                <img src="/img/close-white.svg" alt="" />
              </div>
            </div>
            <div className="modal-white-body">
              <div className="section-header">Are you sure to delete this alert.</div>
            </div>
            <div className="modal-gray-footer">
              <div className="form-buttons">
                <div className="form-flex">
                  <div className="form-flex-inner">
                    <div className="light-gray-btn">
                      <button onClick={close} className="close-modal" data-target="set-price-alert">
                        NO
                      </button>
                    </div>
                  </div>
                  <div className="form-flex-inner">
                    <div className="light-blue-btn">
                      <button onClick={onDelete}>YES</button>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationAlert;
