import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import {withRouter} from 'react-router-dom';
import UserApi from "../../api/UserApi";
import ExchangeConfirmation from "./ExchangeConfirmation";
import Loading from "../../components/Loading";
import ExchangeCreate from "./ExchangeCreate";
import {checkQuotaAccess, useAuth0} from "../../helpers/Auth0";
import {toastr} from "react-redux-toastr";

const Exchanges = props => {
    const {location, history, exchanges} = props;
    const [showP, setShowP] = useState(false);
    const [showC, setShowC] = useState(false);
    const [data, setData] = useState({items: [], loading: true, selected: {}});
    const {user} = useAuth0();

    // Extract navigated exchange value
    const locState = location && location.state && location.state.exchange ? location.state : null;

    useEffect(() => {
        if (locState && !data.loading) {
            setShowP(true);
        }
    }, [locState, data.loading]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setData(d => ({...d, loading: true}));
        UserApi.readKeys().then( d => {
            // Fill exchange names
            const mapped = d.map(el => {
               el.exchange_name = exchanges[el.exchange].name;
               return el;
            });

            setData({items: mapped, loading: false });
        });
    };

    const onUpdate = item => {
        setData(d => ({...d, selected: item}));
        setShowP(true);
    };

    const onDelete = item => {
        setData(d => ({...d, selected: item}));
        setShowC(true);
    };

    return (
        <React.Fragment>
            <section className="dashboard padding-sm-container">
                <Breadcrump />
                <div className="flex-row">
                    <div className="flex-width-left" style={{width: "100%"}}>
                        <div className="gray-container alerts-price-alert">
                            <div className="section-header">Exchange Connections</div>
                            <div className="pa-inner">
                                <div className="scroll-table" id="beauty-scroll-table">
                                    <Loading loading={data.loading}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{color: '#fff'}}>Name</th>
                                                    <th style={{color: '#fff'}}>Exchange</th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.items.map((d, i) => (
                                                <tr key={i}>
                                                    <td>{d.key_name}</td>
                                                    <td style={{color: '#fff'}}>{d.exchange_name}</td>
                                                    <td className="edit-delete-btn" style={{width: "120px", cursor: "pointer"}}>
                                                        <button onClick={() =>
                                                            history.push({
                                                                pathname: '/trade',
                                                                state: {exchange: d.exchange}
                                                            })
                                                        } className="open-modal-event">
                                                            <img className="actionable-img" src="/img/euro.svg" alt="" />
                                                        </button>
                                                        <button onClick={() => onUpdate(d)} className=" open-modal-event" style={{marginRight: "15px"}}>
                                                            <img className="actionable-img" src="/img/edit.svg" alt="" />
                                                        </button>
                                                        <button onClick={() => onDelete(d)} className=" open-modal-event">
                                                            <img className="actionable-img" src="/img/delete.svg" alt="" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Loading>
                                </div>
                                <div className="green-border-btn">
                                    <a onClick={() => {
                                        const quota = checkQuotaAccess(user, 'exchange_connections', data.items.length);
                                        if (quota) {
                                            setShowP(true);
                                        } else {
                                            toastr.error("", `You cannot create new watchlist. Upgrade your plan (Remaining Quota: ${quota}).`)
                                        }
                                    }}
                                       className=" open-modal-event"
                                       data-target="set-price-alert">
                                        Add Exchange
                                    </a>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ExchangeCreate
                fetchKeys={fetchData}
                history={history}
                isNew={data.selected}
                locState={locState}
                show={showP}
                close={() => {
                    if (locState) history.replace('/exchanges');
                    setData(d => ({...d, selected: {}}));
                    setShowP(false);
                }}
            />
            <ExchangeConfirmation
                fetchKeys={fetchData}
                item={data.selected}
                show={showC}
                close={() => {
                    setData(d => ({...d, selected: {}}));
                    setShowC(false);
                }}
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    // Convert exchanges to map of id to exchange data
    const exchangeMap = {};
    state.meta.connectable_exchanges.filter(el => el.is_connectable).forEach(el => exchangeMap[el.id] = el);

    return {
        exchanges: exchangeMap
    }
};

export default connect(mapStateToProps)(withRouter(Exchanges));
