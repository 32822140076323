import React from 'react';
import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';
import {useAuth0} from "../helpers/Auth0";
import Utils from "../helpers/Utils";

const isMobile = Utils.checkMobile();

const TradingView = props => {
	const {user} = useAuth0();
	return (
		isMobile?
			<div style={{width: "100%", height: "370px"}}>
				<TVChartContainer ticker={props.ticker} customFilter={props.customFilter} pricescale={props.pricescale}
								  symbol={props.symbol} exchange={props.exchange} latest_price={props.latest_price}
								  type={props.type} isNews={props.isNews} chart_type={user.preferences && user.preferences.chart_type} sentiment_type={user.preferences && user.preferences.sentiment_field}/>
			</div> :
			<div style={{width: "100%", height: "100%"}}>
				<TVChartContainer ticker={props.ticker} customFilter={props.customFilter} pricescale={props.pricescale}
								  symbol={props.symbol} exchange={props.exchange} latest_price={props.latest_price}
								  type={props.type} isNews={props.isNews} chart_type={user.preferences && user.preferences.chart_type} sentiment_type={user.preferences && user.preferences.sentiment_field}/>
			</div>
	);
}

export default TradingView;
