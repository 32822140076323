import React from 'react';
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

const HomeTableRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    row,
    weight,
  } = props;
  return (
     // Top Coins Page

     isMobile?
         // Top coins click coins
         // <tr onClick={rowClick}>
         <tr>
             <td style={{textAlign: 'left'}}>
                 {/*<div className="custom-icon-img">*/}
                 {/*    <img width="15" src={rowImage} alt="" />*/}
                 {/*</div>*/}
                 {name}
             </td>
             <td className={rowClass}>{price}</td>
             <td>{marketCap}</td>
             <td>{totalCoin}</td>
             <td>{vol24}</td>
             <td className={perClass}>{perTd}</td>
         </tr>

     :

    <tr onClick={rowClick}>
      <td style={{textAlign: 'left'}}>
        <div className="custom-icon-img">
          <img width="15" src={rowImage} alt="" />
        </div>
        {name}
      </td>
      <td className={rowClass}>{price}</td>
      <td>{marketCap}</td>
      <td>{totalCoin}</td>
      <td>{vol24}</td>
      <td className={perClass}>{perTd}</td>
    </tr>
  );
};

export default HomeTableRow;
