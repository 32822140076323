import React from 'react';

const WatchListRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    setShow,
    row,
  } = props;
  return (
    <tr>
      <td
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}>
        {/*<div className="icon-img">*/}
        {/*  <img src={rowImage} style={{width: 20, marginRight: "1em"}} alt="" />*/}
        {/*</div>*/}
        {symbol} / {exchange}
      </td>
      <td className={rowClass}>{price}</td>
      <td>{marketCap}</td> <td>{totalCoin}</td>
      <td>{vol24}</td>
      <td className={perClass}>{perTd}</td>
      <td className="edit-delete-btn">
        <button onClick={() => setShow(row.pairId)} className=" open-modal-event" data-target="delete-modal-wlitem">
          <img className="actionable-img" src="img/delete.svg" alt="" />
        </button>
      </td>
    </tr>
  );
};

export default WatchListRow;
