import React, { Component } from 'react'

export default class SquareBannerArea extends Component {
    render() {
        return (
            <div className="banner-area">
                <div className="banner-area-inner">
                    <div className="vAlignWr">
                        <div className="vAlign">
                            <div className="mid-text">Banner</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
