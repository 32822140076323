import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import Filter from '../../components/PageCoins/Filter';
import Indices from '../../components/Indices';
import Breadcrumps from '../../constants/Breadcrumps';
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";
import Utils from "../../helpers/Utils";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";
import {useAuth0} from "../../helpers/Auth0";

const isMobile = Utils.checkMobile();

const Coins = (props, context) => {
    const {user} = useAuth0();
    const [watchListShow, setWatchListShow] = useState(user.preferences.show_watchlist);
    const [filter, setFilter] = useState({});
    const [loading, setLoading] = useState(true);
    const [filterValue, setFilterValue] = useState(false);
    const [show, setShow] = useState(false);

    const searchT = filter => {
        setFilter(filter);
    };

    useEffect(() => {
        isMobile ? setFilterValue(false) : setFilterValue(true);
    }, []);

    const changeFilter = () => {
        setShow(true);
    }

    return (
        isMobile ?
            <div>
                <MobileNavBar/>
                <div>
                    {watchListShow ? <Watchlist/> : <></>}
                </div>
                <div className="mobile-Div">
                    <div className="row row-d" style={{paddingTop:"10px"}}>
                        <div className="col-xs-10 col-filter-top-l">
                            <p style={{margin:"5px 0px"}}>Advanced Search</p>
                        </div>
                        <div className="col-xs-2 col-filter-top-r" onClick={changeFilter}>
                            <img style={{margin:"5px 0px"}} src="/img/search.svg" alt="" height="30px"/>
                        </div>
                    </div>
                    <Filter show={show} loadingP={loading} {...props} search={setFilter} cancel={() => setShow(false)}/>
                </div>

                <div className="mobile-Div">
                    <Indices {...props} filter={filter} setLoadingP={setLoading}/>
                </div>
                <div style={{height: "80px"}}>

                    <p></p>
                </div>
            </div>
            :
            <React.Fragment>
                <section className="coin-table padding-container">
                    <div className="watchlist-all-page">
                        {watchListShow ? <Watchlist/> : <></>}
                    </div>

                    <Breadcrump links={Breadcrumps.coins}/>

                    <div id="filter-area-coins" onClick={changeFilter} style={{display: 'none'}}>
                        <p>Filter <i className="down-arrow-news"/></p>
                    </div>

                    {filterValue && <Filter loadingP={loading} {...props} search={searchT}/>}

                    <Indices {...props} filter={filter} setLoadingP={setLoading}/>
                </section>
            </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(Coins);
