import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Loading from '../../components/Loading';
import Confirm from '../../components/Confirm/Confirm';
import CoinRow from '../../components/CoinRow';
import Utils from '../../helpers/Utils';
import UserApi from "../../api/UserApi";
import Select from "../../components/Forms/Select";
import {toastr} from "react-redux-toastr";

const isMobile = Utils.checkMobile();

const WatchListTable = props => {
  const {watchlist, meta} = props;
  const [result, setResult] = useState({items: [], loading: true});
  const {allCoins, lists} = meta || {};
  const allCoinsT = Utils.prepareCoinSelections(allCoins);
  const currencyList =
      (lists && lists.key_currency_list && lists.key_currency_list.map(c => ({value: c[0], label: c[0], type: c[2] === 'FIAT' ? 'USD' : 'BTC'}))) || [];

  const [showAdd, setShowAdd] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState({symbol: null});
  const [to, setTo] = useState({value: null});

  useEffect(() => {
    setResult({items: [], loading: true});
    if (watchlist) {
      fetchPairs();
    } else {
      setResult({items: [], loading: false});
    }
  }, [watchlist]);

  const fetchPairs = () => {
    setResult({items: [], loading: true});
    UserApi.getPairs(watchlist.watchlist_id).then(pairs => {
      const data = pairs.map(p => {
        let sc = allCoinsT.filter(c => c.symbol === p.from_sym);
        sc = sc[0] || {};
        return {...sc, symbol: p.from_sym, exchange: p.to_sym, pairId: p.pair_id};
      });
      setLoading(false);
      setResult({items: data, loading: false});
    }).catch( ex => {
      console.error(ex);
      setResult({items: [], loading: false});
    });
  }

  const del = () => {
    UserApi.deletePair(watchlist.watchlist_id, show).then(res => {
      fetchPairs();
      setShow(false);
    }).catch(ex => {
      setShow(false);
    })
  };

  const create = () => {
    setLoading(true);
    UserApi.createPair(watchlist.watchlist_id, from.symbol, to.value).then(res => {
      toastr.success('', 'Successfully Saved');
      fetchPairs();
      setFrom({symbol: null});
      setTo({value: null});
      setShowAdd(false);
    }).catch(err => {
      setLoading(false);
      toastr.error('', 'Already in the list');
    });
  }

  return (
      isMobile?
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className="table-body table-responsive" >
              <Loading loading={result.loading}>
                <table className="wlt-inner">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Mkt.w Cap</th>
                    <th>Total Coins</th>
                    <th>24h Vol</th>
                    <th>Change</th>
                    <th />
                  </tr>
                  </thead>
                  <tbody>
                  {result.items.map((row, i) => (
                      <CoinRow key={i} type={'watchList'} rowClick={() => null} setShow={setShow} row={row} selectedExchange={{value: row.exchange}} />
                  ))}
                  </tbody>
                </table>
              </Loading>

              <Confirm show={show} ok={del} cancel={() => setShow(false)} />
              <div className="clearfix" />
            </div>
            <div className={`general-modal ${showAdd ? 'active' : ''}`} id="set-price-alert">
              <div className="vAlignWr">
                <div className="vAlign">
                  <div className="white-modal-inner set-alert">
                    <div className="modal-blue-header">
                      Add Pair
                      <div onClick={e => {e.stopPropagation(); setShowAdd(false)}} className="close-modal" data-target="set-price-alert">
                        <img src="img/close-white.svg" alt="" />
                      </div>
                    </div>
                    <div className="modal-white-body-watchlist">
                      <div className="general-tab-area">
                        <div className="general-tab-menu">
                          <ul>
                            <li className="active">
                              <a href="#" data-target="price-alert">
                                Add Watchlist Pair
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="general-tab-body-all">
                          <div className="general-tab-body active" id="price-alert">
                            <div className="set-price-alert">
                              <div className="calc-price-number">
                                <div className="input-table">
                                  <table>
                                    <tbody>
                                    <tr>
                                      <td style={{width: "1px", paddingTop: "10px", textAlign: "center"}}>
                                        <div className="spa-text">Coin</div>
                                      </td>
                                      <td colSpan={2}>
                                        <div className="sm-select-input" style={{padding: '0px'}}>
                                          <Select
                                              style={{width: "100%"}}
                                              selected={from.symbol || 0}
                                              options={allCoinsT}
                                              optionValue={'symbol'}
                                              placeholder={'Select'}
                                              maxHeight={'150px'}
                                              optionLabel={'value'}
                                              onChange={selected => setFrom(selected)}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "1px", paddingTop: "10px", textAlign: "center"}}>
                                        <div className="spa-text">Currency</div>
                                      </td>
                                      <td colSpan={2}>
                                        <div className="sm-select-input" style={{padding: '0px'}}>
                                          <Select
                                              style={{width: '100%'}}
                                              selected={to.value || 0}
                                              placeholder={'Select'}
                                              maxHeight={'100px'}
                                              options={currencyList}
                                              onChange={selected => setTo(selected)}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-gray-footer">
                      <div className="form-buttons">
                        <div className="form-flex">
                          <div className="form-flex-inner">
                            <div className="light-gray-btn">
                              <button onClick={e => {e.stopPropagation(); setShowAdd(false)}} className="close-modal" data-target="set-price-alert">
                                CANCEL
                              </button>
                            </div>
                          </div>
                          <div className="form-flex-inner">
                            <div className="light-blue-btn">
                              <button disabled={loading || !from.symbol || !to.value} onClick={create}>
                                SAVE
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="green-border-btn" style={{marginLeft: "auto", width: "150px", marginTop: "20px"}}>
              <a
                  onClick={() => {
                    setShowAdd(true);
                  }}
                  className="open-modal-event"
                  data-target="set-watch-list">
                Add Pair
              </a>
            </div>
          </div>
          :
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div className="table-body table-responsive" >
        <Loading loading={result.loading}>
          <table className="wlt-inner">
            <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Mkt.w Cap</th>
              <th>Total Coins</th>
              <th>24h Vol</th>
              <th>Change</th>
              <th />
            </tr>
            </thead>
            <tbody>
            {result.items.map((row, i) => (
                <CoinRow key={i} type={'watchList'} rowClick={() => null} setShow={setShow} row={row} selectedExchange={{value: row.exchange}} />
            ))}
            </tbody>
          </table>
        </Loading>

        <Confirm show={show} ok={del} cancel={() => setShow(false)} />
        <div className="clearfix" />
      </div>
      <div className={`general-modal ${showAdd ? 'active' : ''}`} id="set-price-alert">
        <div className="vAlignWr">
          <div className="vAlign">
            <div className="white-modal-inner set-alert" style={{width: "50vh"}}>
              <div className="modal-blue-header">
                Add Pair
                <div onClick={e => {e.stopPropagation(); setShowAdd(false)}} className="close-modal" data-target="set-price-alert">
                  <img src="img/close-white.svg" alt="" />
                </div>
              </div>
              <div className="modal-white-body-watchlist">
                <div className="general-tab-area">
                  <div className="general-tab-menu">
                    <ul>
                      <li className="active">
                        <a href="#" data-target="price-alert">
                          Add Watchlist Pair
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="general-tab-body-all">
                    <div className="general-tab-body active" id="price-alert">
                      <div className="set-price-alert">
                        <div className="calc-price-number">
                          <div className="input-table">
                            <table>
                              <tbody>
                              <tr>
                                <td style={{width: "1px", paddingTop: "10px", textAlign: "center"}}>
                                  <div className="spa-text">Coin</div>
                                </td>
                                <td colSpan={2}>
                                  <div className="sm-select-input" style={{padding: '0px'}}>
                                    <Select
                                        style={{width: "100%"}}
                                        selected={from.symbol || 0}
                                        options={allCoinsT}
                                        optionValue={'symbol'}
                                        placeholder={'Select'}
                                        maxHeight={'150px'}
                                        optionLabel={'value'}
                                        onChange={selected => setFrom(selected)}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{width: "1px", paddingTop: "10px", textAlign: "center"}}>
                                  <div className="spa-text">Currency</div>
                                </td>
                                <td colSpan={2}>
                                  <div className="sm-select-input" style={{padding: '0px'}}>
                                    <Select
                                        style={{width: '100%'}}
                                        selected={to.value || 0}
                                        placeholder={'Select'}
                                        maxHeight={'100px'}
                                        options={currencyList}
                                        onChange={selected => setTo(selected)}
                                    />
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-gray-footer">
                <div className="form-buttons">
                  <div className="form-flex">
                    <div className="form-flex-inner">
                      <div className="light-gray-btn">
                        <button onClick={e => {e.stopPropagation(); setShowAdd(false)}} className="close-modal" data-target="set-price-alert">
                          CANCEL
                        </button>
                      </div>
                    </div>
                    <div className="form-flex-inner">
                      <div className="light-blue-btn">
                        <button disabled={loading || !from.symbol || !to.value} onClick={create}>
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="green-border-btn" style={{marginLeft: "auto", width: "150px", marginTop: "20px"}}>
        <a
            onClick={() => {
              setShowAdd(true);
            }}
            className="open-modal-event"
            data-target="set-watch-list">
          Add Pair
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    meta: state.meta,
  };
};

export default connect(mapStateToProps)(WatchListTable);
