import Api from "../index";
import Links from "../../constants/Links";
import BinanceApi from "./binance";
import CoinBaseApi from "./coin_base";
import BTCTurkApi from "./btc_turk";

const KEY_PATH = (key_id) => '/' + key_id
const BALANCE_PATH = (key_id) => KEY_PATH(key_id) + '/balances'
const OPEN_ORDERS_PATH = (key_id) => KEY_PATH(key_id) + '/order/open'
const ORDER_PATH = (key_id, order_id) => order_id ? KEY_PATH(key_id) + '/order/' + order_id : KEY_PATH(key_id) + '/order';
const TRADE_PATH = (key_id) => KEY_PATH(key_id) + '/trades';

const ExchangeApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.BASE_TRADE_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.BASE_TRADE_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.BASE_TRADE_URL)
    },

    /**
     * Get balances of the account
     *
     * @param key_id Account ID
     * @param symbols Symbols to fetch
     */
    getBalances(key_id, symbols) {
        return this.getPromise(BALANCE_PATH(key_id), {symbols: symbols.join(",")});
    },

    /**
     * Get open orders for instrument and account
     *
     * @param key_id Account ID
     * @param instrument Instrument to fetch
     */
    getOpenOrders(key_id, instrument) {
        return this.getPromise(OPEN_ORDERS_PATH(key_id), {symbol: instrument});
    },

    /**
     * Get trades of the user
     *
     * @param key_id Account connection to fetch trades for
     * @param symbol Symbol to fetch trades from
     * @param limit Orders limit
     */
    getTrades(key_id, symbol, limit) {
        return this.getPromise(TRADE_PATH(key_id), {symbol: symbol, limit: limit});
    },

    /**
     * Get trades of the market
     *
     * @param symbol Symbol to fetch trades from
     * @param limit Orders limit
     * @param exchange Which exchange to connect to
     */
    getMarketTrades(symbol, limit, exchange) {
        return Api.getPromise("/timeseries/gettimeseries", {cis_native_symbol: symbol, interval: "tick", limit: limit}).then(res => {
            return res.data || [];
        }).catch(err => {
            console.error(err);
            return [];
        });
    },

    /**
     * Cancel order with given orderID and instrument
     *
     * @param key_id Account ID
     * @param order_id Order ID
     * @param instrument Instrument of order
     */
    cancelOrder(key_id, order_id, instrument) {
        return this.deletePromise(ORDER_PATH(key_id, order_id), {symbol: instrument});
    },

    /**
     * Create order with given order body
     *
     * @param key_id Account ID
     * @param order Order information
     * @return {Promise | Promise<unknown>}
     */
    openOrder(key_id, order) {
        return this.postPromise(ORDER_PATH(key_id), order);
    },

    /**
     * Get api accessor with exchange code
     * @param exchangeCode
     */
    getApi(exchangeCode) {
        if (exchangeCode === 'BNB') {
            return new BinanceApi();
        } else if (exchangeCode === 'CBS') {
            return new CoinBaseApi();
        } else if (exchangeCode === 'BTT') {
            return new BTCTurkApi();
        }
    }
};

export default ExchangeApi;
