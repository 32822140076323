import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Select from '../../Forms/Select';
import 'react-datepicker/dist/react-datepicker.css';
import {toastr} from 'react-redux-toastr';
import Utils from "../../../helpers/Utils";
import UserApi from "../../../api/UserApi";

const defaultFilter = {
  keyword: '',
  index: '',
  // startDate: '',
  // endDate: '',
  alert_name: '',
  selectAsset: {},
  selectCoin: {},
  selectSector: {},
  selectSource: {},
};

const SetNewsAlertModal = props => {
  const {meta, isNew, close, show, fetchAlerts, locState, history} = props;
  const {lists, allCoins} = meta || {};
  const assetTypeLists = (lists && lists.asset_type_list) || [];
  const business = (lists && lists.business_classification_schema) || [];
  const sources = (lists && lists.news_sources) || [];
  // Prepare coin list from metadata for dropdown list
  const allCoinsT = Utils.prepareCoinSelections(allCoins);
  const [filter, setFilter] = useState(defaultFilter);
  const [loading, setLoading] = useState(false);

  const onChange = (type, val) => {
    let newFilter = {...filter};
    newFilter[type] = val;
    setFilter(newFilter);
  };

  useEffect(() => {
    if (isNew && isNew.alert_id && show) {
      isNew.selectAsset = assetTypeLists.filter(el => isNew.news_alert.tags.includes(el.news_tag))[0] || {};
      isNew.selectSector = business.filter(el => isNew.news_alert.tags.includes(el.news_tag))[0] || {};
      isNew.selectCoin = allCoinsT.filter(el => isNew.news_alert.tags.includes("COIN_" + el.symbol))[0] || {};
      isNew.selectSource = sources.filter(el => isNew.news_alert.source === el.id)[0] || {};
      setFilter(isNew);
    } else if (!(isNew && isNew.id) && locState && show) {
      setFilter(f => ({...f, ...locState.filter}));
    } else {
      setFilter(defaultFilter);
    }
  }, [show, isNew, locState]);

  const onSave = () => {
    // setLoading(true);
    let type = 'create';
    let params = {alert_name: filter.alert_name, alert_type: 'news'};
    if (isNew && isNew.alert_id) {
      type = 'update';
      params = {alert_id: isNew.alert_id, alert_name: filter.alert_name, alert_type: 'news'};
    }
    const isAsset = Object.keys(filter.selectAsset).length === 0 && filter.selectAsset.constructor === Object;
    const isCoin = Object.keys(filter.selectCoin).length === 0 && filter.selectCoin.constructor === Object;
    const isSector = Object.keys(filter.selectSector).length === 0 && filter.selectSector.constructor === Object;
    const isSource = Object.keys(filter.selectSource).length === 0 && filter.selectSource.constructor === Object;

    const tags = []
    const news_alert = {}
    if (!isAsset) {
      tags.push(filter.selectAsset.news_tag)
    }
    if (!isCoin) {
      tags.push("COIN_" + filter.selectCoin.symbol)
    }
    if (!isSector) {
      tags.push(filter.selectSector.news_tag)
    }
    news_alert['tags'] = tags
    if (!isSource) {
      news_alert['source'] = filter.selectSource.id
    }
    params['news_alert'] = news_alert

    if (!filter.alert_name) {
      toastr.error('', 'Name required.');
      setLoading(false);
    } else if (isAsset && isCoin && isSector && isSource) {
      toastr.error('', 'You must choose at least one.');
      setLoading(false);
    } else {
      if (type === 'update') {
        UserApi.updateAlert(isNew.alert_id, params).then(res => {
          if (fetchAlerts) fetchAlerts();
          if (!locState) close();
          toastr.success('', 'Successfully Saved');
          setFilter(defaultFilter);
          setLoading(false);
          if (locState) history.replace('/news');
        });
      } else {
        UserApi.createAlert(params).then(res => {
          if (fetchAlerts) fetchAlerts();
          if (!locState) close();
          toastr.success('', 'Successfully Saved');
          setFilter(defaultFilter);
          setLoading(false);
          if (locState) history.replace('/news');
        });
      }
    }
  };

  const DateInput = ({value, onClick}) => (
    <button className={'date-picker-button'} onClick={onClick}>
      {value}
    </button>
  );

  return (
    <div className={`general-modal ${show ? 'active' : ''}`} id="set-news-alert">
      <div className="vAlignWr">
        <div className="vAlign">
          <div className="white-modal-inner set-alert">
            <div className="modal-blue-header">
              Set Alerts
              <div onClick={close} className="close-modal" data-target="set-news-alert">
                <img src="img/close-white.svg" alt="" />
              </div>
            </div>
            <div style={{minWidth: 400}} className="modal-white-body">
              <div className="general-tab-area">
                <div className="general-tab-menu">
                  <ul>
                    <li className="active">
                      <a href="#" data-target="price-alert">
                        Set News Alert
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="general-tab-body-all">
                  <div className="general-tab-body active" id="news-alert">
                    <div className="set-news-alert">
                      <div className="input-table">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="spa-text">Alert Name</div>
                              </td>
                              <td>
                                <div className="bottom-border-input">
                                  <input type="text" placeholder="My News Alert" maxLength={50} value={filter.alert_name} onChange={val => onChange('alert_name', val.target.value)} />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="spa-text">Select Coin</div>
                              </td>
                              <td style={{width: '100%'}}>
                                <div className="sm-select-input">
                                  <Select
                                    addAll
                                    selected={filter.selectCoin.symbol || 0}
                                    optionValue={'symbol'}
                                    optionLabel={'value'}
                                    options={allCoinsT || []}
                                    onChange={selected => onChange('selectCoin', selected)}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="spa-text">Sector</div>
                              </td>
                              <td style={{width: '100%'}}>
                                <div className="sm-select-input">
                                  <Select
                                    id="custom-select-5"
                                    addAll
                                    selected={filter.selectSector.news_tag || 0}
                                    options={business || []}
                                    optionValue={'news_tag'}
                                    optionLabel={'business_classification'}
                                    onChange={selected => onChange('selectSector', selected)}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="spa-text">Asset Type</div>
                              </td>
                              <td style={{width: '100%'}}>
                                <div className="sm-select-input">
                                  <Select
                                    id="custom-select-6"
                                    addAll
                                    selected={filter.selectAsset.news_tag || 0}
                                    options={assetTypeLists || []}
                                    optionValue={'news_tag'}
                                    optionLabel={'asset_type'}
                                    onChange={selected => onChange('selectAsset', selected)}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="spa-text">Source</div>
                              </td>
                              <td style={{width: '100%'}}>
                                <div className="sm-select-input">
                                  <Select
                                    id="custom-select-6"
                                    addAll
                                    selected={filter.selectSource.id || 0}
                                    options={sources || []}
                                    optionValue={'id'}
                                    optionLabel={'name'}
                                    onChange={selected => onChange('selectSource', selected)}
                                  />
                                </div>
                              </td>
                            </tr>
                            {/* <tr>
                              <td>
                                <div className="spa-text">Date Interval</div>
                              </td>
                              <td>
                                <div className="gray-datepicker">
                                  <DatePicker
                                    selected={filter.startDate}
                                    onChange={date => setFilter({...filter, startDate: date})}
                                    selectsStart
                                    customInput={<DateInput />}
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    showTimeInput
                                    isClearable
                                    startDate={filter.startDate}
                                    endDate={filter.endDate}
                                  />
                                  <DatePicker
                                    selected={filter.endDate}
                                    onChange={date => setFilter({...filter, endDate: date})}
                                    selectsEnd
                                    customInput={<DateInput />}
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    showTimeInput
                                    isClearable
                                    startDate={filter.endDate}
                                    endDate={filter.endDate}
                                    minDate={filter.startDate}
                                  />
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                        {/* <div class="white-btn">
                                                <button class="skew-anim-black" type="submit">SAVE</button>
                                            </div> */}
                        <div className="clearfix" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-gray-footer">
              <div className="form-buttons">
                <div className="form-flex">
                  <div className="form-flex-inner">
                    <div className="light-gray-btn">
                      <button onClick={close} className="close-modal" data-target="set-news-alert">
                        CANCEL
                      </button>
                    </div>
                  </div>
                  <div className="form-flex-inner">
                    <div className="light-blue-btn">
                      <button disabled={loading} onClick={onSave}>
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    meta: state.meta
  };
};

export default connect(mapStateToProps)(SetNewsAlertModal);
