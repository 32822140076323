import React, {useState, useEffect} from 'react';
import Loading from '../../../components/Loading';
import {connect} from "react-redux";
import Api from "../../../api";
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';




const CreatePriceAlert = props => {
    const {
        exchanges, // Exchange information metadata
    } = props;

    const params = {};



    const [selectedMarket,setSelectedMarket] = useState(null);
    const [selectedSymbol,setSelectedSymbol] = useState(null);
    const [selectedCurrency,setSelectedCurrency] = useState(null);
    const [loading,setLoading] = useState(true);
    const [marketOptions,setMarketOptions] = useState([]);
    const [coinSymbolOptions,setCoinSymbolOptions] = useState({data:[],isDisable:true});
    const [coinCurrencyOptions,setCoinCurrencyOptions] = useState({data:[],isDisable:true});

    useEffect(()=>{
        getMarkets();
    },[])

    useEffect(()=>{
        getCoinPairs();
    },[selectedMarket])

    const getMarkets = () => {
        const params = {};

        Api.marketSearch(params).then(res => {
            let market;
            let options;
            options = res.map(el => {
                exchanges.forEach(exel => {
                    if(el === exel.code){
                        market = {value:el,label:exel.name}
                        return ;
                    }
                    if(el === "CISCALC"){
                        market = {value:el,label:"CISCALC"}

                    }
                });
                return market;
            });
            setMarketOptions(options);
            setLoading(false);
        });
    }

    const getCoinPairs = () => {
        const params = {};
        if(selectedMarket){
            setLoading(true);
            params.source_code = selectedMarket.value;
            Api.coinPairSearch(params).then(res =>{
                setCoinSymbolOptions({data:res.base_assets.map(el =>{
                    return {value:el,label:el};
                }),isDisable: false});
                setCoinCurrencyOptions({data:res.quoted_assets.map(el =>{
                        return {value:el,label:el};
                    }),isDisable: false});
                setLoading(false);
            });
        }
    }

    const handleChangeMarket = selectedOption => {
        setSelectedMarket(selectedOption);
    };

    const handleChangeSymbolPair = selectedOption => {
        setSelectedSymbol(selectedOption);
    };

    const handleChangeCurrencyPair = selectedOption => {
        setSelectedCurrency(selectedOption);
    };


    return(
        <div className="gray-container alerts-price-alert">
            <Loading loading={loading}>
                <div className="row" style={{padding:"5px 0px"}}>
                    <div className="col-lg-12">
                        <h3 style={{color:"white",textAlign:"center"}}>Create Price Alert</h3>
                        <hr></hr>
                    </div>
                    <div className="col-lg-12">
                        <div className="input-title col-lg-6" style={{padding:"5px 0px",fontSize:"18px",textAlign:"center"}}>Market:</div>
                        <div className="filter-bar-input1 col-lg-6" style={{padding:"0px"}}>
                            <Select
                                value={selectedMarket}
                                onChange={handleChangeMarket}
                                options={marketOptions}
                                placeholder="Markets"
                            />
                        </div>
                    </div>
                    <div className="col-lg-12" style={{paddingTop:"10px"}}>
                        <div className="input-title col-lg-6" style={{padding:"5px 0px",fontSize:"18px",textAlign:"center"}}>Coin(Symbol/Currency):</div>
                        <div className="filter-bar-input1 col-lg-6" style={{padding:"0px"}}>
                            <div className="col-lg-6" style={{paddingLeft:"0px",paddingRight:"2px"}}>
                                <Select
                                    value={selectedSymbol}
                                    onChange={handleChangeSymbolPair}
                                    options={coinSymbolOptions.data}
                                    isDisabled={coinSymbolOptions.isDisable}
                                    placeholder="Symbol"
                                />
                            </div>
                            <div className="col-lg-6" style={{paddingLeft:"2px",paddingRight:"0px"}}>
                                <Select
                                    value={selectedCurrency}
                                    onChange={handleChangeCurrencyPair}
                                    options={coinCurrencyOptions.data}
                                    isDisabled={coinCurrencyOptions.isDisable}
                                    placeholder="Currency"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12" style={{paddingTop:"10px"}}>
                        <hr></hr>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button>Price</Button>
                            <Button>...</Button>
                        </ButtonGroup>
                    </div>
                    <div className="col-lg-12" style={{paddingTop:"10px"}}>
                        <hr></hr>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button>Price</Button>
                            <Button>Change(%)</Button>
                            <Button>...</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </Loading>
        </div>
    );

};



const prepareInputProps = state => {

    return {
        exchanges: state.meta.exchanges
    };
};

export default connect(prepareInputProps)(CreatePriceAlert);