import React, {useState, useEffect} from 'react';
import Api from '../../api';
import Loading from '../../components/Loading';
import moment from 'moment';
import TradingView from "../../tradingview";
import DataMappers from "../../helpers/DataMappers";
import Paginate from "../../components/Paginate";
import Utils from "../../helpers/Utils";
import {useAuth0} from "../../helpers/Auth0";

const isMobile = Utils.checkMobile();

const filter = {
  // tags: '',
  // news_sources: '',
  // min_publication_datetime: '',
  // max_publication_datetime: '',
  page_number: 1,
  page_size: 20,
  language_code: 'en',
};

const CoinNews = props => {
  const {
    selectedExchange, // Selected exchange
    row // Coin row selected
  } = props;

  const {user} = useAuth0();

  // News data to for listing
  const [data, setData] = useState({items: [], loading: true});

  // Table related information variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    // Fetch news on any change
    fetchNews(filter);
  }, [row, currentPage]);

  // Fetches news from repository and makes it ready for the visualisation
  const fetchNews = filter => {
    // Generate coin tag and fetch news
    const coin = (row && row.symbol && 'COIN_' + row.symbol) || null;
    if (coin) filter.tags = coin;
    // Set which page to fetch
    filter.page_number = currentPage;

    // Fetch data from the api
    setData({...data, loading: true});
    Api.searchNews(filter, true).then(res => {
      // Generate news data
      const data = res.data.map(el => {
        el.source = el.source.split("_").map(word =>  word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
        return el;
      });

      // Set related pages
      setData({items: data, loading: false});
      setTotalPage(res.totalPage);
    });
  };

  // Sentiment bubble generator
  const sentimentStyle = (value) => {
    return {
      borderRadius: "50%",
      width: "3em",
      height: "3em",
      fontSize: "12px",
      lineHeight: "3em",
      marginLeft: "auto",
      textAlign: "center",
      flex: "0 0 3em",
      color: "white",
      fontWeight: "bold",
      background: DataMappers.mapSentimentColor(value)
    }
  };

  // Handles pager clicks
  const handlePageClick = d => {
    if (currentPage !== d.selected + 1) {
      setCurrentPage(d.selected + 1);
    }
  };
  return (
      isMobile?

          <div className="overview-inner">
            <div className="overview-row" style={{display: "flex", flexRow: "row wrap", marginTop: "10px"}}>
              <div className="coin-news-table">
                <div className={"gray-container"} style={{padding: "10px 10px 10px 10px", marginTop :"10px",display: "flex", flexDirection: "column", height: "100%"}}>
                  <div className="section-header">Latest News</div>
                  <div className=" summary" style={{height: '100%', flex: "1 1 1px", display: "flex", flexDirection: "column"}}>
                    {data.items.length>0 ?
                        <Loading loading={data.loading}>
                          {data.items.length &&
                          data.items.map((d, i) => {
                            const date = moment.utc(d.publication_datetime).local();
                            const sentimentPref = user.preferences.sentiment_field;
                            let sentimentPrefValue = 0;

                            if(sentimentPref === "title_sentiment" && d.sentiment){
                              sentimentPrefValue = d.sentiment.title;
                            }else if(sentimentPref === "summary_sentiment" && d.sentiment){
                              sentimentPrefValue = d.sentiment.summary;
                            }else if(sentimentPref === "content_sentiment" && d.sentiment){
                              sentimentPrefValue = d.sentiment.content;
                            }
                            const perc = (d.sentiment && parseInt(sentimentPrefValue * 100, 10)) || 0;
                            const categories = d.tags.filter(el => el.startsWith("OTHER_")).map(el => el.split("OTHER_")[1]).join(" | ");

                            return (
                                <div key={i} className="summary-par" id="beauty-scroll-15" style={{ display: 'flex', margin: "0 1em 1em 1em", flexDirection: 'column', borderBottom: '1px solid #fff'}}>
                                  <div style={{display: "flex", flexDirection: "row"}}>
                                    <div style={{display: "flex", flexDirection: "column", paddingRight: "3vw"}}>
                                      <div style={{color: 'rgb(48, 111, 165)' /* fontWeight: 700, */, fontSize: '12px', }}>
                                        {d.source} |&nbsp;{date.format('DD MMM YYYY HH:mm:ss')}
                                      </div>
                                      <div style={{color: '#fff', fontWeight: 700}}>{d.title}</div>
                                    </div>
                                    <div style={sentimentStyle(sentimentPrefValue)}>
                                      {perc}%
                                    </div>
                                  </div>
                                  <div className="summary-text" style={{ lineHeight: "1.5", color: "white", marginTop: "15px"}}> {/*'#cccccc'*/}
                                    {d.summary}
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', margin: "3vh 0 1vh 0"}}>
                                    <div style={{color: 'white', fontSize: '12px', }}>
                                      {categories}
                                    </div>
                                    <div className="gray-border-btn" style={{marginLeft: "auto", marginTop: "0px"}}>
                                      <a onClick={() => window.open(d.link, '_blank')}>Read More</a>
                                    </div>
                                  </div>
                                </div>
                            );
                          })}
                        </Loading> : 'There is no relevant news'
                    }
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                    {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                  </div>
                </div>
              </div>
              <div className="coin-chart-table">
                <div className="chart-area">
                  <TradingView symbol={row.symbol} exchange={selectedExchange.value} type={'CISCALC'} isNews={true}/>
                </div>
              </div>
            </div>
          </div>

          :
    <div className="overview-inner">
      <div className="overview-row" style={{display: "flex", flexRow: "row wrap", marginTop: "10px"}}>
        <div className="col-md-5 coin-news-table" style={{paddingRight: "10px", paddingLeft: "10px"}} >
          <div className={"gray-container"} style={{padding: "10px 10px 10px 10px", display: "flex", flexDirection: "column", height: "100%"}}>
            <div className=" summary" style={{height: '100%', margin: 0, flex: "1 1 1px", display: "flex", flexDirection: "column"}}>
            {data.items.length>0 ? 
              <Loading loading={data.loading}>
                {data.items.length &&
                data.items.map((d, i) => {
                  const date = moment.utc(d.publication_datetime).local();
                  const sentimentPref = user.preferences.sentiment_field;
                  let sentimentPrefValue = 0;

                  if(sentimentPref === "title_sentiment" && d.sentiment){
                    sentimentPrefValue = d.sentiment.title;
                  }else if(sentimentPref === "summary_sentiment" && d.sentiment){
                    sentimentPrefValue = d.sentiment.summary;
                  }else if(sentimentPref === "content_sentiment" && d.sentiment){
                    sentimentPrefValue = d.sentiment.content;
                  }
                  const perc = (d.sentiment && parseInt(sentimentPrefValue * 100, 10)) || 0;
                  const categories = d.tags.filter(el => el.startsWith("OTHER_")).map(el => el.split("OTHER_")[1]).join(" | ");

                  return (
                      <div key={i} className="summary-par" id="beauty-scroll-15" style={{ display: 'flex', margin: "0 1em 1em 1em", flexDirection: 'column', borderBottom: '1px solid #fff'}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                          <div style={{display: "flex", flexDirection: "column", paddingRight: "3vw"}}>
                            <div style={{color: 'rgb(48, 111, 165)' /* fontWeight: 700, */, fontSize: '12px', }}>
                              {d.source} |&nbsp;{date.format('DD MMM YYYY HH:mm:ss')}
                            </div>
                            <div style={{color: '#fff', fontWeight: 700}}>{d.title}</div>
                          </div>
                          <div style={sentimentStyle(sentimentPrefValue)}>
                            {perc}%
                          </div>
                        </div>
                        <div className="summary-text" style={{ lineHeight: "1.5", color: "white", marginTop: "15px"}}> {/*'#cccccc'*/}
                          {d.summary}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', margin: "3vh 0 1vh 0"}}>
                          <div style={{color: 'white', fontSize: '12px', }}>
                            {categories}
                          </div>
                          <div className="gray-border-btn" style={{marginLeft: "auto", marginTop: "0px"}}>
                            <a onClick={() => window.open(d.link, '_blank')}>Read More</a>
                          </div>
                        </div>
                      </div>
                  );
                })}
              </Loading> : 'There is no relevant news'
            }
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
              {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
            </div>
          </div>
        </div>
        <div className="col-md-7 coin-chart-table" style={{paddingRight: "10px", paddingLeft: "10px"}} >
          <div className="chart-area" style={{height: "70vh", marginTop: "0"}}>
            <TradingView symbol={row.symbol} exchange={selectedExchange.value} type={'CISCALC'} isNews={true}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinNews;
