import React, {useState, useEffect} from 'react';
import Loading from '../../../../components/Loading';
import {toastr} from 'react-redux-toastr';
import Utils from "../../../../helpers/Utils";
import {connect} from "react-redux";
import {checkQuotaAccess, useAuth0} from "../../../../helpers/Auth0";

const PriceAlert = props => {
  const {setShow, loading, items, onUpdate, onDelete, meta} = props;
  const {allCoins} = meta || {};
  const allCoinsT = Utils.prepareCoinSelections(allCoins);
  const [data, setData] = useState([]);
  const {user} = useAuth0();

  useEffect(() => {
    const d = items.map(item => {
      let sc = allCoinsT.filter(c => c.symbol === item.price_alert.symbol);
      sc = sc[0] || {};
      return {image_url: sc.image_url, ...item};
    });
    setData(d);
  }, [items])

  const openModal = () => {
    const quota = checkQuotaAccess(user, 'price_alert', items.length);
    if (quota) {
      setShow(true);
    } else {
      toastr.error('', `You can not create new price alert. Upgrade your plan (Remaining Quota: ${quota}).`);
    }
  };

  return (
    <div className="gray-container alerts-price-alert">
      <div className="section-header">Price Alert</div>
      <div className="pa-inner">
        <div className="scroll-table" id="beauty-scroll-table">
          <Loading loading={loading}>
            <table>
              <thead>
                <tr>
                  <th style={{color: '#fff'}}>Name</th>
                  <th style={{color: '#fff'}}>From/To</th>
                  <th style={{color: '#fff'}}>Price</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {data.map((d, i) => (
                  <tr key={i}>
                    <td style={{padding: 0}}>{d.alert_name}</td>
                    <td
                      className="td-item-price-alert"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'left',
                        alignItems: 'center',
                      }}>
                      <div style={{paddingRight: 15}} className="icon-img">
                        <img src={d.image_url} style={{width: 20}} alt="" />
                      </div>
                      {d.price_alert.symbol} /
                      {d.price_alert.exchange}
                    </td>
                    <td className="td-item-price-alert">{d.price_alert.target}</td>
                    <td className="edit-delete-btn">
                      <button onClick={() => onUpdate(d)} className=" open-modal-event">
                        <img className="actionable-img" src="/img/edit.svg" alt="" />
                      </button>
                      <button onClick={() => onDelete(d)} className=" open-modal-event">
                        <img className="actionable-img" src="/img/delete.svg" alt="" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Loading>
        </div>
        {/* <div className="gray-border-checkbox mail-active-event flLeft" style={{marginTop: '27px'}}>
          <label className="gbc-label">
            <div className="gbc-text">I would like to receive by email</div>
            <input type="checkbox" />
            <div className="gbc-checkmark" />
          </label>
        </div> */}
        {/* <div className="mail-time">
          <div className="gray-border-checkbox flLeft">
            <label className="gbc-label">
              <div className="gbc-text">Daily</div>
              <input type="checkbox" />
              <div className="gbc-checkmark" />
            </label>
          </div>
          <div className="gray-border-checkbox flLeft">
            <label className="gbc-label">
              <div className="gbc-text">Weekly</div>
              <input type="checkbox" />
              <div className="gbc-checkmark" />
            </label>
          </div>
        </div> */}
        <div className="green-border-btn">
          <a onClick={openModal} className=" open-modal-event" data-target="set-price-alert">
            Add Price Alert
          </a>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    meta: state.meta,
  };
};

export default connect(mapStateToProps)(PriceAlert);
