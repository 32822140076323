import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import PriceAlert from './Parts/PriceAlert';
import NewsAlert from './Parts/NewsAlert';
import SetPriceAlertModal from '../../components/Modals/SetPriceAlertModal';
import SetNewsAlertModal from '../../components/Modals/SetNewsAlertModal';
import ConfirmationAlert from './Parts/ConfirmationAlert';
import {withRouter} from 'react-router-dom';
import {toastr} from 'react-redux-toastr';
import UserApi from "../../api/UserApi";
import {useAuth0, checkQuotaAccess} from "../../helpers/Auth0";
import Breadcrumps from "../../constants/Breadcrumps";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";
import CreatePriceAlert from "../../components/Alerts/CreatePriceAlert/CreatePriceAlert";


const Alerts = props => {
  const {user} = useAuth0();
  const {location, history} = props;
  const [showP, setShowP] = useState(false);
  const [showN, setShowN] = useState(false);
  const [showC, setShowC] = useState(false);
  const [watchListShow, setWatchListShow] = useState(user.preferences.show_watchlist);
  const [data, setData] = useState({items: [], loading: true, selected: {}});
  const locState = location && location.state && location.state.price ? location.state : null;
  const locStateNews = location && location.state && location.state.news ? location.state : null;

  useEffect(() => {
    if (locState && !data.loading) {
      const quota = checkQuotaAccess(user, 'price_alert', data.items.filter(d => d.alert_type === 'price').length);
      if (quota) {
        setShowP(true);
      } else {
        toastr.error('', `You can not create new price alert. Upgrade your plan (Remaining Quota: ${quota}).`);
      }
    }
    if (locStateNews && !data.loading) {
      const quota = checkQuotaAccess(user, 'news_alert', data.items.filter(d => d.alert_type === 'news').length);
      if (quota) {
        setShowN(true);
      } else {
        toastr.error('', `You can not create new news alert. Upgrade your plan (Remaining Quota: ${quota}).`);
      }
    }
  }, [locState, data.loading, locStateNews]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setData(d => ({...d, loading: true}));
    UserApi.getAlerts().then( d => {
      setData({items: d, loading: false});
    });
  };

  const onUpdate = item => {
    const newItem = {
      ...item
    };
    setData(d => ({...d, selected: newItem}));
    if (item.alert_type === 'price') {
      setShowP(true);
    } else {
      setShowN(true);
    }
  };

  const onDelete = item => {
    const newItem = {
      ...item
    };
    setData(d => ({...d, selected: newItem}));
    setShowC(true);
  };

  return (
    <React.Fragment>
      <section className="dashboard padding-sm-container">
        <div className="watchlist-all-page">
          {watchListShow ? <Watchlist/> : <></>}
        </div>
        <Breadcrump links={Breadcrumps.alerts}/>
        <div className="flex-row">
          <div className="flex-width-left" style={{width:"50%"}}>
            <CreatePriceAlert/>
            <PriceAlert
              loading={data.loading}
              items={data.items.filter(d => d.alert_type === 'price')}
              onUpdate={onUpdate}
              onDelete={onDelete}
              setShow={setShowP}
            />
          </div>
          <div className="flex-width-right" style={{width:"50%"}}>
            <NewsAlert
              loading={data.loading}
              items={data.items.filter(d => d.alert_type === 'news')}
              onUpdate={onUpdate}
              onDelete={onDelete}
              setShow={setShowN}
            />
          </div>
        </div>
      </section>
      <SetPriceAlertModal
        fetchAlerts={fetchData}
        history={history}
        isNew={data.selected}
        locState={locState}
        show={showP}
        close={() => {
          if (locState) history.replace('/alerts');
          setData(d => ({...d, selected: {}}));
          setShowP(false);
        }}
      />
      <SetNewsAlertModal
        fetchAlerts={fetchData}
        isNew={data.selected}
        history={history}
        locState={locStateNews}
        show={showN}
        close={() => {
          if (locStateNews) history.replace('/alerts');
          setData(d => ({...d, selected: {}}));
          setShowN(false);
        }}
      />
      <ConfirmationAlert
        fetchAlerts={fetchData}
        item={data.selected}
        show={showC}
        close={() => {
          setData(d => ({...d, selected: {}}));
          setShowC(false);
        }}
      />
    </React.Fragment>
  );
};


const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapDispatchToProps)(withRouter(Alerts));
