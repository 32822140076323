import {withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ExchangeApi from "../../api/ExchangeApi";
import {toastr} from "react-redux-toastr";
import Loading from "../../components/Loading";
import ReactTooltip from "react-tooltip";
import AppHelpers from "../../helpers/AppHelpers";

const Order = props => {
    const {
        selectedAccount, // Account selected for trading (Can be undefined)
        selectedSpot,  // Selected spot details
        selectedBalance, // Balance information
        spotInformation, // Spot information for precision, min, max values
        setOrderChanged, // Triggered when a order request is made 
        selectedPrice // Selected price from orderbook
    } = props;

    // Show some loading indicator when button is clicked
    const [loading, setLoading] = useState(false);

    // Indicates which type of order we are preparing
    const [type, setType] = useState('limit');

    // Buy & Sell Filters
    const [buyFilter, setBuyFilter] = useState({});
    const [sellFilter, setSellFilter] = useState({});

    // Initializes filter
    const initFilter = (side) => {
        return {
            'symbol': selectedSpot && selectedSpot.native_symbol,
            'side': side,
            'price': selectedPrice || (selectedSpot && selectedSpot.org_ticker.lst),
            'amount': 0,
            'total': 0,
            'type': type
        }
    }

    // Handle precision flow mapping
    const mapPrecision = (val, precision, base) => {
        // Precision is correct
        if (AppHelpers.countPrecision(val) <= precision) return val;
        // If a number entered that has more decimal numbers than precision
        return Math.floor(val * base) / base;
    }

    // Validate field (If valid return null else return message)
    const validateField = (filter, field, value, update) => {
        value = value || 0;
        if (field === 'amount') {
            // Balance limitation checks
            if (filter.side === 'buy' && filter.total > selectedBalance.quoted_balance.free) {
                const max = mapPrecision(selectedBalance.quoted_balance.free / filter.price, spotInformation.base_precision, spotInformation.base_pow)
                filter.amount_validation = `Balance is not enough. Maximum ${max}`;
            } else if (filter.side === 'sell' && value > selectedBalance.base_balance.free) {
                filter.amount_validation = `Balance is not enough. Maximum ${selectedBalance.base_balance.free}`;
            } else {
                delete filter.amount_validation;
            }

            // General spot information checks for quote amount (Ignore if already stuck in general limitation)
            if (!filter.amount_validation) {
                if (value < spotInformation.base_min_amount) {
                    filter.amount_validation = `Minimum amount ${spotInformation.base_min_amount}`;
                } else if (value > spotInformation.base_max_amount) {
                    filter.amount_validation = `Maximum amount ${spotInformation.base_max_amount}`;
                } else {
                    delete filter.amount_validation;
                }
            }
        }

        // General spot information checks for base amount
        if (filter.type === 'limit' && field === 'price') {
            if (value < spotInformation.quote_min_amount) {
                filter.price_validation = `Minimum amount ${spotInformation.quote_min_amount}`;
            } else if (value > spotInformation.quote_max_amount) {
                filter.price_validation = `Maximum amount ${spotInformation.quote_max_amount}`;
            } else {
                delete filter.price_validation;
            }
        }

        // Set filter accordingly
        if (update) {
            filter.side === 'buy' ? setBuyFilter({...filter}) : setSellFilter({...filter});
        } else {
            return filter;
        }
    }

    // Delete validation message
    const deleteValidation = (filter, field) => {
        if (field === 'price') {
            delete filter.price_validation;
        } else {
            delete filter.amount_validation;
        }
        filter.side === 'buy' ? setBuyFilter({...filter}) : setSellFilter({...filter});
    }

    // A value changed from given filter
    const valueChanged = (filter, field, value) => {
        let newValue;
        // Handle rounding and minimum size
        if (field === 'price') {
            newValue = mapPrecision(value, spotInformation.quote_precision, spotInformation.quote_pow);
        }
        if (field === 'total') {
            newValue = mapPrecision(value, spotInformation.total_precision, spotInformation.total_pow);
        }
        if (field === 'amount') {
            newValue = mapPrecision(value, spotInformation.base_precision, spotInformation.base_pow);
            filter = validateField(filter, 'amount', newValue);
        }

        // Check if value is actually changed
        if (filter[field] !== newValue) {
            filter[field] = newValue;

            // If we are in price type orders
            if (filter.type === 'limit') {
                // Price is changed, if amount exists set total automatically
                if (field === 'price' && value) {
                    filter.total = filter.amount && (value * filter.amount);
                    filter.total = mapPrecision(filter.total, spotInformation.total_precision, spotInformation.total_pow);
                    filter = validateField(filter, 'price', filter.price);
                }

                // If amount is changed and price exists set total automatically
                if (field === 'amount' && value) {
                    filter.total = filter.price && (value * filter.price);
                    filter.total = mapPrecision(filter.total, spotInformation.total_precision, spotInformation.total_pow);
                }

                // If total is changed and price exists set amount automatically
                if (field === 'total' && value) {
                    filter.amount = filter.price && (value / filter.price);
                    filter.amount = mapPrecision(filter.amount, spotInformation.base_precision, spotInformation.base_pow);
                }
            }
            filter = {...filter} // Deep copy filter so react understands it needs update
        }

        // Set filter accordingly
        filter.side === 'buy' ? setBuyFilter(filter) : setSellFilter(filter);
    }


    // Percentage button clicked
    const percentageSelected = (filter, percentage) => {
        if (selectedBalance) {
            if (filter.side === 'buy') {
                valueChanged(filter, 'amount', (selectedBalance.quoted_balance.free * percentage) / filter.price);
            } else {
                valueChanged(filter, 'amount', selectedBalance.base_balance.free * percentage);
            }
        }
    }

    // Send order for opening order
    const sendOrder = (order) => {
        setLoading(true);

        // Validate fields ignore request if validation fails
        order = validateField(order, 'price', order.price);
        order = validateField(order, 'amount', order.amount);
        if (order.price_validation || order.amount_validation) {
            toastr.error("Invalid Order, Check Fields...")
            order.side === 'buy' ? setBuyFilter({...order}) : setSellFilter({...order});
            setLoading(false);
            return;
        }

        ExchangeApi.openOrder(selectedAccount.key_id, order).then( resp => {
            toastr.success('', 'Order successfully created...');
            // Init filters to default forms
            setBuyFilter(initFilter('buy'));
            setSellFilter(initFilter('sell'));
            setLoading(false);
            setOrderChanged(new Date().getMilliseconds().toString());
        }).catch( err => {
            if (err.response.status === 403) {
                toastr.error("You exceeded your monthly order quota...")
            } else {
                toastr.error('', "Something went wrong...");
                console.error(err);
            }
            setLoading(false);
        });
    }

    // Check if disabled
    const isDisabled = () => (selectedAccount && !loading) ? '' : 'disabled';

    useEffect(() => {
        // Initialize buy & sell filters
        setBuyFilter(initFilter('buy'));
        setSellFilter(initFilter('sell'));
    }, [selectedSpot, selectedAccount, spotInformation, type]);

    useEffect(() => {
        // Price is selected from orderbook update filters
        if (selectedPrice && !isDisabled()) {
            valueChanged(sellFilter, 'price', selectedPrice);
            valueChanged(buyFilter, 'price', selectedPrice);
        }
    }, [selectedPrice]);

    return (
        [
            <div className={"inline-tab-wrapper"}>
                <ul className={"inline-tab"}>
                    <li key={"Limit"}><button className={type === 'limit' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation(); setType('limit')}}>Limit</button></li>
                    <li key={"Market"}><button className={type !== 'limit' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation(); setType('market')}}>Market</button></li>
                </ul>
            </div>,
            <div className="col-md-6 col-sm-12 gray-container-tabbed" style={{display: "flex", flexDirection: "column", height: "100%", borderRight: "1px solid #33393f"}}>
                <div className="table-head" style={{marginBottom: "2vh"}}>
                    <div className="table-left">
                        <div className="table-header">Buy {selectedSpot.base_asset}</div>
                    </div>
                    <div className="table-right">
                        <div className="table-header">
                            <div style={{color:'white'}}>
                                <img className="actionable-img" src="img/wallet.svg" width={16} height={16} alt="" />
                                <span style={{marginLeft:5}}>{selectedBalance ? selectedBalance.quoted_balance.free : "-"} {selectedSpot.quoted_asset}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive set-price-alert" style={{marginTop:20,padding:0}}>
                    <div className="col-md-12 col-sm-12" style={{display:'inline-flex', marginBottom: "10px"}}>
                        <div className="col-lg-4 col-md-6 spa-text" style={{padding: "10px 0", fontSize: "14px"}}>Price</div>
                        <div className="col-lg-8 col-md-6 sm-select-input" style={{padding: 0}}>
                            <div className="bottom-border-input">
                                {type === 'limit' &&
                                    <input
                                        onFocus={() => validateField(buyFilter, 'price', buyFilter.price, true)}
                                        onBlur={() => deleteValidation(buyFilter, 'price')}
                                        type="number"
                                        data-for="validate"
                                        data-tip={buyFilter.price_validation ? buyFilter.side + "-price_validation" : ""}
                                        style={{padding: "10px 5px 10px 10px", borderColor: buyFilter.price_validation ? "red" : "#337ab7"}}
                                        disabled={isDisabled()}
                                        min={spotInformation.quote_min_amount}
                                        step={spotInformation.quote_increment_size}
                                        onChange={e => valueChanged(buyFilter, 'price', e.target.valueAsNumber)}
                                        value={buyFilter.price}/>}
                                {type !== 'limit' && <input type="text" value={"Market"} disabled style={{padding: "10px 5px 10px 10px"}}/>}
                                <span className={"inline-asset-type"}> {buyFilter.price_validation && <i style={{color:  "red"}} className="fas fa-exclamation-circle"/>} {selectedSpot.quoted_asset}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12" style={{display:'inline-flex', marginBottom: "10px"}}>
                        <div className="col-lg-4 col-md-6 spa-text" style={{padding: "10px 0", fontSize: "14px"}}>Amount</div>
                        <div className="col-lg-8 col-md-6 sm-select-input" style={{padding: 0}}>
                            <div className="bottom-border-input">
                                {/*<input type="text" maxLength={50} placeholder="My Price Alert" value={filter.alert_name} onChange={val => onChange('alert_name', val.target.value)} />*/}
                                <input
                                    onFocus={() => validateField(buyFilter, 'amount', buyFilter.amount, true)}
                                    onBlur={() => deleteValidation(buyFilter, 'amount')}
                                    data-for="validate"
                                    data-tip={buyFilter.amount_validation ? buyFilter.side + "-amount_validation" : ""}
                                    type="number"
                                    style={{padding: "10px 5px 10px 10px", borderColor: buyFilter.amount_validation ? "red" : "#337ab7"}}
                                    disabled={isDisabled()}
                                    min={spotInformation.base_min_amount}
                                    step={spotInformation.base_increment_size}
                                    onChange={(e) => valueChanged(buyFilter, 'amount', e.target.valueAsNumber)}
                                    value={buyFilter.amount}/>
                                <span className={"inline-asset-type"}>{buyFilter.amount_validation && <i style={{color:  "red"}} className="fas fa-exclamation-circle"/>} {selectedSpot.base_asset}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12" style={{display:'inline-flex', marginBottom: type === 'limit' ? "10px" : "30px"}}>
                        <div className="col-lg-4 col-md-6 spa-text" style={{padding: "10px 0", fontSize: "14px"}}/>
                        <div className={"col-lg-8 col-md-6 button-holder"}>
                            <button className={"button-order-perc"} disabled={isDisabled()} onClick={() => percentageSelected(buyFilter, 0.25)}>25%</button>
                            <button className={"button-order-perc"} disabled={isDisabled()} onClick={() => percentageSelected(buyFilter, 0.50)}>50%</button>
                            <button className={"button-order-perc"} disabled={isDisabled()} onClick={() => percentageSelected(buyFilter, 0.75)}>75%</button>
                            <button className={"button-order-perc"} disabled={isDisabled()} onClick={() => percentageSelected(buyFilter, 1)}>100%</button>
                        </div>
                    </div>
                    {type === 'limit' && <div className="col-md-12 col-sm-12" style={{display:'inline-flex', marginBottom: "30px"}}>
                        <div className="col-lg-4 col-md-6 spa-text" style={{padding: "10px 0", fontSize: "14px"}}>Total</div>
                        <div className="col-lg-8 col-md-6 sm-select-input" style={{padding: 0}}>
                            <div className="bottom-border-input">
                                {/*<input type="text" maxLength={50} placeholder="My Price Alert" value={filter.alert_name} onChange={val => onChange('alert_name', val.target.value)} />*/}
                                <input
                                    type="number"
                                    style={{padding: "10px 5px 10px 10px"}}
                                    disabled={isDisabled()}
                                    min={spotInformation.quote_min_amount}
                                    step={spotInformation.total_increment_size}
                                    onChange={e => valueChanged(buyFilter, 'total', e.target.valueAsNumber)}
                                    value={buyFilter.total}/>
                                <span className={"inline-asset-type"}>{selectedSpot.quoted_asset}</span>
                            </div>
                        </div>
                    </div>}
                    <div className=" col-md-12" style={{padding:"0px 15px"}}>
                        <button className={"light-green-btn"} disabled={isDisabled()} onClick={() => sendOrder(buyFilter)}>
                            {loading ? <Loading width={"20%"} height={"20%"} loading={true}/> : "Buy " + selectedSpot.base_asset}
                        </button>
                    </div>
                </div>
            </div>,
            <div className="col-md-6 col-sm-12 gray-container-tabbed" style={{display: "flex", flexDirection: "column", height: "100%"}}>
                <div className="table-head" style={{marginBottom: "2vh"}}>
                    <div className="table-left">
                        <div className="table-header">Sell {selectedSpot.base_asset}</div>
                    </div>
                    <div className="table-right">
                        <div className="table-header">
                            <div style={{color:'white'}}>
                                <img className="actionable-img" src="img/wallet.svg" width={16} height={16} alt="" />
                                <span style={{marginLeft:5}}>{selectedBalance ? selectedBalance.base_balance.free : "-"}  {selectedSpot.base_asset}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive set-price-alert" style={{marginTop:20,padding:0}}>
                    <div className="col-md-12 col-sm-12" style={{display:'inline-flex', marginBottom: "10px"}}>
                        <div className="col-lg-4 col-md-6 spa-text" style={{padding: "10px 0", fontSize: "14px"}}>Price</div>
                        <div className="col-lg-8 col-md-6 sm-select-input" style={{padding: 0}}>
                            <div className="bottom-border-input">
                                {type === 'limit' &&
                                    <input type="number"
                                        onFocus={() => validateField(sellFilter, 'price', sellFilter.price, true)}
                                        onBlur={() => deleteValidation(sellFilter, 'price')}
                                        data-for="validate"
                                        data-tip={sellFilter.price_validation ? sellFilter.side + "-price_validation" : ""}
                                        style={{padding: "10px 5px 10px 10px", borderColor: sellFilter.price_validation ? "red" : "#337ab7"}}
                                        disabled={isDisabled()}
                                        min={spotInformation.quote_min_amount}
                                        step={spotInformation.quote_increment_size}
                                        onChange={e => valueChanged(sellFilter, 'price', e.target.valueAsNumber)}
                                        value={sellFilter.price}/>}
                                {type !== 'limit' &&  <input type="text" value={"Market"} disabled style={{padding: "10px 5px 10px 10px"}}/>}
                                <span className={"inline-asset-type"}>{sellFilter.price_validation && <i style={{color: "red"}}  className="fas fa-exclamation-circle" />} {selectedSpot.quoted_asset}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12" style={{display:'inline-flex', marginBottom: "10px"}}>
                        <div className="col-lg-4 col-md-6 spa-text" style={{padding: "10px 0", fontSize: "14px"}}>Amount</div>
                        <div className="col-lg-8 col-md-6 sm-select-input" style={{padding: 0}}>
                            <div className="bottom-border-input">
                                {/*<input type="text" maxLength={50} placeholder="My Price Alert" value={filter.alert_name} onChange={val => onChange('alert_name', val.target.value)} />*/}
                                <input type="number"
                                       onFocus={() => validateField(sellFilter, 'amount', sellFilter.amount, true)}
                                       onBlur={() => deleteValidation(sellFilter, 'amount')}
                                       data-for="validate"
                                       data-tip={sellFilter.amount_validation ? sellFilter.side + "-amount_validation" : ""}
                                       style={{padding: "10px 5px 10px 10px", borderColor: sellFilter.amount_validation ? "red" : "#337ab7"}}
                                       disabled={isDisabled()}
                                       min={spotInformation.base_min_amount}
                                       step={spotInformation.base_increment_size}
                                       onChange={(e) => valueChanged(sellFilter, 'amount', e.target.valueAsNumber)}
                                       value={sellFilter.amount} />
                                <span className={"inline-asset-type"}>{sellFilter.amount_validation && <i style={{color:  "red"}} className="fas fa-exclamation-circle"/>} {selectedSpot.base_asset}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12" style={{display:'inline-flex', marginBottom: type === 'limit' ? "10px" : "30px"}}>
                        <div className="col-lg-4 col-md-6 spa-text" style={{padding: "10px 0", fontSize: "14px"}}/>
                        <div className={"col-lg-8 col-md-6 button-holder"}>
                            <button className={"button-order-perc"} disabled={isDisabled()} onClick={() => percentageSelected(sellFilter, 0.25)}>25%</button>
                            <button className={"button-order-perc"} disabled={isDisabled()} onClick={() => percentageSelected(sellFilter, 0.50)}>50%</button>
                            <button className={"button-order-perc"} disabled={isDisabled()} onClick={() => percentageSelected(sellFilter, 0.75)}>75%</button>
                            <button className={"button-order-perc"} disabled={isDisabled()} onClick={() => percentageSelected(sellFilter, 1)}>100%</button>
                        </div>
                    </div>
                    {type === 'limit' && <div className="col-md-12 col-sm-12" style={{display:'inline-flex', marginBottom: "30px"}}>
                        <div className="col-lg-4 col-md-6 spa-text" style={{padding: "10px 0", fontSize: "14px"}}>Total</div>
                        <div className="col-lg-8 col-md-6 sm-select-input" style={{padding: 0}}>
                            <div className="bottom-border-input">
                                {/*<input type="text" maxLength={50} placeholder="My Price Alert" value={filter.alert_name} onChange={val => onChange('alert_name', val.target.value)} />*/}
                                <input type="number"
                                       style={{padding: "10px 5px 10px 10px"}}
                                       disabled={isDisabled()}
                                       min={spotInformation.quote_min_amount}
                                       step={spotInformation.total_increment_size}
                                       onChange={e => valueChanged(sellFilter, 'total', e.target.valueAsNumber)}
                                       value={sellFilter.total}/>
                                <span className={"inline-asset-type"}>{selectedSpot.quoted_asset}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="col-md-12" style={{padding:"0px 15px"}}>
                        <button className={"light-danger-btn"} disabled={isDisabled()} onClick={() => sendOrder(sellFilter)}>
                            {loading ? <Loading width={"20%"} height={"20%"} loading={true}/> : "Sell " + selectedSpot.base_asset}
                        </button>
                    </div>
                </div>
                <ReactTooltip id="validate" getContent={dt => {
                    if (!dt) return null;

                    // Find required fields
                    const reg = dt.split("-");
                    const side = reg[0];
                    const field = reg[1];
                    return side === 'buy' ? buyFilter[field] : sellFilter[field];
                }}/>
            </div>]

    )
}

export default withRouter(Order);
