import React from 'react';
import {toastr} from 'react-redux-toastr';
import Loading from '../../../../components/Loading';
import {checkQuotaAccess, useAuth0} from "../../../../helpers/Auth0";

const NewsAlert = props => {
  const {setShow, loading, items, onUpdate, onDelete} = props;
  const {user} = useAuth0();

  const openModal = () => {
    const quota = checkQuotaAccess(user, 'news_alert', items.length);
    if (quota) {
      setShow(true);
    } else {
      toastr.error('', `You can not create new news alert. Upgrade your plan (Remaining Quota: ${quota}).`);
    }
  };

  return (
    <div className="gray-container alerts-price-alert">
    <div className="section-header">News Alert</div>
    <div className="pa-inner">
      <div className="scroll-table" id="beauty-scroll-table">
        <Loading loading={loading}>
          <table>
            <tbody>
              {items.map((d, i) => (
                <tr key={i}>
                  <td>{d.alert_name}</td>
                  <td className="edit-delete-btn">
                    <button onClick={() => onUpdate(d)} className=" open-modal-event">
                      <img className="actionable-img" src="/img/edit.svg" alt="" />
                    </button>
                    <button onClick={() => onDelete(d)} className=" open-modal-event">
                      <img className="actionable-img" src="/img/delete.svg" alt="" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Loading>
      </div>
      {/* <div className="gray-border-checkbox mail-active-event flLeft" style={{marginTop: '27px'}}>
        <label className="gbc-label">
          <div className="gbc-text">I would like to receive by email</div>
          <input type="checkbox" />
          <div className="gbc-checkmark" />
        </label>
      </div> */}
      {/* <div className="mail-time">
        <div className="gray-border-checkbox flLeft">
          <label className="gbc-label">
            <div className="gbc-text">Daily</div>
            <input type="checkbox" />
            <div className="gbc-checkmark" />
          </label>
        </div>
        <div className="gray-border-checkbox flLeft">
          <label className="gbc-label">
            <div className="gbc-text">Weekly</div>
            <input type="checkbox" />
            <div className="gbc-checkmark" />
          </label>
        </div>
      </div> */}
      <div className="green-border-btn">
        <a onClick={openModal} className=" open-modal-event" data-target="set-price-alert">
          Add News Alert
        </a>
      </div>
      <div className="clearfix" />
    </div>
  </div>
  );
};

export default NewsAlert;
