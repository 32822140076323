/* eslint-disable no-use-before-define */
import React, {useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from "@material-ui/core/CircularProgress";

const TextComplete = props => {
  const {
      onSelect, // Called when the item is selected
      loading, // Indicates if the options are loading
      onSearch, // Called when the string is entered
      onClose, // Called when the options are closed
      searchText, // Search text entered
      data // Options for the search selection
  } = props;

  // Keep track of search text for cleaning
  const textInput = useRef(null);

  return (
    <form autoComplete={'off'} style={{width: "12vw"}}>
      <Autocomplete
        id="free-solo-2-demo"
        options={data}
        onChange={(e, selected) => {
            // Propagate selected to parent class
            onSelect(selected)
        }}
        onClose={onClose}
        loading={loading}
        clearOnEscape
        noOptionsText={(!loading && searchText) ? "No options..." : "Start Typing..."}
        groupBy={option => option.group}
        getOptionLabel={option => option ? option.label : ''}
        renderInput={params => (
          <TextField
            {...params}
            label="Search"
            margin="normal"
            variant="outlined"
            onChange={onSearch}
            inputRef={textInput}
            fullWidth
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <InputAdornment position="end">
                    {loading ? <CircularProgress color="secondary" size={20} /> : <img className="actionable-img" src="/img/search.svg" alt="" />}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </form>
  );
};

export default TextComplete;
