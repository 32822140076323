import React, {useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading";
import moment from "moment";
import ExchangeApi from "../../api/ExchangeApi";
import {withRouter} from "react-router-dom";
import RealtimeUtils from "../../helpers/RealtimeUtils";

const Tradebook = props => {
    const {
        selectedAccount, // Selected user account
        selectedSpot, // Selected spot to show for orderbook
        setSelectedPrice, // Send selected price to order creation screen
        orderChanged, // Triggered when order opened or cancelled
        history // Used for navigating between pages
    } = props;

    // Loading elements
    const [uLoading, setULoading] = useState(false);
    const [gLoading, setGLoading] = useState(false);

    // User tradebook and general tradebook variables
    const [userTradeBook, setUserTradeBook] = useState([]);
    const [initTradebook, generalTradeBook] = RealtimeUtils.useRealTimeTrade();
    const [activeTradeBook, setActiveTradeBook] = useState([]);

    // Active screen indicator
    const [type, setType] = useState("general");

    // Realtime callback reference
    const realTimeCallback = useRef(null);

    // Fetch trades of the user
    const fetchUserTrades = () => {
        setULoading(true);
        ExchangeApi.getTrades(selectedAccount.key_id, selectedSpot.native_symbol, 50).then(res => {
            // Set tradebook data of the user
            setULoading(false);
            setUserTradeBook(res);
        });
    };

    // Fetch market trades
    const fetchMarketTrades = () => {
        // Get connection api for the selected spot
        if (selectedSpot) {
            initTradebook(selectedSpot.cis_native_symbol, null);
        }
    }

    // Initialize periodic checks for balance
    const startPeriodicCheck = () => {
        // Clear timeout if already running
        if (realTimeCallback.current) {
            clearInterval(realTimeCallback.current);
        }

        // Create new periodic job
        realTimeCallback.current = setInterval(() => {
            fetchUserTrades();
        }, 60 * 1000)
    };

    // Detect side color of the trade price
    const detectSideColor = (side) => {
        if (side === 'sell') {
            return '#c11e0f';
        } else if (side === 'buy') {
            return '#7ac231';
        }
    }

    useEffect(() => {
        if (selectedAccount) {
            // Fetch user trades
            fetchUserTrades();

            // Start periodic check for balances
            startPeriodicCheck();
        }
    }, [selectedAccount]);

    useEffect(() => {
        if (selectedAccount && selectedSpot.exchange_id === selectedAccount.exchange) {
            // Fetch user trades
            fetchUserTrades();

            // Start periodic check for balances
            startPeriodicCheck();
        } else {
            // Set user trade book to empty
            setUserTradeBook([]);

            // Stop tradebook periodic check
            if (realTimeCallback.current) {
                clearInterval(realTimeCallback.current);
            }
        }

        if (selectedSpot) {
            // Fetch market trades if selected spot changed
            fetchMarketTrades()
        }
    }, [selectedSpot]);

    useEffect(() => {
        // Check if changed triggered by new order
        if (!orderChanged.startsWith("Cancel-") && selectedAccount) {
            // Fetch user trades
            fetchUserTrades();

            // Start periodic check for balances
            startPeriodicCheck();
        }
    }, [orderChanged]);

    // Clean async jobs
    useEffect(() => {
        return () => {
            // Stop tradebook periodic check
            if (realTimeCallback.current) {
                clearInterval(realTimeCallback.current);
            }
        }
    }, []);

    // Arrange fields when type changed
    useEffect(() => {
        if (type === 'general') { // Set active book as general book
            setActiveTradeBook(generalTradeBook);
        } else { // Set active book as user book
            setActiveTradeBook(userTradeBook);
        }
    }, [type, generalTradeBook, userTradeBook]);

    // Get loading of active panel
    const getLoading = (type) => {
        return type === 'general' ? gLoading : uLoading;
    }

    return (
        [
            <div className={"inline-tab-wrapper"}>
                <ul className={"inline-tab"}>
                    <li key={"Tradebook"}><button className={type === 'general' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation(); setType('general')}}>Market Trades</button></li>
                    <li key={"Userbook"}><button className={type !== 'general' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation(); setType('user')}}>My Trades</button></li>
                </ul>
            </div>,
            <div className="gray-container-tabbed" style={{display: "flex", flexDirection: "column", flex: "1 1 auto"}}>
                { getLoading(type) || activeTradeBook.length > 0 ? <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{flex: "1 1 1px", outline: 'none', marginTop: "0px", paddingBottom:"5px", borderBottom: "1px solid #33393f"}}>
                    <table className="coins enumarable-table news-table-responsive linked-table" >
                        <thead>
                        <tr>
                            <th className="fixed-gray-header" style={{textAlign: 'left', paddingLeft: "7px"}}>Time</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left'}}>Price({selectedSpot.quoted_asset})</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left'}}>Amount({selectedSpot.base_asset}}</th>
                        </tr>
                        </thead>
                        <tr>
                            <td colSpan={3}><Loading loading={type === 'general' ? gLoading : uLoading}/></td>
                        </tr>
                        {!getLoading(type) && <tbody>
                            {activeTradeBook.map((row, i) => (
                                <tr onClick={() => setSelectedPrice(parseFloat(row.price))}>
                                    <td style={{textAlign: 'left'}}>
                                        <div className="text">{moment(row.timestamp).format('DD.MM.YYYY HH:mm:ss')}</div>
                                    </td>
                                    <td style={{textAlign: 'left', color: detectSideColor(row.side)}}>
                                        <div className="text">{row.price}</div>
                                    </td>
                                    <td style={{textAlign: 'left'}}>
                                        <div className="text">{row.amount}</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>}
                    </table>
                </div> : (
                    selectedAccount ? <p style={{marginTop: "10px", color: "white"}}>You don't have {selectedSpot.base_asset} / {selectedSpot.quoted_asset} trade on {selectedAccount.key_name}</p> :
                    <p style={{marginTop: "10px", color: "white"}}>No accounts to show. <a style={{color: "white", cursor: "pointer", textDecoration: "underline"}} onClick={() =>
                        history.push({
                            pathname: '/exchanges',
                            state: {exchange: "Connect Now"}
                            })
                    }>Connect Now</a></p>
                )}
            </div>
        ]
    )

}


export default withRouter(Tradebook);
