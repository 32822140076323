// src/react-auth0-spa.js
import React, {useContext, useEffect, useState} from "react";
import Api from "../api";
import UserApi from "../api/UserApi";
import {Route} from "react-router-dom";
import UpgradeYourPlan from "../pages/UpgradeYourPlan/UpgradeYourPlan";
import {useReactOidc} from "@axa-fr/react-oidc-context";
import NotificationApi from "../api/NotificationApi/NotificationApi";
import {UserManager} from "oidc-client";
import StepUpAuthentication from "../components/StepUpAuthentication";

const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();

/**
 * Check quota access for given field_name and length, returns remaining quota
 *
 * @param user User metadata object
 * @param field_name Field to check
 * @param length Current length of the limited field
 */
export const checkQuotaAccess = (user, field_name, length) => {
    const quotas = user.authorization.rule.quotas;
    if (quotas[field_name] === -1) return 1; // Infinite access return 1
    return quotas[field_name] - length < 0 ? 0 : quotas[field_name] - length;
}

/**
 * Check if user has access to given feature
 *
 * @param user User metadata object
 * @param field_name Feature to check
 */
export const checkFeatureAccess = (user, field_name) => {
    const features = user.authorization.rule.features;
    return features[field_name];
}

/**
 * Check permission for path and route to component if allowed (Not Required Right Now in terms of full page)
 *
 * @param user Object that holds auth information
 * @param path If given returns Route object else component
 * @param permission Which permission feature to look for
 * @param component Component to initiate
 */
export const getAccessibleComponent = (user, path, permission, component) => {
    const notAuth = path ? <Route path={path} component={UpgradeYourPlan}/> : <UpgradeYourPlan/>;
    if (!checkFeatureAccess(user, permission)) return notAuth;

    // Authorized return accordingly
    return path ? <Route path={path}  component={component}/> : component;
}

/**
 * Get step up applied component
 *
 * @param path Where route is directing
 * @param scopeName Scope name to check
 * @param component Component to create after stepped up
 * @return {JSX.Element}
 */
export const getStepUpComponent = (path, scopeName, component) => {
    return <Route path={path} render={(props) => <StepUpAuthentication {...props} checkScope={scopeName} component={component}/>}/>;
}

const stepUpAuthorize = (url) => {
    if (!url) {
        console.log(document.location.toString())
        url = document.location.toString();
    }

    const oidcConfiguration = {
        client_id: "social_client",
        redirect_uri: window.location.origin + '/authentication/callback',
        response_type: 'code',
        scope: 'openid profile email 2fa-required',
        authority: 'https://ssodev.cryptoindexseries.com/auth/realms/CIS',
        silent_redirect_uri: window.location.origin + '/authentication/silent_callback',
        automaticSilentRenew: true,
        monitorSession: false,
        accessTokenExpiringNotificationTime: 30,
        post_logout_redirect_uri: window.location.origin,
        loadUserInfo: false,
    };

    const manager = new UserManager(oidcConfiguration);
    return manager.signinRedirect({
        state: { url: url }
    });
}


export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK}) => {
    const { oidcUser, logout} = useReactOidc();
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [token, setToken] = useState();
    const [loading, setLoading] = useState(false);
    const [scopes, setScopes] = useState(null);

    useEffect(() => {
        const initAuth0 = async () => {

            if (window.location.search.includes("code=") &&
                window.location.search.includes("state=")) {
                onRedirectCallback(oidcUser.state);
            }
            setIsAuthenticated(oidcUser);

            if (oidcUser) {
                // Add interceptor to be used with token
                Api.updateToken(oidcUser.access_token);

                try {
                    const user = await UserApi.getProfile();

                    // Register user device for notifications
                    NotificationApi.subscribeUser();

                    // Get notifications of the user
                    // const n = await NotificationApi.getNotifications();

                    // Set internal values
                    setUser(user);
                    setToken(oidcUser.access_token);
                    if (oidcUser.scope !== scopes)
                        setScopes(oidcUser.scope);
                } catch (error){
                    console.log(error)
                    setIsAuthenticated("denied");
                }
            }
            setLoading(false);
        };
        initAuth0();
        // eslint-disable-next-line
    }, [oidcUser]);

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                token,
                setUser,
                loading,
                scopes,
                logout,
                stepUpAuthorize
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};

