import React, {useState} from 'react';
import moment from 'moment';
import SummaryModal from "../../../pages/News/SummaryModal";
import {useAuth0} from "../../../helpers/Auth0";



const LatestNews = (props) => {
  const {news} = props;
  const [show, setShow] = useState(false);
  const [item, setItem] = useState({});
  const {user} = useAuth0();

  return (

    <div className="inp-column">
      <div className="gray-container latest-news">
        <div className="section-header">Latest News</div>
        <div className="news-list" id="beauty-scroll3">
          <ul style={{margin: '1em'}}>
            {news && news.length &&
              news.map((d, i) => {
                const source = d.source.split("_").map(word =>  word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                const date = moment.utc(d.publication_datetime).local();
                return (
                  <li key={i} onClick={() => {setShow(true); setItem(d);}}>
                    <div className="ln-date">
                      <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                    </div>
                    <div className="ln-text">{d.title}</div>
                    <div className="ln-link">
                      {' '}
                      <a target="_blank" className="blue-link" href={d.link}>
                        {source}
                      </a>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <SummaryModal show={show} item={item} userPref={user.preferences.sentiment_field} cancel={() => setShow(false)}/>
    </div>
  );
};

export default LatestNews;
