import React, {useEffect, useRef, useState} from 'react';
import DataMappers from "../../helpers/DataMappers";
import Api from "../../api";
import Loading from "../Loading";
import CISNavigator from "../../helpers/CISNavigator";
import {withRouter} from "react-router-dom";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

const StateBarIndex = props => {
    const {
        indexCode, // Index code for fetching Index data
        selectedExchange, // Information of selected exchange
        isInPage, // Indicates if the bar is in page (smaller font size)
        history, // For router events
        clickable // Indicates if state bar is clickable
    } = props;

    // Extract selected exchange value for viewing purposes
    const exchange = selectedExchange.value;
    // Index data of the sector
    const [indexData, setIndexData] = useState(null);
    // Indicates if the data is loading
    const [loading, setLoading] = useState(true);
    // Realtime related states and functions
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker([], DataMappers.indexTickerMapper);

    // Ticker symbol to subscribe
    const tickerSymbol = indexCode + "-" + exchange + ".CISIDX";
    // Realtime receivers
    const [ticker, setTicker] = RealtimeUtils.useRealTimeRow(tickerSymbol, setChannel);

    // Executes when props are changed
    useEffect(() => {
        // Fetch index for the sector
        if (indexCode) {
            fetchIndex(indexCode)
        }
    }, [indexCode, selectedExchange]);

    // Fetches full index from index code
    const fetchIndex = (indexCode) => {
        setLoading(true);
        Api.readIndexFull(indexCode, exchange).then(res => {
            // Set index data
            setIndexData(res);
            // Set ticker data from index data
            const tickerData = DataMappers.baseTickerMapperRaw(res.index_ticker, true);
            setTicker(tickerData);
            // Start real time updates
            initSocketConnection([tickerSymbol]);
            // Show what we loaded
            setLoading(false);
        });
    };

    return (
        <Loading loading={loading}>
            {indexData && <div className="row row-center">
            <div className={isInPage ? "col-lg-12 in-page" : "col-lg-11"} style={clickable ? {cursor: "pointer"} : {}} onClick={ e => {
                if (clickable) {
                    e.stopPropagation();
                    CISNavigator.navigateToIndexDetail(history, indexData.index_info, selectedExchange)
                }
            }}>
                <div className="grid-group" id="grid-group-statebar">
                    <div className={"row"} style={{"marginLeft" : "0.1em"}}>
                        <div className="primary-text above" style={{"textAlign" : "start"}}>
                            {indexData.index_info.code}
                        </div>
                        <div className="secondary-text" style={{"marginTop" : "4px", "fontSize": "14px", "color" : "white", "marginBottom" : "10px"}}>
                            {indexData.index_info.name}
                        </div>
                    </div>
                </div>
                <div className="pull-right grid-group mobile-state-bar" id="grid-group-statebar-2">
                    <div className={"row grid-group-item-wo-pd"} id="grid-group-first-item" style={{"marginLeft" : "0.5em"}}>
                        <div className="secondary-text">
                            VALUE ({exchange})
                        </div>
                        <div className={`primary-text primary-value ${ticker.price_update_class}`}>
                            {ticker && ticker.price}
                        </div>
                    </div>
                    <div className={"row grid-group-item-wo-pd"}>
                        <div className="secondary-text">
                            MKT CAP
                        </div>
                        <div className={`primary-text primary-value ${ticker.market_cap_update_class}`}>
                            {ticker.market_cap}
                        </div>
                    </div>
                    <div className={"row grid-group-item-wo-pd"}>
                        <div className="secondary-text">
                            24h VOL
                        </div>
                        <div className={`primary-text primary-value ${ticker.vol24_update_class}`}>
                            {ticker.vol24}
                        </div>
                    </div>
                    <div className={"row grid-group-item-wo-pd"}>
                        <div className="secondary-text">
                            24h LOW ({exchange})
                        </div>
                        <div className={`primary-text primary-value ${ticker.low_update_class}`}>
                            {ticker.low}
                        </div>
                    </div>
                    <div className={"row grid-group-item-wo-pd"}>
                        <div className="secondary-text">
                            24h HIGH ({exchange})
                        </div>
                        <div className={`primary-text primary-value ${ticker.high_update_class}`}>
                            {ticker.high}
                        </div>
                    </div>
                    <div className={"row grid-group-item-wo-pd"} style={{"marginRight" : "0.1em"}} id="mobile-change-indices">
                        <div className="secondary-text">
                            CHG (24H)
                        </div>
                        <div className={`primary-text primary-value ${ticker.chngpct_update_class} ${ticker.chngpct_class}`}>
                            {ticker.chngpct}
                        </div>
                    </div>
                </div>
            </div>
            {!isInPage && <div className={"col-lg-1 hide-content"}>
                <div className="pull-right">
                    <a target="_blank" rel="noopener noreferrer" href={indexData.index_info.document_url}>
                        <img className="actionable-img" src="/img/ic_document.png" alt="" style={{"height": "50px"}}/>
                    </a>
                </div>
            </div>}
        </div>}
        </Loading>
    );
};

export default withRouter(StateBarIndex);
