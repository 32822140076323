import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import Breadcrumps from '../../constants/Breadcrumps';
import Api from '../../api';
import TradingView from "../../tradingview";
import SymbolSearch from "./SymbolSearch";
import InstrumentOverview from "./InstrumentOverview";
import UserApi from "../../api/UserApi";
import AccountSummary from "./AccountSummary";
import Order from "./Order";
import Orderbook from "./Orderbook";
import Tradebook from "./Tradebook";
import AppHelpers from "../../helpers/AppHelpers";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";
import Utils from "../../helpers/Utils";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";

import {useAuth0} from "../../helpers/Auth0";
import DataMappers from "../../helpers/DataMappers";


const isMobile = Utils.checkMobile();

const filter = {
  // tags: '',
  // news_sources: '',
  // min_publication_datetime: '',
  // max_publication_datetime: '',
  page_number: 1,
  page_size: 20,
  language_code: 'en',
};

const filterParam = {
  page_size: 25,
  asset_type: '',
  coin_symbols: '',
  business_classification: '',
  ccy: 'USD',
  order_by: 'mkt_cap,desc',
  ts_cutoff: 0,
  page_number: 1,
};

const Trade = (props) => {
  const {exchanges} = props;

  // Selected spot states
  const [selectedSpot, setSelectedSpot] = useState(null);
  // Spot/Instrument info of selected spot
  const [spotInfo, setSpotInfo] = useState(null);
  // Symbol mappings of selected spot per exchange
  const [symbolMappings, setSymbolMappings] = useState({loaded: false});
  // User account connections
  const [userAccounts, setUserAccounts] = useState([]);
  // Selected user account connection
  const [selectedAccount, setSelectedAccount] = useState(null);
  // Selected spot and account balance information
  const [selectedBalance, setSelectedBalance] = useState(null);
  // Triggered when an order is created or canceled from this app
  const [orderChanged, setOrderChanged] = useState("");
  // Selected price to delegate to order creation page
  const [selectedPrice, setSelectedPrice] = useState(0);
  // User information
  const {user} = useAuth0();
  //User WatchListShow preference
  const [watchListShow,setWatchListShow] = useState(user.preferences.show_watchlist);

  const [selectedDropDown, setSelectedDropDown] = useState(false);

  const accounts = useRef(null);

  // Fetches user accounts and updates states
  const fetchAccounts = () => {
    UserApi.readKeys().then(res => {
      setUserAccounts(res);
    })
  }

  const fetchMappings = () => {
    if (selectedSpot) {
      // Spot may be changed but we may have already loaded the respective mappings (Same spot different exchange)
      if (symbolMappings.loaded && symbolMappings[selectedSpot.exchange_id] && symbolMappings[selectedSpot.exchange_id].native_symbol === selectedSpot.native_symbol) {
        return;
      }

      const params = {
        base_asset: selectedSpot.base_asset,
        quoted_asset: selectedSpot.quoted_asset,
        is_connectable: true
      }

      // Make an additional spot search to extract all exchanges
      Api.spotSearch(params).then(res => {
        const mappings = {loaded: true};
        // Generate mappings
        res.data.forEach(el => {
          const exchange = exchanges[el.source_code];
          mappings[exchange.id] = el.native_symbol;
        });

        // Update mappings globally
        setSymbolMappings(mappings);
      }).catch(err => {
        console.error(err);
        setSymbolMappings({loaded: true});
      });
    }
  }

  const arrangeAccounts = () => {
    // Convert accounts to map of exchange symbol to key ID
    const accountMap = {};
    userAccounts.forEach(el => accountMap[el.exchange] = el.key_id);

    // Set reference value and return
    accounts.current = accountMap;
  }

  // Fetch spot/instrument info and set it
  const fetchSpotInfo = () => {
    if (selectedSpot) {
      Api.getInstrumentInfo(selectedSpot.cis_native_symbol).then(res => {
        // Set precision as a integer on trading info
        const ti = res.cis_trading_info;
        ti.loaded = true;
        ti.base_precision = AppHelpers.countPrecision(parseFloat(ti.base_increment_size));
        ti.base_pow = Math.pow(10, ti.base_precision);
        ti.quote_precision = AppHelpers.countPrecision(parseFloat(ti.quote_increment_size));
        ti.quote_pow = Math.pow(10, ti.quote_precision);
        ti.total_precision = ti.base_precision + ti.quote_precision;
        ti.total_pow = Math.pow(10, ti.total_precision);
        ti.total_increment_size = Math.pow(10, -ti.total_precision);
        setSpotInfo(ti);
      }).catch(err => {
        setSpotInfo({loaded: true});
      });
    }
  };


  const getSpot = () =>{
    const params = {
      is_connectable: true,
      base_asset:"BTC",
      quoted_asset:"USDT"
    };

    Api.spotSearch(params,isMobile?10:20).then(async res => {

      // Puts selected spot at top if any

      const uaMap = accounts.current || {};

      res.data = res.data || [];
      res.data.forEach(el => el.ticker= el.ticker || {});
      // Generate readable tickers
      res.data.forEach(el => {
        el.org_ticker = el.ticker;
        el.ticker = DataMappers.spotTickerMapper(el.ticker);
      });

      const inSpots = res.data.map(el => {
        // Add exchange name and ID
        const exc = exchanges[el.source_code];
        if (exc) {
          el.exchange_name = exc.name;
          el.exchange_id = exc.id;

          // Add user account key ID if possible
          el.key_id = uaMap && uaMap[exc.id];
        } else { // In case exchange not defined (Not likely when we get it from API)
          el.exchange_name = el.source_code;
        }
        return el;
      });


      setSelectedSpot(inSpots[0]);

    });

  }

  // When initiated fetch user accounts
  useEffect(() => {
    arrangeAccounts();
    fetchAccounts();
    getSpot();
  }, [])


  useEffect(() => {
    // Fetch mappings when spot is changed
    fetchMappings();
    // Fetch spot information
    fetchSpotInfo();
    // Set selected balance to null
    setSelectedBalance(null);

  }, [selectedSpot]);

  // Selected spot is changed, fetch extra spot information

  return (
    isMobile ?


          <div>
            <MobileNavBar/>
            <div>
              {watchListShow? <Watchlist />:<></>}
            </div>
            <div className="mobile-Div">
              <React.Fragment>
                <section className="coin-table padding-container">
                  <div className="overview-inner">
                    <div className="overview" style={{display: "flow-root", marginTop: "10px"}}>
                      <SymbolSearch selectedSpot={selectedSpot} setSelectedSpot={setSelectedSpot} userAccounts={userAccounts}/>
                    </div>
                  </div>
                </section>
              </React.Fragment>
            </div>

            <div style={{height:"80px"}}>

              <p></p>
            </div>
          </div>

         :

          <React.Fragment>
            <section className="coin-table padding-container">
              <div className="watchlist-all-page">
                {watchListShow? <Watchlist />:<></>}
              </div>
              <Breadcrump links={Breadcrumps.trade} />
              <div className="overview-inner">
                <div className="overview" style={{display: "flow-root", marginTop: "10px"}}>
                  <div className={"container"} style={{width: "100%", padding: 0}}>
                    <div className={"col-lg-12"} style={{padding: 0}}>
                      {selectedSpot && <InstrumentOverview selectedDropDown={selectedDropDown} setSelectedDropDown={setSelectedDropDown} selectedSpot={selectedSpot} userAccounts={userAccounts} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount}/>}
                       <SymbolSearch selectedSpot={selectedSpot} setSelectedSpot={setSelectedSpot} userAccounts={userAccounts} setSelectedDropDown={setSelectedDropDown} selectedDropDown = {selectedDropDown}/>
                    </div>
                    <div className={"col-lg-12"} style={{width: "100%", padding: 0, display: "flex"}}>
                      <div className={"col-lg-3"} style={{padding: 0, marginTop: "10px", paddingRight: "5px"}}>
                        {selectedSpot && <Orderbook selectedSpot={selectedSpot} setSelectedPrice={setSelectedPrice}/>}
                      </div>
                      <div className={"container col-lg-6"} style={{padding: 0, paddingLeft: "5px", paddingRight: "5px"}}>
                        <div className={"col-lg-12"} style={{padding: 0, marginTop: "10px", height: "50vh"}}>
                          {selectedSpot && spotInfo && <TradingView ticker={selectedSpot.cis_native_symbol} pricescale={spotInfo.quote_pow} symbol={selectedSpot.base_asset} exchange={selectedSpot.quoted_asset} type={selectedSpot.source_code}/>}
                        </div>
                        <div className={"col-lg-12"} style={{padding: 0, marginTop: "10px", }}>
                          {selectedSpot && spotInfo && <Order selectedPrice={selectedPrice} selectedSpot={selectedSpot} spotInformation={spotInfo} selectedAccount={selectedAccount} selectedBalance={selectedBalance} setOrderChanged={setOrderChanged}/>}
                        </div>
                      </div>
                      <div className={"col-lg-3"} style={{padding: 0, marginTop: "10px", paddingLeft: "5px", display: "flex", flexDirection: "column"}}>
                        {selectedSpot && <Tradebook selectedSpot={selectedSpot} selectedAccount={selectedAccount} setSelectedPrice={setSelectedPrice} orderChanged={orderChanged}/>}
                      </div>
                    </div>
                  </div>
                  {selectedSpot && <AccountSummary selectedSpot={selectedSpot} orderChanged={orderChanged} setOrderChanged={setOrderChanged}
                                                   userAccounts={userAccounts} selectedAccount={selectedAccount} symbolMappings={symbolMappings}
                                                   setSelectedBalance={setSelectedBalance}/>}
                </div>
              </div>
            </section>
          </React.Fragment>
  );
};

const mapStateToProps = state => {
  // Convert exchanges to map of symbol to exchange data
  const exchangeMap = {};
  state.meta.connectable_exchanges.forEach(el => exchangeMap[el.code] = el);

  return {
    meta: state.meta,
    exchanges: exchangeMap,
  };
};

export default connect(mapStateToProps)(Trade);
