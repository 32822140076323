import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import TopBannerArea from '../../components/BannerAreas/TopBannerArea';
import Watchlist from '../../components/PageDashboard/Watchlist';
import TopIndices from '../../components/PageDashboard/TopIndices/TopIndices';
import LatestNews from '../../components/News/LatestNews';
import Api from '../../api';
import SmallCoinList from '../../components/SmailCoinList/SmallCoinList';
import TradingView from '../../tradingview/App';
import {useAuth0} from "../../helpers/Auth0";
import Utils from "../../helpers/Utils";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";

const isMobile = Utils.checkMobile();

const filterParam = {
  page_size: isMobile?10:25,
  asset_type: '',
  coin_symbols: '',
  business_classification: '',
  ccy: 'USD',
  order_by: 'mkt_cap,desc',
  ts_cutoff: 0,
  page_number: 1,
};

const Dashboard = props => {
  const {meta} = props;
  const {user} = useAuth0();
  const [indexes, setIndexes] = useState({items: [], loading: true});
  const [news, setNews] = useState({items: [], loading: true});
  const [result, setResult] = useState({items: [], loading: true});
  const [exchange, setExchange] = useState(user.preferences.currency);
  const [exchange2, setExchange2] = useState(user.preferences.currency);
  const [watchListShow,setWatchListShow] = useState(user.preferences.show_watchlist);
  const [selectedExchange, setSelectedExchange] = useState({value: user.preferences.currency});

  const currencyList =
    (meta.lists &&
      meta.lists.key_currency_list &&
      meta.lists.key_currency_list.map(c => ({value: c[0], label: c[0], type: c[2] === 'FIAT' ? 'USD' : 'BTC'}))) ||
    [];

  useEffect(() => {
    // Fetch news
    fetchNews();
  }, []);

  useEffect(() => {
    let selectedExchange = currencyList.filter(e => e.value === exchange);
    selectedExchange = (selectedExchange.length && selectedExchange[0]) || {};
    setSelectedExchange(selectedExchange);
    fetchData(exchange);
  }, [exchange]);

  useEffect(() => {
    fetchIndexes(exchange2);
  }, [exchange2]);

  const fetchIndexes = ex => {
    setIndexes({items: [], loading: true});
    Api.getPromise(`/search/IndexSearch?order_by=mkt_cap,desc&ccy=${ex}&page_size=12`)
      .then(res => {
        setIndexes({items: (res && res.data) || [], loading: false});
      })
      .catch(e => setIndexes({items: [], loading: false}));
  };

  const fetchNews = () => {
    let f = {}
    if(isMobile){
      f = {page_size:5};
    }
    setNews({...news, loading: true});
    Api.getPromise(`/News/NewsSearch?language_code=en`,f)
      .then(res => {
        setNews({items: (res && res.data) || [], loading: false});
      })
      .catch(e => setNews({items: [], loading: false}));
  };

  const fetchData = ex => {
    let f = filterParam;
    f.ccy = ex;
    setResult({items: [], loading: true});
    setTimeout(() => {
      Api.getPromise('/Search/CoinSearch', f)
        .then(res => {
          const data = (res && res.data) || [];
          setResult({items: data, loading: false});
        })
        .catch(e => setResult({items: [], loading: false}));
    }, 500);
  };


  return (
    isMobile ?
        <div>
          <MobileNavBar/>
          <div>
              {watchListShow? <Watchlist />:<></>}
          </div>
          <div className="mobile-Div">
            <div className="chart-area">
              <TradingView symbol={"BTC"} exchange={selectedExchange.value}/>
            </div>
          </div>
          <div className="mobile-Div">
            <SmallCoinList
                homeTable
                columnClass={'top-coins'}
                subClass={'gray-container'}
                selectedExchange={selectedExchange}
                setResult={setResult}
                exchange={exchange}
                setExchange={setExchange}
                result={result}
                title={`TOP COINS`}
            />
          </div>
          <div className="mobile-Div">
            <TopIndices indexes={indexes} exchange={exchange2} setExchange={setExchange2} selectedExchange={selectedExchange} currencyList={currencyList} />
          </div>
          <div className="mobile-Div">
            <LatestNews news={news.items} />
          </div>
          <div style={{height:"80px"}}>

            <p></p>
          </div>
        </div>
        :
        <section className="dashboard padding-sm-container">
            <TopBannerArea />
            {watchListShow? <Watchlist />:<></>}
            <div className="flex-row">
              <div className="flex-width-left">
                <div className="chart-area">
                  <TradingView symbol={"BTC"} exchange={selectedExchange.value}/>
                </div>
                <div className="indices-news-part">
                  <TopIndices indexes={indexes} exchange={exchange2} setExchange={setExchange2} selectedExchange={selectedExchange} currencyList={currencyList} />
                  <LatestNews news={news.items} />
                </div>
              </div>
              <div className="flex-width-right">
                <SmallCoinList
                  homeTable
                  columnClass={'top-coins'}
                  subClass={'gray-container'}
                  selectedExchange={selectedExchange}
                  setResult={setResult}
                  exchange={exchange}
                  setExchange={setExchange}
                  result={result}
                  title={`TOP COINS`}
                />
              </div>
            </div>
        </section>
  );
};

const mapStateToProps = state => ({
  meta: state.meta
});

export default connect(mapStateToProps)(Dashboard);
