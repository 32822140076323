const Breadcrumps = {
    coins: [
        {
            name: "Home", path: "/"
        },
        {
            name: "Coins", path: "/coins"
        },

    ],
    trade: [
        {
            name: "Home", path: "/"
        },
        {
            name: "Trade", path: "/trade"
        },
    ],

    news: [
        {
            name: "Home", path: "/"
        },
        {
            name: "News", path: "/news"
        },
    ],
    sectors: [
        {
            name: "Home", path: "/"
        },
        {
            name: "Sectors", path: "/sectors"
        },
    ],
    indices: [
        {
            name: "Home", path: "/"
        },
        {
            name: "Indices", path: "/indices"
        },
    ],
    alerts: [
        {
            name: "Home", path: "/"
        },
        {
            name: "Alerts", path: "/alerts"
        },
    ]
}


export default Breadcrumps;