import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Api from "../../../api";
import PieChart from "../../../components/Chart/PieChart";
import FilterForm from "../../../components/Forms/FilterForm/FilterForm";
import SectorCoinTable from "../../../components/Sector/SectorCoinTable";
import Loading from "../../../components/Loading";


const SectorAnalysis = props => {


    const {history, meta,exchange,rawData} = props;
    const params = {
        alphanumeric_code:rawData.alphanumeric_code,
        ccy:exchange.value
    };
    const [totalCoin,setTotalCoin] = useState();
    const [assetType,setAssetType] = useState({items:[],loading:true});
    const [marketCapSplit,setMarketCapSplit] = useState({items:[],loading:true});
    const [filter,setFilter] = useState({
        volatilities: ["Low","Normal","High"],
        volumes: ["Low","Medium-Low","Medium","Medium-High","High","Very High","Extremely High"],
        marketCaps: ["Small","Mid","Large","Huge"]
    });
    const [loading,setLoading] = useState(false);
    const [coinDatas,setCoinDatas] = useState(null);
    const [filterDatas,setFilterDatas] = useState(null);



    const fetchPieChartDatas = () => {
        Api.getSectorPieDatas(params).then(resp =>{
            setTotalCoin(resp.coin_count);
            setAssetType({items:resp.asset_type_split,loading: false});
            setMarketCapSplit({items:resp.market_cap_split,loading: false});
        });

    }

    const fetchSectorCoinDatas = () => {
        Api.getSectorCoinDatas(params).then(resp =>{
            setCoinDatas(resp);
            const filterData =resp.filter(d => (filter.marketCaps.includes(d.market_cap_category)&&filter.volumes.includes(d.volume_category)&&filter.volatilities.includes(d.volatility_category)));
            setFilterDatas(filterData);

        });
    }
    useEffect(()=>{
        fetchPieChartDatas();
    },[]);

    useEffect(()=>{
        fetchSectorCoinDatas();
    },[exchange]);

    useEffect(()=>{
        if(coinDatas && filter){
            const filterData =coinDatas.filter(d => (filter.marketCaps.includes(d.market_cap_category)&&filter.volumes.includes(d.volume_category)&&filter.volatilities.includes(d.volatility_category)));
            setFilterDatas(filterData);
        }
    },[filter]);

    return (
        <div className="overview-inner">
            <div className="row" style={{marginTop: "10px"}}>
                <div className="col-lg-6" style={{paddingRight:"5px"}}>
                    <div className="gray-container">
                        <div style={{color:"white",fontSize:"25px",textAlign:"center",fontWeight:"bold",paddingBottom:"10px"}}>
                            Asset Type Breakdown
                        </div>
                        <Loading loading={assetType.loading} >
                        <PieChart  resp={assetType.items} type={"Asset"}/>
                        </Loading>
                    </div>
                </div>
                <div className="col-lg-6" style={{paddingLeft:"5px"}}>
                    <div className="gray-container">
                        <div style={{color:"white",fontSize:"25px",textAlign:"center",fontWeight:"bold",paddingBottom:"10px"}}>
                            Market Cap Breakdown
                        </div>
                        <Loading loading={marketCapSplit.loading} >
                            <PieChart  resp={marketCapSplit.items} type={"MarketCap"}/>
                        </Loading>
                    </div>
                </div>
                <div className="col-lg-12" style={{paddingTop:"10px"}}>
                    <div className="gray-container">
                        <FilterForm setFilter={setFilter}/>
                        {filterDatas &&<SectorCoinTable datas={filterDatas} />}

                    </div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        meta: state,
    };
};

export default connect(mapStateToProps)(SectorAnalysis);


