import React, {useEffect, useState} from 'react';
import ReadMoreReact from 'read-more-react';
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

const AssetSummary = props => {
  const {desc,row} = props;

  return (
    <div className="gray-container summary">

      <div className="section-header">Coin Summary</div>
        <hr></hr>
        <div style={{color:"white",fontFamily: "Roboto , sans-serif",fontSize:"18px",fontWeight:"bold"}}>Market Cap Category:  {row.market_cap_category}</div>

        <div style={{color:"white",paddingTop:"10px",fontFamily: "Roboto , sans-serif",fontSize:"18px",fontWeight:"bold"}}>Summary</div>
      {!isMobile ? <div id="body-scroll"
        style={{textAlign: 'justify', height: 250, overflowY: 'scroll', margin: '1em', color: '#fff',paddingRight: 15}}
        dangerouslySetInnerHTML={{__html: desc}}
        className="summary-par"/>
        :
        <ReadMoreReact text={desc}
                min={500}
                ideal={500}
                max={1500}
                style={{color:'white'}}
                readMoreText="Read More..."/>}
      <div className="clearfix" />
    </div>
  );
};

export default AssetSummary;
