import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import AssetSummary from '../../components/AssetSummary';
import LatestNews from '../../components/News/LatestNews';
import Api from '../../api';
import Loading from '../../components/Loading';
import Taxonomy from '../../components/Taxonomy';
import SmallCoinList from '../../components/SmailCoinList/SmallCoinList';
import TradingView from '../../tradingview/App';
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();


const CoinList = props => {
  const {row, selectedExchange, result, setResult, taxonomy2} = props;
  const [data, setData] = useState({items: row, loading: false});
  const [news, setNews] = useState({items: [], loading: true});

  useEffect(() => {
    if (row.symbol) {
      setData({items: row, loading: false});
      fetchNews();
    }
  }, [row.symbol]);

  const fetchNews = () => {
    setNews({...news, loading: true});
    Api.getPromise(`/News/NewsSearch?language_code=en&page_size=20&page_number=1&tags=COIN_${row.symbol}`)
      .then(res => {
        setNews({items: (res && res.data) || [], loading: false});
      })
      .catch(e => setNews({items: [], loading: false}));

  };


  return (
    isMobile ?

    taxonomy2 == "true" ? <Loading loading={data.loading || news.loading}>
      <div className="overview-inner">
        <div className="overview-row">

          <div>
            <Taxonomy data={(data.items.coin_info && data.items.coin_info) || {}} />
            <SmallCoinList
              columnClass={'gray-container basic-lists2'}
              subClass={''}
              selectedExchange={selectedExchange}
              setResult={setResult}
              result={{...result, items: result.items.filter(i => i.symbol !== row.symbol).slice(0,10)}}
              title={'Top 10 Similar Assets'}
            />
          </div>
        </div>
      </div>
    </Loading> :
        <Loading loading={data.loading || news.loading }>
      <div className="overview-inner">
        <div className="overview-row">
          <div className="overview-left-column">
            <AssetSummary desc={data.items.coin_info && data.items.coin_info.description} row={row}/>
          </div>
          <div>
            <div className="chart-area" style={{height:"auto"}}>
                <TradingView symbol={data.items.coin_info && data.items.coin_info.symbol} exchange={selectedExchange.value}/>
            </div>
          </div>
        </div>
      </div>
    </Loading> :
    <Loading loading={data.loading || news.loading}>
      <div className="overview-inner">
        <div className="overview-row">
          <div className="overview-left-column">
            <AssetSummary desc={data.items.coin_info && data.items.coin_info.description} row={row}/>
          </div>
          <div className="overview-right-column">
            <div className="chart-area" style={{height:"auto"}}>
                <TradingView symbol={data.items.coin_info && data.items.coin_info.symbol} exchange={selectedExchange.value}/>
            </div>
          </div>
        </div>
        <div className="overview-row">
          <div className="overview-left-column">
            <LatestNews news={news.items} />
          </div>
          <div className="overview-right-column">
            <Taxonomy data={(data.items.coin_info && data.items.coin_info) || {}} />
            <SmallCoinList
              columnClass={'gray-container basic-lists'}
              subClass={''}
              selectedExchange={selectedExchange}
              setResult={setResult}
              result={{...result, items: result.items.filter(i => i.symbol !== row.symbol).slice(0,10)}}
              title={'Top 10 Similar Assets'}
            />
          </div>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(CoinList);
