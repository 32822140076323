import AppHelpers from "./AppHelpers";

/**
 * Contains utility functions...
 *
 * @type {{prepareCoinSelections(*=): this}}
 */
const Utils = {

    /**
     * Number polarity class generator
     *
     * @input value Value to generate from
     */
    textColorPolarity(value) {
        let percentageClass = 'table-gray-text';
        const isPositive = AppHelpers.isPositiveFloat(value);
        if (isPositive === 1 || isPositive === -1) {
            percentageClass = isPositive === 1 ? 'table-green-text' : 'table-red-text';
        }
        return percentageClass;
    },


    /**
     * Prepares coins fetched from API for dropdown selections
     *
     * @input coins Raw coins fetched from metadata api
     */
    prepareCoinSelections(coins) {
        const allCoinsT = (coins && coins.length && [...coins]) || [];
        return allCoinsT
            .map(c => ({...c, label: `${c.symbol} (${c.name})`, value: c.symbol}))
            .sort((a, b) => {
                var textA = a.symbol.toUpperCase().trim();
                var textB = b.symbol.toUpperCase().trim();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
    },

    /**
     * Scroll to bottom child element referenced by childRef inside parent element
     * referenced as parentRef. Example use case buy is orderbook which is scrolled bottom
     * when loaded.
     *
     * @param childRef Child reference
     * @param parentRef Parent reference
     */
    scrollToChild(childRef, parentRef) {
        parentRef.current.scrollTo && parentRef.current.scrollTo(0, childRef.current.offsetTop);
    },
    /**
     * Check device is mobile for all pages
     */
    checkMobile(){
        var userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
        const isMobile = /windows phone/i.test(userAgent) || /android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
        return isMobile;
    }

};


export default Utils;
