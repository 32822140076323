import React, {useState, useEffect, useRef} from 'react';
import AppHelpers from '../../helpers/AppHelpers';
import Select from '../Forms/Select';
import CoinRow from '../CoinRow';
import CISNavigator from "../../helpers/CISNavigator";
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

const HomeTable = props => {
    const {title, exchange, currencyList, setExchange, selectedExchange, result, history, parentSubType} = props;
    return (
        isMobile ?
            <>
                <div className="table-head">
                    <div className="table-left">
                        <div className="table-header">{title}</div>
                    </div>
                    {setExchange && (
                        <div className="table-right">
                            <div className={'table-select-input'}>
                                <div className={'sm-select-input small-select'}>
                                    <Select selected={exchange} options={currencyList}
                                            onChange={selected => setExchange(selected.value)}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="table-body home-table table-responsive">
                    <table>
                        <thead>
                        <tr>
                            <th>Coin</th>
                            <th>Price</th>
                            <th>Mkt. Cap</th>
                            <th>Vol</th>
                            <th>Volto</th>
                            <th>24h Change%</th>
                        </tr>
                        </thead>
                        <tbody>
                        {result.items.map((row, i) => (
                            <CoinRow
                                key={i}
                                parentSubType={parentSubType}
                                type={'homeTable'}
                                rowClick={() => {
                                    CISNavigator.navigateToCoinDetail(history, row, selectedExchange);
                                    document.body.scrollTop = 0;
                                    document.documentElement.scrollTop = 0;
                                }}
                                row={row}
                                selectedExchange={selectedExchange}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
            </> :
            <>
                <div className="table-head">
                    <div className="table-left">
                        <div className="table-header">{title}</div>
                    </div>
                    {setExchange && (
                        <div className="table-right">
                            <div className={'table-select-input'}>
                                <div className={'sm-select-input small-select'}>
                                    <Select selected={exchange} options={currencyList}
                                            onChange={selected => setExchange(selected.value)}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="table-body home-table table-responsive" style={{height: 820}}>
                    <table>
                        <thead>
                        <tr>
                            <th colSpan={3}/>
                            <th colSpan={3}>LAST 24h</th>
                        </tr>
                        <tr>
                            <th>Coin</th>
                            <th>Price</th>
                            <th>Mkt. Cap</th>
                            <th>Vol</th>
                            <th style={{paddingLeft: "0px"}}>Volto</th>
                            <th>Change</th>
                        </tr>
                        </thead>
                        <tbody>
                        {result.items.map((row, i) => (
                            <CoinRow
                                key={i}
                                parentSubType={parentSubType}
                                type={'homeTable'}
                                rowClick={() => {
                                    CISNavigator.navigateToCoinDetail(history, row, selectedExchange);
                                    document.body.scrollTop = 0;
                                    document.documentElement.scrollTop = 0;
                                }}
                                row={row}
                                selectedExchange={selectedExchange}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
            </>
    );
};

export default HomeTable;
