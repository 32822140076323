import AppHelpers from "./AppHelpers";
import React from "react";
import Utils from "./Utils";

/**
 * Contains mapper functions to map data fetched from API to CIS Web App.
 */
const DataMappers = {
    /**
     * Maps color for blinking
     *
     * @param val1 First value to compare
     * @param val2 Second value to compare
     * @returns {string}
     */
    mapBlinkColor(val1, val2) {
        if (val1 < val2) {
            return "price-back-red";
        } else if (val2 < val1) {
            return "price-back-green";
        } else {
            return "non-update"
        }
    },

    /**
     * Generate bubble color for given sentiment score, color intensity is increased as
     * absolute value of sentiment score increases.
     *
     * @param sentScore Sentiment score
     */
    mapSentimentColor(sentScore) {
        let color = "#16191c";
        if (sentScore > 0.0) {
            color = `radial-gradient(circle, #16191c 0%, rgba(0,255,0,${(sentScore / 1.0) * 0.7 + 0.3}) 0%, rgba(0,255,0,0) 100%)`
        } else if (sentScore < 0.0) {
            color = `radial-gradient(circle, #16191c 0%, rgba(255,0,0,${(sentScore / -1.0) * 0.7 + 0.3}) 0%, rgba(249,0,0,0) 100%)`
        }

        return color;
    },

    /**
     * Set update class and return if we should update the ticker
     *
     * @param newTicker New ticker message
     * @param oldTicker Old ticker message
     * @param disabledFields Disabled fields on update checks
     * @param enabledAnimations indicates which animations are enabled
     */
    shouldUpdate(newTicker, oldTicker, disabledFields = [], enabledAnimations = ["price"]) {
        // Updatable fields
        const fields = ["chngpct", "price", "market_cap", "vol24", "v", "high", "low"];
        // Track if we should update
        let shouldUpdate = !oldTicker || Object.keys(oldTicker).length === 0;

        // Compare tickers
        if (oldTicker) {
            fields
                .filter(el => !disabledFields.includes(el))
                .forEach(el => {
                    const color = DataMappers.mapBlinkColor(newTicker[el], oldTicker[el]);
                    shouldUpdate = color !== 'non-update' || shouldUpdate;
                    newTicker[el + '_update_class'] = enabledAnimations.includes(el) ? color : "non-update";
                });
        }

        return [newTicker, shouldUpdate];
    },

    /**
     * Indicates if we should put this field to display (Used for compare and update)
     *
     * @param field Field to check
     * @param ticker Ticker message
     */
    shouldDisplay(ticker, field) {
        if (ticker.mt === 'update') return ticker[field];
        return true;
    },

    /**
     * Map ticker for without update classes
     *
     * @param ticker Ticker to map
     * @param forIndex Indicates if the mapping for index
     */
    baseTickerMapperRaw(ticker, forIndex) {
        // Display ticker object
        const displayTicker = {};
        // Set ticker to empty map if it is null
        ticker = ticker ? ticker : {};

        // Map base ticker values
        if (DataMappers.shouldDisplay(ticker, 'chgpct')) {
            displayTicker['chngpct'] = ticker.chgpct ? (ticker.chgpct * 100).toFixed(2) : '--';
            displayTicker['ochngpct'] =  ticker.chgpct ? (ticker.chgpct * 100).toFixed(2) : 0.0;
            displayTicker['chngpct_class'] = Utils.textColorPolarity(displayTicker['chngpct']); // Generate color class for percentage
            displayTicker['chngpct'] = displayTicker['chngpct'] + ' %';
        }
        if (DataMappers.shouldDisplay(ticker, 'lst'))
            displayTicker['price'] = ticker.lst ? AppHelpers.priceFormat(ticker.lst) : '--';
        if (DataMappers.shouldDisplay(ticker, forIndex ? 'index_market_cap' : 'market_cap')) {
            displayTicker['market_cap'] = AppHelpers.numberFormat(ticker[forIndex ? 'index_market_cap' : 'market_cap']);
            displayTicker['market_cap'] = displayTicker['market_cap'] ? displayTicker['market_cap'] : '--';
        }
        if (DataMappers.shouldDisplay(ticker, forIndex ? 'index_total_volume' : 'vto')) {
            displayTicker['vol24'] = AppHelpers.numberFormat(ticker[forIndex ? 'index_total_volume' : 'vto']);
            displayTicker['vol24'] = displayTicker['vol24'] ? displayTicker['vol24'] : '--';
        }
        if (DataMappers.shouldDisplay(ticker, 'v'))
            displayTicker['v'] = ticker.v ? AppHelpers.numberFormat(ticker.v) : '--';
        if (DataMappers.shouldDisplay(ticker, 'h'))
            displayTicker['high'] = ticker.h ? AppHelpers.priceFormat(ticker.h) : '--';
        if (DataMappers.shouldDisplay(ticker, 'l'))
            displayTicker['low'] = ticker.l ? AppHelpers.priceFormat(ticker.l) : '--';

        displayTicker['lst_ts'] = ticker.lst_ts;
        return displayTicker;
    },

    /**
     * Ticker mapper for spots
     *
     * @param ticker
     */
    spotTickerMapper(ticker) {
        // Get base mapping
        const displayTicker = DataMappers.baseTickerMapperRaw(ticker, false);

        // Map extra fields
        if (DataMappers.shouldDisplay(ticker, 'chg')) {
            displayTicker['chng'] = ticker.chg ? AppHelpers.priceFormat(ticker.chg) : '--';
            displayTicker['chng_class'] = Utils.textColorPolarity(ticker.chg);
        }

        return displayTicker;
    },

    /**
     * Ticker mapper for index tickers
     *
     * @param ticker
     */
    indexTickerMapper(ticker) {
        return DataMappers.baseTickerMapperRaw(ticker, true);
    },

    /**
     * Ticker mapper for coin tickers
     *
     * @param ticker
     */
    coinTickerMapper(ticker) {
        return DataMappers.baseTickerMapperRaw(ticker, false);
    },

    /**
     * Base ticker mapper to map ticker objects. Implement a different function and use this as
     * base for mapping custom fields.
     *
     * @param ticker Ticker object
     * @param forIndex Indicates if the ticker is for index
     * @param oldTicker Old ticker to compare
     */
    baseTickerMapper(ticker, forIndex = false, oldTicker) {
        // Set ticker to empty map if it is null
        ticker = ticker ? ticker : {};

        // Map base ticker values
        const displayTicker = DataMappers.baseTickerMapperRaw(ticker, forIndex);

        // Generate blink color
        return DataMappers.shouldUpdate(displayTicker, oldTicker);
    },

    /**
     * Remove updates from ticker
     *
     * @param ticker Ticker to change
     */
    removeUpdates(ticker) {
        const newTicker = Object.assign({}, ticker);

        // Disable updates
        newTicker['chngpct_update_class'] = 'non-update';
        newTicker['price_update_class'] = 'non-update';
        newTicker['market_cap_update_class'] = 'non-update';
        newTicker['vol24_update_class'] = 'non-update';
        newTicker['v_update_class'] = 'non-update';
        newTicker['high_update_class'] = 'non-update';
        newTicker['low_update_class'] = 'non-update';

        return newTicker;
    },

    /**
     * Coin supply mapper
     *
     * @param supply Supply to map
     * @param oldSupply Old supply to compare
     */
    coinSupplyMapper(supply, oldSupply) {
        const displaySupply = {};
        // Set ticker to empty map if it is null
        supply = supply ? supply : {};

        // Map supply values
        displaySupply['total_supply'] = AppHelpers.numberFormat(supply.total_supply);
        displaySupply['total_supply'] = displaySupply['total_supply'] ?  displaySupply['total_supply'] : '--';
        displaySupply['max_supply'] = AppHelpers.numberFormat(supply.max_supply);
        displaySupply['max_supply'] = displaySupply['max_supply'] ?  displaySupply['max_supply'] : '--';
        displaySupply['circulating_supply'] = AppHelpers.numberFormat(supply.circulating_supply);
        displaySupply['circulating_supply'] = displaySupply['circulating_supply'] ?  displaySupply['circulating_supply'] : '--';

        // Generate blink color
        if (oldSupply) {
            displaySupply['total_supply_update_class'] = DataMappers.mapBlinkColor(displaySupply['total_supply'], displaySupply['total_supply']);
            displaySupply['max_supply_update_class'] = DataMappers.mapBlinkColor(displaySupply['max_supply'], displaySupply['max_supply']);
            displaySupply['circulating_supply_update_class'] = DataMappers.mapBlinkColor(displaySupply['circulating_supply'], displaySupply['circulating_supply']);
        }

        return displaySupply;
    },

    /**
     * Maps full coin data for visualisation (ticker and supply)
     *
     * @param coin Coin data to map
     */
    coinDataMapper(coin) {
        // Map coin ticker
        coin.display_ticker = DataMappers.baseTickerMapperRaw(coin.ticker);

        // Map coin supply
        coin.display_supply = DataMappers.coinSupplyMapper(coin.supply);

        // Map weight if exists
        coin.display_weight = (coin.weight_pct ? (coin.weight_pct * 100).toFixed(2) : '--') + ' %';

        // Return new coin object
        return coin;
    },


    /**
     * Clean supply updates and get ready for new blinks
     *
     * @param supply
     */
    cleanSupplyUpdates(supply) {
        const newSupply = Object.assign({}, supply);
        newSupply['total_supply_update_class'] = 'non-update';
        newSupply['max_supply_update_class'] = 'non-update';
        newSupply['circulating_supply_update_class'] = 'non-update';
        return newSupply;
    },

    /**
     * Clean coin updates and get ready for new blinks
     *
     * @param coin Coin to clean updates
     * @returns
     */
    cleanCoinUpdates(coin) {
        // Clean ticker updates
        coin.ticker = DataMappers.cleanCoinUpdates(coin.ticker);

        // Clean supply updates
        coin.supply = DataMappers.cleanSupplyUpdates(coin.supply);

        // Return new coin object
        return coin;
    }
};



export default DataMappers;

