import Loading from "../../components/Loading";
import React, {useEffect, useRef, useState} from "react";
import ExchangeApi from "../../api/ExchangeApi";
import Utils from "../../helpers/Utils";


const Orderbook = props => {
    const {
        selectedSpot, // Selected spot to show for orderbook
        setSelectedPrice // Send selected price to order creation screen
    } = props;

    // Orderbook connection and visual references
    const connection = useRef(null);
    const isScrolled = useRef(false);
    const tableEndRef = useRef(null);
    const parentRef = useRef(null);

    // Buy and sell orders
    const [loading, setLoading] = useState(false);
    const [buyOrders, setBuyOrders] = useState([]);
    const [sellOrders, setSellOrders] = useState([]);

    // Stop orderbook connection
    const closeConnection = () => {
        // Stop connection
        connection.current && connection.current.destroyConnection();
    };

    useEffect(() => {
        // Stop connection if exists
        closeConnection()
        isScrolled.current = false;
        setLoading(true);

        // Get connection api for the selected spot
        if (selectedSpot) {
            connection.current = ExchangeApi.getApi(selectedSpot.source_code)
            connection.current && connection.current.subscribeOrderbook(selectedSpot.native_symbol, setBuyOrders, setSellOrders);
        }
    }, [selectedSpot]);

    // Clean real time connections
    useEffect(() => {
        return () => {
          closeConnection()
        }
    }, []);

    useEffect(() => {
        setLoading(buyOrders.length === 0 && sellOrders.length === 0);
    }, [buyOrders, sellOrders]);

    // Scroll to bottom at buy orders once spot changed
    useEffect(() => {
        if (!isScrolled.current && tableEndRef.current && buyOrders.length > 0) {
            Utils.scrollToChild(tableEndRef, parentRef);
            isScrolled.current = true
        }
    }, [tableEndRef.current, isScrolled.current, buyOrders]);

    return (
        <div className="gray-container" style={{display: "flex", flexDirection: "column", height: "100%", borderRadius:"0px"}}>
            <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{flex: "1 1 1px", outline: 'none', marginTop: "0px"}} ref={parentRef}>
                <table className="coins enumarable-table news-table-responsive linked-table" style={{tableLayout:"fixed"}}>
                    <thead>
                        <tr>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingRight:"1px"}}>Price({selectedSpot.quoted_asset})</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingLeft:"0px"}}>Amount({selectedSpot.base_asset}}</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingLeft:"10px"}}>Total({selectedSpot.quoted_asset})</th>
                        </tr>
                    </thead>
                    {loading && <tr>
                        <td colSpan={3}><Loading loading={loading}/></td>
                    </tr>}
                    {!loading && <tbody>
                        {buyOrders.map((row, i) => (
                            <tr onClick={() => setSelectedPrice(parseFloat(row[0]))} ref={i === 19 ? tableEndRef : null}>
                                <td style={{textAlign: 'left',paddingRight:"2px"}}>
                                    <div className="text" style={{color:'#c11e0f'}}>{row[0]}</div>
                                </td>
                                <td style={{textAlign: 'left',paddingLeft:"0px",paddingRight:"0px"}}>
                                    <div className="text">{row[1]}</div>
                                </td>
                                <td style={{textAlign: 'left',paddingLeft:"10px"}}>
                                    <div className="text">{row[2]}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>}
                </table>
            </div>
            <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{flex: '1 1 1px', outline: 'none'}}>
                <table className="coins enumarable-table news-table-responsive linked-table" style={{tableLayout:"fixed"}}>
                    <thead>
                        <tr>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingRight:"1px"}}>Price({selectedSpot.quoted_asset})</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingLeft:"0px"}}>Amount({selectedSpot.base_asset}}</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left'}}>Total({selectedSpot.quoted_asset})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Loading loading={loading}>
                            {sellOrders.map((row, i) => (
                                <tr onClick={() => setSelectedPrice(parseFloat(row[0]))}>
                                    <td style={{textAlign: 'left',paddingRight:"2px"}}>
                                        <div className="text" style={{color:'#7ac231'}}>{row[0]}</div>
                                    </td>
                                    <td style={{textAlign: 'left',paddingLeft:"0px",paddingRight:"0px"}}>
                                        <div className="text">{row[1]}</div>
                                    </td>
                                    <td style={{textAlign: 'left',paddingLeft:"10px"}}>
                                        <div className="text">{row[2]}</div>
                                    </td>
                                </tr>
                            ))}
                        </Loading>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Orderbook;
