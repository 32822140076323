import UserApi from "../../api/UserApi";
import React from "react";
import {toastr} from 'react-redux-toastr';
import {setLoading} from "../../actions/generalAction";
import {connect} from "react-redux";

const ExchangeConfirmation = props => {
    const {item, show, close, fetchKeys, setFullLoading} = props;

    const onDelete = () => {
        if (item.key_id) {
            setFullLoading(true);
            UserApi.deleteKey(item.key_id).then(res => {
                setFullLoading(false);
                if (fetchKeys) fetchKeys();
                toastr.success(`Key ${item.key_name} successfully deleted...`)
                close();
            }).catch(err => {
                setFullLoading(false);
                toastr.info(`Something went wrong while deleting key ${item.key_name}...`)
                console.error(err);
            });
        }
    };
    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="delete-modal">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            Delete
                            <div onClick={close} className="close-modal" data-target="delete-modal">
                                <img src="/img/close-white.svg" alt="" />
                            </div>
                        </div>
                        <div className="modal-white-body">
                            <div className="section-header">Are you sure to delete this exchange.</div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner">
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal" data-target="set-price-alert">
                                                NO
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner">
                                        <div className="light-blue-btn">
                                            <button onClick={onDelete}>YES</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

export default connect(null, mapDispatchToProps)(ExchangeConfirmation);
