import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Select from '../../Forms/Select';
import Api from '../../../api';
import InputMask from 'react-input-mask';
import {toastr} from 'react-redux-toastr';
import Utils from "../../../helpers/Utils";
import UserApi from "../../../api/UserApi";


const isMobile = Utils.checkMobile();

const frequency = [
  {value: 1, label: 'One Time'},
  {value: 2, label: 'Recursive'}
]


const defaultFilter = {
  keyword: '',
  type: {value: 1, label: 'Price'},
  currVal: 0,
  alert_name: '',
  percent: '',
  inc: true,
  target: 0,
  frequency: 1,
  symbol: '',
  exchange: '',
};

const SetPriceAlertModal = props => {
  const {close, show, meta, isNew, fetchAlerts, locState, history} = props;
  const {lists, allCoins} = meta || {};
  // Prepare coin list from metadata for dropdown list
  const allCoinsT = Utils.prepareCoinSelections(allCoins);
  const currencyList =
    (lists && lists.key_currency_list && lists.key_currency_list.map(c => ({value: c[0], label: c[0], type: c[2] === 'FIAT' ? 'USD' : 'BTC'}))) || [];
  const [filter, setFilter] = useState(defaultFilter);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const onChange = (type, val) => {
    let newFilter = {...filter};
    newFilter[type] = val;
    setFilter(newFilter);
  };

  useEffect(() => {
    if (isNew && isNew.alert_id && show) {
      setFilter({...defaultFilter, ...isNew, ...isNew.price_alert});
    } else if (!(isNew && isNew.alert_id) && locState && show) {
      let from = allCoinsT.filter(c => c.value === locState.from);
      let to = currencyList.filter(c => c.value === locState.to);
      from = from.length ? from[0] : {};
      to = to.length ? to[0] : {};
      setFilter(f => ({...f, symbol: from.value, exchange: to.value}));
    } else {
      setFilter(defaultFilter);
    }
  }, [show, isNew, locState]);

  useEffect(() => {
    if (filter.exchange && filter.symbol) {
      fetchCoins(filter.symbol, filter.exchange);
    }
  }, [filter.exchange, filter.symbol]);

 
  useEffect(() => {
    if (filter.currVal && parseFloat(filter.percent)) {
      let val = parseFloat(filter.currVal * parseFloat(filter.percent) * 0.01);
      val = filter.inc ? val : val * -1;
      setFilter(f => ({...f, target: f.currVal + val}));
    } else if (filter.inc && filter.percent) {
      setFilter(f => ({...f, target: f.currVal}));
    }
  }, [filter.currVal, filter.percent, filter.inc]);

  const fetchCoins = (from, to) => {
    const params = {
      coin_symbols: from || '',
      ccy: to || '',
    };
    Api.getPromise('/Search/CoinSearch', params)
      .then(res => {
        const price = (res.data[0] && res.data[0].ticker && res.data[0].ticker.lst) || 0;
        if (isNew && isNew.id) {
          if (isUpdate) {
            setFilter(f => ({...f, currVal: price}));
          } else setIsUpdate(true);
        } else {
          setFilter(f => ({...f, currVal: price, price}));
        }
      })
      .catch(e => setFilter(f => ({...f, currVal: 0})));
  };

  const onSave = () => {
    setLoading(true);
    let type = 'create';
    const price_alert = {"symbol": filter.symbol, "exchange": filter.exchange, "target": filter.target, "frequency": filter.frequency};
    let params = {alert_name: filter.alert_name, price_alert: price_alert, alert_type: 'price'};

    if (isNew && isNew.alert_id) {
      type = 'update';
      params = {alert_id: isNew.alert_id, alert_name: filter.alert_name, price_alert: price_alert, alert_type: 'price'};
    }
    if (!filter.alert_name) {
      toastr.error('', 'Name Required');
      setLoading(false);
    } else if (!filter.symbol || !filter.symbol || !filter.target) {
      toastr.error('', 'Coin Required and target value required');
      setLoading(false);
    } else {
      if (type === 'update') {
        UserApi.updateAlert(isNew.alert_id, params).then(res => {
          if (fetchAlerts) fetchAlerts();
          if (!locState) close();
          toastr.success('', 'Successfully Saved');
          setFilter(defaultFilter);
          setLoading(false);
          if (locState) history.replace('/coins');
        });
      } else {
        UserApi.createAlert(params).then(res => {
          if (fetchAlerts) fetchAlerts();
          if (!locState) close();
          toastr.success('', 'Successfully Saved');
          setFilter(defaultFilter);
          setLoading(false);
          if (locState) history.replace('/coins');
        });
      }
    }
  };

  return (
    <div className={`general-modal ${show ? 'active' : ''}`} id="set-price-alert">
      <div className="vAlignWr">
        <div className="vAlign">
          <div className="white-modal-inner set-alert">
            <div className="modal-blue-header">
              Set Alerts
              <div onClick={close} className="close-modal" data-target="set-price-alert">
                <img src="img/close-white.svg" alt="" />
              </div>
            </div>
            <div className="modal-white-body">
              <div className="general-tab-area">
                <div className="general-tab-menu">
                  <ul>
                    <li className="active">
                      <a href="#" data-target="price-alert">
                        Set Price Alert
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="general-tab-body-all">
                  <div className="general-tab-body active" id="price-alert">
                    <div className="set-price-alert">
                      <div className="calc-price-number">
                        <div className="input-table">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="spa-text">Alert Name</div>
                                </td>
                                <td colSpan="2">
                                  <div className="bottom-border-input">
                                    <input type="text" maxLength={50} placeholder="My Price Alert" value={filter.alert_name} onChange={val => onChange('alert_name', val.target.value)} />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="spa-text">Coin</div>
                                </td>
                                <td>
                                  <div className="sm-select-input flLeft" style={{padding: '0px'}}>
                                    <Select
                                      style={{width: '93.5px'}}
                                      selected={filter.symbol || 0}
                                      options={allCoinsT.filter(v => v.value !== filter.exchange) || []}
                                      optionValue={'symbol'}
                                      placeholder={'Symbol'}
                                      optionLabel={'value'}
                                      onChange={selected => onChange('symbol', selected.value)}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="sm-select-input flLeft" style={{padding: '0px'}}>
                                    <Select
                                      style={{width: '93.5px'}}
                                      selected={filter.exchange || 0}
                                      placeholder={'Currency'}
                                      options={currencyList.filter(v => v.value !== filter.symbol)}
                                      onChange={selected => onChange('exchange', selected.value)}
                                    />
                                  </div>
                                </td>
                              </tr>
                              {/*<tr>*/}
                              {/*  <td>*/}
                              {/*    <div className="spa-text">Type</div>*/}
                              {/*  </td>*/}
                              {/*  <td>*/}
                              {/*    <div className="sm-select-input" style={{padding: '0px'}}>*/}
                              {/*      <Select*/}
                              {/*        selected={filter.frequency}*/}
                              {/*        options={[{value: 1, label: 'Price'}]}*/}
                              {/*        onChange={selected => onChange('type', selected)}*/}
                              {/*      />*/}
                              {/*    </div>*/}
                              {/*  </td>*/}
                              {/*</tr>*/}
                              <tr>
                                <td>
                                  <div className="spa-text">Current Value</div>
                                </td>
                                <td>
                                  <div className="calculated-price">
                                    <div className="spa-text">{filter.currVal}</div>
                                  </div>
                                </td>
                                <td>
                                  <div className="gray-border-checkbox increase">
                                    <label className="gbc-label">
                                      <div className="gbc-text">Increase</div>
                                      <input type="radio" name="incdec" checked={!filter.hasOwnProperty("inc") || filter.inc} onChange={val => onChange('inc', true)} />
                                      <div className="gbc-checkmark" />
                                    </label>
                                  </div>
                                  <div className="gray-border-checkbox decrease">
                                    <label className="gbc-label">
                                      <div className="gbc-text">Decrease</div>
                                      <input type="radio" name="incdec" checked={filter.hasOwnProperty("inc") && !filter.inc} onChange={val => onChange('inc', false)} />
                                      <div className="gbc-checkmark" />
                                    </label>
                                  </div>

                                {isMobile && <div style={{display: 'flex', flexDirection: 'row'}} className="bottom-border-input">
                                <InputMask
                                  className="number-input price-percentage"
                                  type="text"
                                  mask="99.99"
                                  maskChar=""
                                  value={filter.percent}
                                  onChange={val => onChange('percent', val.target.value)}
                                />
                                <div className="input-info-icon-line">%</div>
                                </div>}

                                </td>
                                <td className="percentage-input">
                                {!isMobile && <div style={{display: 'flex', flexDirection: 'row'}} className="bottom-border-input">
                                    <InputMask
                                      className="number-input price-percentage"
                                      type="text"
                                      mask="99.99"
                                      maskChar=""
                                      value={filter.percent || ''}
                                      onChange={val => onChange('percent', val.target.value)}
                                    />
                                    <div className="input-info-icon-line">%</div>
                                  </div>}
                                  
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="spa-text">Target Value</div>
                                </td>
                                <td>
                                  <div className="bottom-border-input">
                                    <InputMask
                                      className="target-price price-check number-input"
                                      type="text"
                                      value={filter.target}
                                      onChange={val => {
                                        let dec = val.target.value.split('.');
                                        dec = dec[1] && dec[1].length > 6;
                                        if ((/^[0-9]+\.?[0-9]*$/.test(val.target.value) && !dec) || val.target.value == '')
                                          onChange('target', parseFloat(val.target.value));
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="spa-text">Frequency</div>
                                </td>
                                <td>
                                  <div className="sm-select-input" style={{padding: '0px'}}>
                                    <Select
                                      selected={filter.frequency}
                                      options={frequency}
                                      onChange={selected => onChange('frequency', selected)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-gray-footer">
              <div className="form-buttons">
                <div className="form-flex">
                  <div className="form-flex-inner">
                    <div className="light-gray-btn">
                      <button onClick={close} className="close-modal" data-target="set-price-alert">
                        CANCEL
                      </button>
                    </div>
                  </div>
                  <div className="form-flex-inner">
                    <div className="light-blue-btn">
                      <button disabled={loading} onClick={onSave}>
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    meta: state.meta,
    user: state.user,
  };
};
export default connect(mapStateToProps)(SetPriceAlertModal);
