import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {toastr} from 'react-redux-toastr';
import UserApi from "../../api/UserApi";
import {useAuth0} from "../../helpers/Auth0";
import Select from "../../components/Forms/Select";
import {setLoading} from "../../actions/generalAction";

const defaultFilter = {
    key_name: '',
    exchange: ''
};

const ExchangeCreate = props => {
    const {exchanges, isNew, close, show, fetchKeys, locState, history, setFullLoading} = props;
    const [filter, setFilter] = useState(defaultFilter);
    const {token} = useAuth0();

    const onChange = (type, val) => {
        let newFilter = {...filter};
        newFilter[type] = val;
        setFilter(newFilter);
    };

    useEffect(() => {
        if (isNew && isNew.key_id && show) { // Update is requested
            setFilter(isNew);
        } else if (!(isNew && isNew.id) && locState && show) { // User is navigated to this page with add exchange (Not used)
            setFilter(f => ({...f, exchange: locState.exchange}));
        } else { // Create requested
            setFilter(defaultFilter);
        }
    }, [show, isNew, locState]);

    /**
     * Generate key part in request, returns null if invalid
     *
     * @param filter
     * @return {*}
     */
    const generateKey = (filter) => {
        let key = {};
        let isValid = true;
        // Validate required fields
        const exc = exchanges[filter.exchange];
        exc.exchange_connection_required_fields.forEach(el => {
            if (!filter[el.field_name]) {
                isValid = false;
            } else {
                key[el.field_name] = filter[el.field_name];
            }
        })

        // Check if all required fields are entered
        if (!isValid) {
            toastr.error('', 'You must enter exchange connection details');
            setFullLoading(false);
            return null;
        }

        return key;
    }

    const onSave = () => {
        setFullLoading(true);
        let type = (isNew && isNew.key_id) ? 'update' : 'create';

        if (!filter.key_name) {
            toastr.error('', 'Name required.');
            setFullLoading(false);
        } else if (!filter.exchange) {
            toastr.error('', 'You must choose exchange.');
            setFullLoading(false);
        }  else {
            if (type === 'update') {
                let key;
                // Validate and create key object
                if (exchanges[filter.exchange].exchange_connection_required_fields.some(el => filter[el.field_name])) {
                    key = generateKey(filter);
                    if (!key) return;
                }

                setFullLoading(true);
                UserApi.updateKey(isNew.key_id, filter.key_name, filter.exchange, filter.exchange && exchanges[filter.exchange].code, token, key).then(res => {
                    if (fetchKeys) fetchKeys();
                    if (!locState) close();
                    toastr.success('', 'Successfully Saved');
                    setFilter(defaultFilter);
                    setFullLoading(false);
                    if (locState) history.replace('/news');
                }).catch(err => {
                    console.error(err)
                    setFullLoading(false);
                    toastr.error('Something went wrong while updating key');
                });
            } else {
                // Validate and create key object
                const key = generateKey(filter)
                if (!key) return;

                setFullLoading(true);
                UserApi.createKey(filter.key_name, filter.exchange, exchanges[filter.exchange].code, token, key).then(res => {
                    if (fetchKeys) fetchKeys();
                    if (!locState) close();
                    toastr.success('', 'Successfully Saved');
                    setFilter(defaultFilter);
                    setFullLoading(false);
                    if (locState) history.replace('/news');
                }).catch(err => {
                    console.error(err);
                    setFullLoading(false);
                    toastr.error('Something went wrong while creating key');
                });
            }
        }
    };

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-news-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            Set Exchanges
                            <div onClick={close} className="close-modal" data-target="set-news-alert">
                                <img src="/img/close-white.svg" alt="" />
                            </div>
                        </div>
                        <div style={{minWidth: '50vw'}} className="modal-white-body">
                            <div className="general-tab-area">
                                <div className="general-tab-menu">
                                    <ul>
                                        <li className="active">
                                            <a href="#" data-target="price-alert">
                                                Set Exchange
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="general-tab-body-all">
                                    <div className="general-tab-body active" id="news-alert">
                                        <div className="set-news-alert">
                                            <div className="input-table">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="spa-text">Name</div>
                                                        </td>
                                                        <td>
                                                            <div className="bottom-border-input">
                                                                <input type="text" placeholder="My Exchange Connection" maxLength={50} value={filter.key_name} onChange={val => onChange('key_name', val.target.value)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="spa-text">Exchange</div>
                                                        </td>
                                                        <td style={{width: '100%'}}>
                                                            <div className="sm-select-input">
                                                                <Select
                                                                    selected={filter.exchange || 0}
                                                                    optionValue={'id'}
                                                                    optionLabel={'name'}
                                                                    options={Object.values(exchanges) || []}
                                                                    onChange={selected => onChange('exchange', selected.id)}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {filter.exchange && exchanges[filter.exchange].exchange_connection_required_fields.map(el =>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">{el.field_label}</div>
                                                            </td>
                                                            <td>
                                                                <div className="bottom-border-input">
                                                                    <input type="text" placeholder={el.field_placeholder} value={filter[el.field_name]} onChange={val => onChange(el.field_name, val.target.value)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>
                                                <div className="clearfix" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner">
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal" data-target="set-news-alert">
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner">
                                        <div className="light-blue-btn">
                                            <button onClick={onSave}>
                                                SAVE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    // Generate a map from exchanges list
    const exchangeMap = {}
    state.meta.connectable_exchanges.forEach(el => {
        exchangeMap[el.id] = el;
    })

    return {
        exchanges: exchangeMap
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeCreate);
