import React, {useState, useEffect} from 'react';
import CoinRow from '../../../components/CoinRow';
import SetWatchListModal from '../../Modals/SetWatchListModal';
import Loading from '../../Loading';
import {withRouter} from 'react-router-dom';

const StateBar = props => {
  const {row, selectedExchange, history} = props;
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [rowChangeLoad, setRowChangeLoad] = useState(true);

  useEffect(() => {
    setRowChangeLoad(true); // socket yüzünden öldürmek zorundayım ortalık karısıyor :)
    setTimeout(() => {
      if (row.symbol) setRowChangeLoad(false);
    }, 400);
  }, [row]);

  return (
    <div className="state-bar">
      <Loading Loading={rowChangeLoad}>
        {!rowChangeLoad && (
          <CoinRow type={'stateBar'} rowClick={() => null} setAdd={setAdd} setShow={setShow} row={row} selectedExchange={selectedExchange} />
        )}
      </Loading>
      <div className="green-border-btn">
        <a>
          <img className="actionable-img" src="/img/plus-normal.svg" alt="" />
        </a>
      </div>
      <div style={{right: 50, top: 36, width: 50, height: 30, position: 'absolute'}} className="three-dot-menu three-dot-menu-coin-details">
        <div className="dot-menu-icon">
          <img className="actionable-img" src="" alt="" />
        </div>
        <div className="table-dropdown-menu">
          <ul>
            <li>
              <a
                onClick={e => {
                  e.stopPropagation();
                  setShow(true);
                  setAdd({from: row.symbol, to: selectedExchange.value});
                }}
                className=" open-modal-event"
                data-target="set-watch-list">
                <img className="actionable-img" src="/img/rounded-add-button.svg" alt="" />
                Add Watchlist
              </a>
            </li>
            {/*<li>*/}
            {/*  <a*/}
            {/*    onClick={() =>*/}
            {/*      history.push({*/}
            {/*        pathname: '/alerts',*/}
            {/*        state: {from: row.symbol, to: selectedExchange.value, price: true},*/}
            {/*      })*/}
            {/*    }*/}
            {/*    className=" open-modal-event"*/}
            {/*    data-target="set-alert">*/}
            {/*    <Image className="actionable-img" src="set-alarm.svg" alt="" />*/}
            {/*    Set Alert*/}
            {/*  </a>*/}
            {/*</li>*/}
          </ul>
        </div>
      </div>
      <SetWatchListModal modalShow={show} closeModal={() => setShow(false)} add={add} />
    </div>
  );
};

StateBar.defaultProps = {
  exchangeList: [],
};

export default withRouter(StateBar);
