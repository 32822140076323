import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import StateBar from '../../components/Bars/StateBar';
import Api from '../../api';
import MenuContent from './MenuContent';
import Select from '../../components/Forms/Select';
import ReactGA from "react-ga";
import {useAuth0} from "../../helpers/Auth0";
import Utils from "../../helpers/Utils";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";

const isMobile = Utils.checkMobile();


const menuItems = !isMobile ? [
    {
        name: 'OVERVIEW',
        path_matcher: "detail"
    },
    {
        name: 'NEWS',
        path_matcher: "coin-news"
    },
    {
        name: 'SOCIAL MEDIA',
        path_matcher: "social-media"
    },
    {
        name: 'MARKETS',
        path_matcher: "markets"
    }
    // {
    //   id: 4,
    //   name: 'CHARTS',
    // },
] : [
    {
        name: 'OVERVIEW',
        path_matcher: "detail"
    },
    {
        name: 'NEWS',
        path_matcher: "coin-news"
    },
    {
        name: 'TAXONOMY',
        path_matcher: "taxonomy"

    },
    {
        name: 'SOCIAL MEDIA',
        path_matcher: "social-media"
    },
    {
        name: 'MARKETS',
        path_matcher: "markets"
    }
];

const filterParam = {
    page_size: 11,
    asset_type: '',
    coin_symbols: '',
    business_classification: '',
    ccy: 'USD',
    order_by: 'mkt_cap,desc',
    ts_cutoff: 0,
    page_number: 1,
};

const CoinIndicesDetail = props => {
    const {history, assetTypeLists, business, currencyList} = props;

    // Authentication mechanism
    const {user} = useAuth0();

    // Get coin symbol and selected menu (default BTC and detail)
    const coinSymbol = (props.match.params && props.match.params.coinSymbol) || "BTC";
    const menuSelection = (props.match.params && props.match.params.additional) || "detail";
    const matchedMenu = menuItems.find(el => el.path_matcher === menuSelection);

    // Get additional params (ccy)
    const params = new URLSearchParams(props.location.search);
    const exchangeCode = params.get("ccy");

    // Selected coin row
    const [selectedRow, setSelectedRow] = useState({});

    // Additional parameters
    const [result, setResult] = useState({items: [], loading: true});
    const [exchange, setExchange] = useState(exchangeCode || user.preferences.currency);
    const [selectedExchange, setSelectedExchange] = useState((exchangeCode && {value: exchangeCode}) || {value: user.preferences.currency});
    const [crumps, setCrumps] = useState([]);

    // Arrange crumps data
    const arrangeCrumps = (coinInfo, exchange) => {
        const name = `${coinInfo.name} (${coinInfo.symbol}/${exchange})`;
        setCrumps([
            {
                name: 'Home',
                path: '/',
            },
            {
                name: 'Coins',
                path: '/coins',
            },
            {
                name: name,
                path: `/coins/${coinInfo.symbol}/detail`,
            },
        ]);
    }

    useEffect(() => {
        // If an invalid menu given convert to detail
        if (!matchedMenu) {
            history.push(`./${menuItems[0].path_matcher}`);
        }
    }, [matchedMenu]);

    useEffect(() => {
        try {
            // Send changes to analytics on page change
            ReactGA.pageview(window.location.pathname);
        } catch (e) {
            console.error(e);
        }
    }, [selectedRow.symbol, window.location.pathname]);

    useEffect(() => {
        // Find selected exchange
        let selectedExchange = currencyList.filter(e => e.value === exchange);
        selectedExchange = (selectedExchange.length && selectedExchange[0]) || {};
        setSelectedExchange(selectedExchange);

        // Change ccy param at url
        history.replace({
            pathname: history.location.pathname,
            search: `?ccy=${selectedExchange.value}`
        });

        // If symbol is given fetch coin data
        if (coinSymbol) {
            fetchData(exchange);
        }
    }, [exchange, coinSymbol]);

    // Menu changed; update link and path
    const menuChanged = (s) => {
        history.push({
            pathname: './' + s.path_matcher,
            search: history.location.search
        });
    }

    const fetchData = ex => {
        let f = filterParam;

        setResult({items: [], loading: true});
        Api.getPromise(`/Coin/GetCoin?coin_symbol=${coinSymbol}&language_code=en`).then(res => {
            const info = (res && res.data && res.data.coin_info) || {};
            let asset = assetTypeLists.filter(a => info.asset_type && info.asset_type.includes(a.asset_type_code));
            let bus = business.filter(b => b.alphanumeric_code === info.business_classification_code);
            if (asset[0] && asset[0].asset_type_id) f.asset_type = asset[0].asset_type_id;
            if (bus[0] && bus[0].business_classification_id) f.business_classification = bus[0].business_classification_id;
            setSelectedRow({...res.data, ...res.data.coin_info});
            arrangeCrumps(res.data.coin_info, ex);
            f.ccy = ex;
            setTimeout(() => {
                Api.getPromise('/Search/CoinSearch', f)
                    .then(res => {
                        const data = (res && res.data) || [];
                        setResult({items: data, loading: false});
                    })
                    .catch(e => setResult({items: [], loading: false}));
            }, 500);
        });
    };

    return (
        isMobile ?
            <div>
                <MobileNavBar/>
                <div className="mobile-Div">
                    <div className="row coin_detail_row">
                        <div className="col-sm-7 col-xs-8 coin_detail_text">
                            <div id="filter-area-coins" style={{display: 'none'}}>
                                <p>Coin Details:</p>
                            </div>
                        </div>
                        <div className="col-sm-5 col-xs-4 coin_detail_select">
                            <div className={'table-select-input'}>
                                <div style={{fontSize: 13}}
                                     className={'sm-select-input small-select coin-detail-mobile'}>
                                    <Select selected={exchange} options={currencyList}
                                            onChange={selected => setExchange(selected.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <StateBar row={selectedRow} selectedExchange={selectedExchange}/>
                </div>
                <div className="mobile-Div">
                    <div className="tab-menu">
                        <ul>
                            {menuItems.map((s, i) => (
                                <li key={i} className={history.location.pathname.endsWith(s.path_matcher) ? 'active' : ''}>
                                    <a onClick={() => menuChanged(s)}>{s.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="mobile-Div">
                    <MenuContent
                        setExchange={setExchange}
                        row={selectedRow}
                        setResult={setResult}
                        result={result}
                        selectedExchange={selectedExchange}/>
                </div>
                <div style={{height: "80px"}}/>
            </div> :
            <section className="dashboard padding-sm-container">
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Breadcrump links={crumps}/>
                    <div className={'table-select-input'}>
                        <div style={{fontSize: 13}} className={'sm-select-input small-select'}>
                            <Select selected={exchange} options={currencyList}
                                    onChange={selected => setExchange(selected.value)}/>
                        </div>
                    </div>
                </div>
                <StateBar row={selectedRow} selectedExchange={selectedExchange}/>
                <div className="tab-menu">
                    <ul>
                        {menuItems.map((s, i) => (
                            <li key={i} className={history.location.pathname.endsWith(s.path_matcher) ? 'active' : ''}>
                                <a onClick={() => menuChanged(s)}>{s.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <MenuContent
                    setExchange={setExchange}
                    row={selectedRow}
                    setResult={setResult}
                    result={result}
                    selectedExchange={selectedExchange}/>
            </section>
    );
};

const mapStateToProps = state => {
    // Lists to be used
    const currencyList =
        (state.meta.lists &&
            state.meta.lists.key_currency_list &&
            state.meta.lists.key_currency_list.map(c => ({
                value: c[0],
                label: c[0],
                type: c[2] === 'FIAT' ? 'USD' : 'BTC'
            }))) ||
        [];
    const {lists} = state.meta || {};
    const assetTypeLists = (lists && lists.asset_type_list) || [];
    const business = (lists && lists.business_classification_schema) || [];

    return {
        meta: state.meta,
        currencyList: currencyList,
        assetTypeLists: assetTypeLists,
        business: business
    };
};

export default connect(mapStateToProps)(CoinIndicesDetail);
