import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import TextComplete from '../../../components/TextComplete';
import TopUserMenu from '../TopUserMenu';
import {NavLink, withRouter} from 'react-router-dom';
import Api from "../../../api";
import CISNavigator from "../../../helpers/CISNavigator";
import ReactTooltip from 'react-tooltip';
import {useAuth0} from "../../../helpers/Auth0";
import moment from 'moment';
import Loading from '../../../components/Loading';

const TopMenu = props => {
  const {history} = props;
  const [notifications, setNotifications] = useState({items: [], loading: false});
  const {user} = useAuth0();
  const unReadCount = notifications.items.filter(n => n.unRead).length;
  const userId = user && user.sub;

  // Options for the search box
  const [searchOptions, setSearchOptions] = useState([]);
  // Indicates if the options are loading
  const [loading, setLoading] = useState(false);
  // Search text
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (window.navigator.serviceWorker) { // Gives error in production build
      window.navigator.serviceWorker.addEventListener('message', message => fetchNotifications());
      fetchNotifications();
    }
  }, []);

  const fetchNotifications = () => {
    // if (userId) {
    //   setNotifications(n => ({...n, loading: true}));
    //   FireHelpers.fetchNotifications(userId, n => {
    //     setNotifications({items: n, loading: false});
    //   });
    // }
  };

  const readAll = () => {
    // let doc = fire.firestore().collection('notifications');
    // if (unReadCount) {
    //   doc
    //     .where('userId', '==', userId)
    //     .where('unRead', '==', true)
    //     .get()
    //     .then(function(querySnapshot) {
    //       var batch = fire.firestore().batch();
    //       querySnapshot.forEach(function(doc) {
    //         batch.update(doc.ref, {unRead: false});
    //       });
    //       return batch.commit();
    //     })
    //     .then(function() {
    //       fetchNotifications();
    //     });
    // }
  };

  const deleteAll = () => {
    // let doc = fire.firestore().collection('notifications');
    // if (notifications.items.length) {
    //   doc
    //     .where('userId', '==', userId)
    //     .get()
    //     .then(function(querySnapshot) {
    //       var batch = fire.firestore().batch();
    //       querySnapshot.forEach(function(doc) {
    //         batch.delete(doc.ref);
    //       });
    //       return batch.commit();
    //     })
    //     .then(function() {
    //       fetchNotifications();
    //     });
    // }
  };

    // Search indexes and coins with given string
    const search = (str) => {
        const searchString = str && str.target && str.target.value;
        if (searchString) {
            setLoading(true);
            Api.quickSearch(searchString).then(quickSearch => { // Search coins, indices and sectors
                // Generate coin group for options
                const groupedCoins = quickSearch.coins.map(el => {
                    el.group = "Coins";
                    el.label = el.name + '(' + el.symbol + ')';
                    el.value = el.symbol;
                    return el;
                });

                // Generate index group for options
                const groupedIndex = quickSearch.indices.map(el => {
                    el.group = "Indices";
                    el.label = el.index_code;
                    el.value = el.index_code;
                    return el;
                });

                // Generate sector group for options
                const groupedSector = quickSearch.sectors.map(el => {
                    el.group = "Sectors";
                    el.label = el.sector_name;
                    el.value = el.sector_code;
                    return el;
                });

                // Set options
                setSearchOptions(groupedCoins.concat(groupedIndex).concat(groupedSector));
                setSearchText(searchString);
                setLoading(false);
            });
        } else {
            setLoading(false);
            setSearchOptions([]);
            setSearchText('');
        }
    };

    // Called when search closed, cleans searched items
    const searchClosed = () => {
        if (searchOptions.length > 0) {
            setLoading(false);
            setSearchOptions([]);
            setSearchText('');
        }
    };

    // Navigate user to respective page on selection from search
    const select = selected => {
        if (selected) {
            searchClosed();
            if (selected.group === "Indices") { // If an index is selected navigate to index detail page
                CISNavigator.navigateToIndexDetail(history, selected, {value: user.preferences.currency})
            } else if (selected.group === "Coins") { // If a coin is selected navigate to coin detail page
                CISNavigator.navigateToCoinDetail(history, selected, {value: user.preferences.currency})
            } else { // If a sector is selected navigate to sector detail page
                CISNavigator.navigateToSectorDetail(history, selected, {value: user.preferences.currency})
            }
        }
    };

    return (
        <section className="top-menu">
          <ReactTooltip html={true} />
            <div className="tm-inner">
                <div className="logo">
                    <img src="/img/logo-text.svg" alt=""/>
                </div>
                <div className="tm-inner-right-area">
                    <TextComplete
                        data={searchOptions}
                        className={"test-class"}
                        onSearch={search}
                        onClose={searchClosed}
                        searchText={searchText}
                        loading={loading}
                        onSelect={select}
                    />
                    <div className="watch-lists">
                        <NavLink className="active" to="/watch-list" data-number="+" data-tip={'WATCHLISTS'} data-hover="WATCHLISTS">
                            <img className="actionable-img" src="/img/watchlist.svg" alt=""/>
                        </NavLink>
                    </div>
                    {/*<div onMouseOut={readAll} className="notifications">*/}
                    {/*    <a className={(unReadCount && 'active') || ''} href="#" data-number={unReadCount}*/}
                    {/*       data-hover="NOTIFICATIONS">*/}
                    {/*        <img className="actionable-img" src="/img/ic-notifications.svg" alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <div className="notf-menu-dropdown">*/}
                    {/*        <div className="nmd-header">*/}
                    {/*            Notifications*/}
                    {/*            <a onClick={deleteAll} className="flRight" href="#">*/}
                    {/*                Delete All*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*        <Loading loading={notifications.loading}>*/}
                    {/*            <ul className="active-scroll" id="beauty-scrollnotf">*/}
                    {/*                {notifications.items.map((n, i) => (*/}
                    {/*                    <li key={i}>*/}
                    {/*                        <a>*/}
                    {/*                            <div className="notf-inner">*/}
                    {/*                                <div className="notf-text">{n.message}</div>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="notf-date">{moment(n.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>*/}
                    {/*                        </a>*/}
                    {/*                    </li>*/}
                    {/*                ))}*/}
                    {/*            </ul>*/}
                    {/*        </Loading>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <TopUserMenu/>
                </div>
                <div className="clearfix"/>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
  return {
    state,
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(TopMenu));
