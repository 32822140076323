import React, {useEffect, useRef, useState} from "react";
import Utils from "../../helpers/Utils";
import {connect} from "react-redux";
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Loading from "../../components/Loading";
import Api from "../../api";
import DataMappers from "../../helpers/DataMappers";
import Paginate from "../../components/Paginate";
import {withRouter} from "react-router-dom";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import TextField from "@material-ui/core/TextField";


const isMobile = Utils.checkMobile();

// Represents per row in spot search table or row in markets tab
const SymbolRow = (props) => {
    const {
        history, // History for navigating to exchange connection
        row, // Row base information
        setSelectedSpot, // Triggers selection of row
        selected, // Indicates if this is the selected row
        setChannel, // Subscribe to real time events
        setSelectedDropDown,
    } = props;

    const openDropDown = () => {
        setSelectedDropDown(false);
        setSelectedSpot(row);

    }

    // Handles real time updates on row
    const [tickerData, setTickerData] = RealtimeUtils.useRealTimeRow(row.cis_symbol, setChannel);

    useEffect(() => {
        // Initialize ticker data with coin row
        setTickerData(row.ticker);
    }, [row]);

    return (
        isMobile?
        <tr key={row.cis_symbol} style={{outline: selected ? "thin solid green" : ""}} onClick={() => {
            setSelectedSpot(row)
        }}>
            <td style={{textAlign: 'left'}}>
                <div className="text">{row.exchange_name}</div>
            </td>
            <td style={{textAlign: 'left'}}>
                <div className="text">{row.base_asset}/{row.quoted_asset}</div>
            </td>
            <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                {tickerData.price}
            </td>
            <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                {tickerData.chngpct}
            </td>
        </tr>


            :
        <tr key={row.cis_symbol} style={{outline: selected ? "thin solid green" : ""}} onClick={openDropDown
        }>
            <td style={{textAlign: 'left'}}>
                <div className="text">{row.base_asset}/{row.quoted_asset}</div>
            </td>
            <td style={{textAlign: 'left'}}>
                <div className="text">{row.exchange_name}</div>
            </td>
            <td style={{textAlign: 'left'}}>
                <div className="text">{row.base_asset}</div>
            </td>
            <td style={{textAlign: 'left'}}>
                <div className="text">{row.quoted_asset}</div>
            </td>
            <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                {tickerData.price}
            </td>
            <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                {tickerData.chngpct}
            </td>
            <td style={{textAlign: 'left'}}>
                <div className="text">{tickerData.vol24}</div>
            </td>
            <td style={{textAlign: 'left'}}>
                <div className="text">{tickerData.v}</div>
            </td>
            <td style={{textAlign: 'left'}}>
                <div className="text">{row.key_id ? 'Connected' :
                    <a style={{color: "white", cursor: "pointer", textDecoration: "underline"}} onClick={() =>
                        history.push({
                            pathname: '/exchanges',
                            state: {exchange: row.exchange_id}
                        })
                    }>Connect Now</a>}</div>
            </td>
        </tr>
    );
}

const SymbolSearch = (props) => {
    const {
        selectedSpot, // Selected spot value
        setSelectedSpot, // Called when selected spot changed
        userAccounts, // User's saved exchange accounts
        exchanges, // Exchange information metadata
        history, // For navigation purposes
        location,// To access variables came with navigation
        setSelectedDropDown,
        selectedDropDown
    } = props;


    // Extract data from local storage (It should be available If we are coming from a coin)
    const navigatedBaseAsset = (location && location.state && location.state.from) || 'BTC';
    const navigatedQuotedAsset = location && location.state && location.state.to;
    const navigatedExchange = location && location.state && location.state.exchange;

    // Search fields
    const [baseAsset, setBaseAsset] = useState(navigatedBaseAsset || null);
    const [quotedAsset, setQuotedAsset] = useState(navigatedQuotedAsset || null);
    const [exchange, setExchange] = useState(Object.values(exchanges).some(el => el.id === navigatedExchange) || null);
    const [freeText, setFreeText] = useState("");

    // Possible filters
    const [baseAssets, setBaseAssets] = useState([]);
    const [quotedAssets, setQuotedAssets] = useState([]);
    const [exchangeSearches, setExchangeSearches] = useState([]);

    // Account connections
    const accounts = useRef(null);

    // State variables
    const [loading, setLoading] = useState(false);
    const [spots, setSpots] = useState([]);

    // Table related information variables
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [sort, setSort] = useState({i: 'cis_symbol', s: 'asc'});



    const handleChangeMarket = selectedOption => {
        setExchange(selectedOption);
        // setBaseAsset([]);
        // setQuotedAsset([]);
    };

    const handleChangeBase = selectedOption => {
        setBaseAsset(selectedOption);
    };

    const handleChangeQuote = selectedOption => {
        setQuotedAsset(selectedOption);
    };

    const handleChangeSearchText = event => {
        setFreeText(event.target.value);

    };


    // Real time listeners
    const disabledColumns = ["market_cap", "high", "low"];
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker(disabledColumns, DataMappers.spotTickerMapper);

    // Get accounts of the user
    const arrangeAccounts = () => {
        // Convert accounts to map of exchange symbol to key ID
        const accountMap = {};
        userAccounts.forEach(el => accountMap[el.exchange] = el.key_id);

        // Set reference value and return
        accounts.current = accountMap;
    }

    // Arrange spots so selected spot at top and exchange info is initiated
    const arrangeSpots = (inSpots, returnSpots) => {
        // Get user accounts
        const uaMap = accounts.current || {};
        // Fill missing spot search values
        inSpots = inSpots.map(el => {
            // Add exchange name and ID
            const exc = exchanges[el.source_code];
            if (exc) {
                el.exchange_name = exc.name;
                el.exchange_id = exc.id;

                // Add user account key ID if possible
                el.key_id = uaMap && uaMap[exc.id];
            } else { // In case exchange not defined (Not likely when we get it from API)
                el.exchange_name = el.source_code;
            }
            return el;
        });

        // Return spots rather than updating (This one is used by updates to current spot list)
        if (returnSpots)
            return inSpots;

        // Initiate real time updates
        initSocketConnection(inSpots.map(el => el.cis_symbol));

        setSpots(inSpots);
    }

    // Called when one of the search parameters are changed
    const searchSpots = () => {
        // Generate search params
        const params = {
            page_number: currentPage,
            order_by_field: sort.i,
            order_by_direction: sort.s,
            is_connectable: true
        };
        if (baseAsset && baseAsset.value)
            params.base_asset = baseAsset.value;
        if (quotedAsset && quotedAsset.value)
            params.quoted_asset = quotedAsset.value;
        if (exchange && exchange.value)
            params.source_code = exchange.value;
        if (freeText)
            params.search_string = freeText;

        // Perform search
        setLoading(true);
        Api.spotSearch(params,isMobile?10:20).then(async res => {
            res.data = res.data || [];
            res.data.forEach(el => el.ticker= el.ticker || {});
            // Generate readable tickers
            res.data.forEach(el => {
                el.org_ticker = el.ticker;
                el.ticker = DataMappers.spotTickerMapper(el.ticker);
            });

            // Puts selected spot at top if any
            arrangeSpots(res.data);

            // Set selected fields
            const ba = res.base_assets || [];
            const newBa = ba.map(el => { return {value:el,label:el}});
            newBa.push({value: "",label: "ALL"});
            setBaseAssets(newBa);
            const qa = res.quoted_assets || [];
            const newQa = qa.map(el => { return {value:el,label:el}});
            newQa.push({value: "",label: "ALL"});
            setQuotedAssets(newQa);
            const exc = res.exchanges || [];
            const newExc = exc.map(el => {
                return {value:exchanges[el].code,label:exchanges[el].name};
            });
            newExc.push({value: "",label: "ALL"});
            setExchangeSearches(newExc);

            setLoading(false);
            setTotalPage(res.totalPage);
        });
    }

    // Checks if spot is the selected spot
    const isSelected = (element) => {
        return selectedSpot && selectedSpot.cis_symbol === element.cis_symbol;
    }

    useEffect(() => {
        // Clear page selection before search
        setCurrentPage(1);
        // Perform search
        searchSpots();
    }, [baseAsset, quotedAsset, exchange])

    useEffect(() => {
        if (baseAsset !== 0 || quotedAsset !== 0 || exchange !== 0) {
            setBaseAsset(0);
            setQuotedAsset(0);
            setExchange(0);
        } else {
            // Clear page selection before search
            setCurrentPage(1);
            // Perform search
            searchSpots();
        }
    }, [freeText])

    useEffect(() => {
        // Search on parameter changes
        searchSpots();
    }, [currentPage, sort]);

    // Put selected spot at top
    useEffect(() => {
        setSpots(currentSpots => arrangeSpots(currentSpots, true));
    }, [selectedSpot]);

    useEffect(() => {
        setFreeText("");
    }, [selectedDropDown]);

    useEffect(() => {
        // Generate user account map
        arrangeAccounts();
        // Use arrange spots to update current spots
        setSpots(currentSpots => arrangeSpots(currentSpots, true));
    }, [userAccounts]);

    // Handles pager clicks
    const handlePageClick = d => {
        if (currentPage !== d.selected + 1) {
            setCurrentPage(d.selected + 1);
        }
    };


    // Get table row for given data
    const getTableRow = (el) =>
      <SymbolRow history={history} row={el} setSelectedSpot={setSelectedSpot} selected={isSelected(el)} setChannel={setChannel} disabledColumns={disabledColumns} setSelectedDropDown={setSelectedDropDown} />

    // Get spots data for table
    const getSpotsData = () => {
        if (spots.length === 0)
            return  <tr><td colSpan={7}><div style={{color: "white", margin: "1em", fontSize: "14px"}}>No pairs listed in CIS markets</div></td></tr>

        return loading ? <tr><td colSpan={9}><Loading loading={loading}/></td></tr> : spots.map(getTableRow);
    }

    // Update multi select field list
    const updateSelect = (field, uptField, newItem)  => {
        // Clear items
        if (newItem === undefined) {
            uptField([0]);
            return
        }

        // Add new item and update
        field = field.filter(el => el !== 0);
        field.push(newItem);
        uptField([...field]);
    }


    // Sort related variables
    const sortChange = index => sort && (sort.s === 'desc' ? {i: index, s: 'asc'} : {i: index, s: 'desc'});
    const sortArrow = index => sort && sort.i === index && <i style={{fontSize: 12}} className={`fa fa-angle-${sort.s === 'desc' ? 'up' : 'down'}`} />;

    return (
        isMobile?
            <div className="gray-container" style={{display: "flex", flexDirection: "column", height: "100%", paddingBottom: totalPage > 1 ? "0px" : "0px", marginTop:"0px"}}>
                <div className="filter-bars" style={{paddingLeft: "5px", margin: "0px", paddingBottom: "0px"}}>
                    <div className="filter-bar-inner1">
                        <div className="filter-bar filter-bar-mobile">
                            <div className="input-title">Search</div>
                            <div className="filter-bar-input1 create-flex">
                                <div className="gray-sm-input" style={{ padding: "0px 5px", fontSize: "13px"}}>
                                    <input
                                        type="text"
                                        style={{height: "23.5px"}}
                                        value={freeText}
                                        onChange={val => setFreeText(val.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7">
                    <table className="coins enumarable-table linked-table">
                        <thead>
                        <tr>
                            <th style={{paddingRight:"20px"}} >Exchange</th>
                            <th style={{padding: "7px", cursor: 'pointer'}} >Pair</th>
                            <th style={{padding: "7px", cursor: 'pointer'}} >Price </th>
                            <th style={{padding: "7px", cursor: 'pointer'}} >24h Change%</th>
                        </tr>
                        <tr>
                            <th>
                                <div className="filter-bar-input create-flex" >
                                    <div className="sm-select-input">
                                        <Select
                                            addAll
                                            selected={(exchange && exchange.id) || 0}
                                            optionValue={"id"}
                                            optionLabel={"name"}
                                            options={exchangeSearches || []}
                                            onChange={selected => setExchange(selected)}/>
                                    </div>
                                </div>
                            </th>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedSpot ? [getTableRow(selectedSpot), getSpotsData()]: getSpotsData()}
                        </tbody>
                    </table>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                    {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                </div>
                <div className="green-border-btn" style={{paddingBottom:"15px"}}>
                    <a>
                        To connect & trade, please login in desktop
                    </a>
                </div>
            </div>
            :
            selectedDropDown ?
            <div className="gray-container" style={{display: "flex", flexDirection: "column", width: "100%", paddingBottom: totalPage > 1 ? "0px" : "0px",borderBottom:"1px solid black",padding:"0px",position:"absolute",zIndex:"99"}}>
                <div className="filter-bars" style={{paddingLeft: "5px", margin: "0px", paddingBottom: "0px",border:"1px solid black"}}>
                    <div className="filter-bar-inner" style={{margin:"0px"}}>

                        <div className="trade-search" style={{width:"300px"}}>
                            <div className="filter-bar-input1 " style={{padding:"0px"}}>
                                <TextField id="outlined-basic" label="Search" variant="outlined"
                                           inputProps={freeText}
                                           onChange={handleChangeSearchText}
                                />
                            </div>
                        </div>
                        <div className="trade-search">
                            <div className="filter-bar-input1 " style={{padding:"0px",paddingRight:"50px",width:"210px"}}>
                                <Select
                                    value={exchange }
                                    onChange={handleChangeMarket}
                                    options={exchangeSearches || []}
                                    placeholder="Markets"
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className="trade-search">
                            <div className="filter-bar-input1 " style={{padding:"0px",width:"200px",paddingLeft:"50px"}}>
                                <Select
                                    value={baseAsset}
                                    onChange={handleChangeBase}
                                    options={baseAssets || []}
                                    placeholder="Base"
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className="trade-search">
                            <div className="filter-bar-input1 " style={{padding:"0px",width:"220px",paddingLeft:"80px"}}>
                                <Select
                                    value={quotedAsset}
                                    onChange={handleChangeQuote}
                                    options={quotedAssets || []}
                                    placeholder="Quote"
                                    isClearable={true}
                                />
                            </div>
                        </div>


                    </div>
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{height: "100%", minHeight: "40vh", maxHeight: "40vh", marginBottom: "30px", outline: 'none', paddingLeft: "1px", paddingRight: "2px",padding:"25px"}}>
                    <table className="coins enumarable-table news-table-responsive linked-table" style={{height: "100%", marginLeft: "0.5px"}}>
                        <thead>
                        <tr>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('cis_symbol'))}>Pair {sortArrow('cis_symbol')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('source_code'))}>Exchange {sortArrow('source_code')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('base_asset'))}>Base {sortArrow('base_asset')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('quoted_asset'))}>Quote {sortArrow('quoted_asset')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('lst'))}>Price {sortArrow('lst')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('chgpct'))}>24h Change% {sortArrow('chgpct')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('vto'))}>24h Vol To {sortArrow('vto')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('v'))}>24h Vol {sortArrow('v')}</th>
                            <th style={{cursor: 'inherit'}}>Account Status</th>
                        </tr>

                        </thead>
                        <tbody>
                        {getSpotsData()}
                        </tbody>
                    </table>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                    {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                </div>

            </div>:
            <div>

            </div>

    );
}

// Prepares input props from state and local storage
const prepareInputProps = state => {

    // Convert exchanges to map of symbol to exchange data
    const exchangeMap = {};
    state.meta.connectable_exchanges.forEach(el => exchangeMap[el.code] = el);

    return {
        exchanges: exchangeMap
    };
};
export default connect(prepareInputProps)(withRouter(SymbolSearch));
