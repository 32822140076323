import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import CoinRow from '../../components/CoinRow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import SetWatchListModal from '../Modals/SetWatchListModal';
import CISNavigator from "../../helpers/CISNavigator";

const IndicesTable = (props, context) => {
  const {t} = context;
  const {data, selectedExchange, history} = props;
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);


  return (
    <tbody>
      {data.map((row, i) => (
        <CoinRow
          rowClick={() => CISNavigator.navigateToCoinDetail(history, row, selectedExchange)}
          setAdd={setAdd}
          setShow={setShow}
          key={i}
          row={row}
          selectedExchange={selectedExchange}
        />
      ))}
      <SetWatchListModal modalShow={show} closeModal={() => setShow(false)} add={add} />
    </tbody>
  );
};

IndicesTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    meta: state.meta,
  };
};

export default connect(mapStateToProps)(withRouter(IndicesTable));
