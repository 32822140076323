// Here we have Service class > dont forget that in JS class is Function
import RealtimeUtils from "../helpers/RealtimeUtils";
import Api from "../api";
import {PineJS} from "./charting_library/charting_library.min";
import Links from "../constants/Links";

export class HttpService {
    constructor() {}

    getTSPath() {
        return Links.BASE_URL + '/timeseries/gettimeseries';
    }

    /**
     * Get possible options for sentiment values
     */
    getPossibleOptions() {
       return ['title', 'summary', 'content'];
    }

    /**
     * Auto generated overall news sentiment symbol options
     */
    generateOverallNewsOptions() {

    }

    /**
     * Auto generated coin specific news sentiment symbol options
     *
     * @param coin
     */
    generateCoinNewsOptions(coin) {

    }

    /**
     * Convert resolution to request param
     *
     * @param resolution
     * @return {string}
     */
    resolutionDetector(resolution) {
        if (resolution.endsWith('D')) {
            return '1d'
        } else if (resolution >= 60) {
            return (resolution / 60) + 'h';
        } else {
            return resolution + 'm';
        }
    }

    /**
     * Parse index response to TV format
     *
     * @param rawIndex index fetched from API
     * @param currency Currency selected
     * @returns TV formatted search result
     */
    parseIndexSearch(rawIndex, currency) {
        return {
            symbol: rawIndex['index_code'],
            full_name: rawIndex['index_code'] + '-' + currency,
            description: rawIndex['index_name'],
            exchange: '',
            ticker: RealtimeUtils.generateTicker(rawIndex['index_code'], currency, true),
            type: 'index'
        };
    }

    /**
     * Parse coin response to the TV format
     *
     * @param rawCoin coin fetched from the API
     * @param currency Currency selected
     * @returns TV formatted search result
     */
    parseCoinSearch(rawCoin, currency) {
        return {
            symbol: rawCoin['symbol'],
            full_name: rawCoin['symbol'] + '-' + currency,
            description: rawCoin['name'],
            exchange: '',
            ticker: RealtimeUtils.generateTicker(rawCoin['symbol'], currency),
            type: 'bitcoin'
        };
    }

    /**
     * Perform search on any options and return in TV format
     *
     * @param currency
     * @param searchString
     */
    search(currency, searchString) {
        return Api.quickSearch(searchString, { page_size: 20}).then(res => {
            const indexSearch = res.indices.map(el => this.parseIndexSearch(el, currency));
            const coinSearch = res.coins.map(el => this.parseCoinSearch(el, currency));
            return indexSearch.concat(coinSearch);
        });
    }

    /**
     * Perform search on indexes and return in TV format
     *
     * @param currency
     * @param searchString
     */
    searchIndex(currency, searchString) {
        return Api.quickSearchIndex(searchString, { page_size: 20}).then(indexList => {
            return indexList.map(el => this.parseIndexSearch(el, currency));
        });
    }

    /**
     * Perform search on coins and return in TV format
     * @param currency
     * @param searchString
     */
    searchCoin(currency, searchString) {
        return Api.quickSearchCoin(searchString, { page_size: 20 }).then(coinList => {
            return coinList.map(el => this.parseCoinSearch(el, currency));
        });
    }

    /**
     * Search symbol and return TV friendly list
     *
     * @param currency Currency to set (e.g. USDT for BTC/USDT)
     * @param symbolInput Search text
     * @param symbolType Type of symbol we search (all, crypto or index
     */
    searchSymbol(currency, symbolInput, symbolType) {
        if (symbolType === "crypto") {
            return this.searchCoin(currency, symbolInput);
        } else if (symbolType === "index") {
            return this.searchIndex(currency, symbolInput);
        } else {
            return this.search(currency, symbolInput);
        }
    }

    /**
     * Fetches and converts time series data from CIS API and converts it to TV time series.
     *
     * @param pair Pair to fetch
     * @param resolution Trading view resolution value
     * @param from Date to start from
     * @param to Date to end
     * @param isNews Indicates if we are fetching for the news
     * @param customFilter filter to apply for fetching news data
     */
    getTSData(pair, resolution, from, to, isNews, customFilter) {
        const params = {
            interval: resolution.endsWith('D') ? '1d' : resolution + 'm',
            limit: isNews ? 300 : 1440
        };
        if (pair.includes("_")) {
            params.cis_native_symbol = pair;
        } else { params.symbol = pair; }

        if (from) {
            params['start'] = from * 1000;
        }
        if (to) {
            params['end'] = to * 1000;
        }

        // Map to trading view model and return
        return Api.getTsData(this.generateQuery(customFilter, params)).then(data => {
            // Map our data model to trading view data model
            if (data['data'] && data['data'].length) {
                // Generate field index map (which array element belongs to which field)
                const indexMap = {};
                data['fields'].forEach((item, index) => {
                    indexMap[item] = index;
                });

                // Map to trading view model and return
                return data['data'].map(el => {
                    return {
                        time: el[indexMap['ts']],
                        low: el[indexMap['l']],
                        high: el[indexMap['h']],
                        open: el[indexMap['o']],
                        close: el[indexMap['c']],
                        volume: null
                    };
                }).filter(el => el.close).reverse();
            } else {
                return [];
            }
        });
    }

    /**
     * Returns news data for the given symbol (If not given returns all)
     *
     * @param symbol Symbol to look for
     * @param resolution Trading view resolution value
     * @param from Date to start from
     * @param to Date to end
     * @param customFilter Custom filter to apply for fetching news data
     */
    getNewsData(symbol, resolution, from, to, customFilter, userSentimentPref) {
        const isAvg = symbol === "SENT MEAN";
        const params = {
            interval: this.resolutionDetector(resolution),
            limit: 300
        };
        // params.interval = params.interval === '60m' ? '1h' : params.interval;

        //Copy custom filter and remove date indicating parameters (Trading view handling those)
        //Copy needed to preserve actual parameters intact
        const cpParams = new URLSearchParams(customFilter && customFilter.toString());
        cpParams.delete('min_publication_datetime');
        cpParams.delete('max_publication_datetime');

        if (from) {
            params['min_publication_datetime'] = from * 1000;
        }
        if (to) {
            params['max_publication_datetime'] = to * 1000;
        }
        if (symbol && !isAvg && symbol !== 'News') {
            params['tags'] = 'COIN_' + symbol;
        }


        // Map to trading view model and return
        return Api.getNewsTsData(this.generateQuery(cpParams, params)).then(data => {
            // Map our data model to trading view data model
            if (data['data'] && data['data'].length) {
                // Map to trading view model and return
                return data['data'].map(el => {
                    // Extract field values
                    const [time, titleSentiment, summarySentiment, contentSentiment, volume] = el;
                    let sentimentType;

                    if(userSentimentPref === "title_sentiment"){
                        sentimentType = titleSentiment;
                    }else if(userSentimentPref === "summary_sentiment"){
                        sentimentType = summarySentiment;
                    }
                    else if(userSentimentPref === "content_sentiment"){
                        sentimentType = contentSentiment;
                    }
                    const [tsAvg, tsNeg, tsNeu, tsPos] = sentimentType;

                    return {
                        time: time,
                        low: !isAvg ? -tsNeg : tsAvg,
                        high: !isAvg ? tsPos : tsAvg,
                        open: !isAvg ? volume : tsAvg,
                        close: tsAvg,
                        volume: volume
                    };
                }).reverse();
            } else {
                return [];
            }
        });
    }

    /**
     * Generate query string from base and given param map
     * @param base
     * @param paramMap
     */
    generateQuery(base, paramMap) {
        let query = base;
        if (typeof base === 'string') {
            const paramQuery = Object.entries(paramMap).map(entry => entry[0] + '=' + entry[1]).join('&');
            return base === '' ? paramQuery : base + '&' + paramQuery;
        } else if (typeof base === 'object' && base && base.append) {
            query = new URLSearchParams(query.toString());
            Object.entries(paramMap).forEach(entry => query.append(entry[0], entry[1]));
            return query;
        } else if (typeof base === 'object' && base) {
            query = Object.assign({}, query);
            Object.entries(paramMap).forEach(entry => query[entry[0]] = entry[1]);
            return query;
        }

        return paramMap;
    }

    /**
     * Loads latest price of the pair
     *
     * @param pair Pair to fetch
     */
    loadLatestPrice(pair) {
        const query = 'symbol=' + pair + '&interval=1d&limit=1';
        return fetch(this.getTSPath() + '?' + query).then(res => res.json()).then( data => {
            // Get the low value of the data
            if (data['data'] && data['data'].length) {
                // Generate field index map (which array element belongs to which field)
                const indexMap = {};
                data['fields'].forEach((item, index) => {
                    indexMap[item] = index;
                });
                return data['data'][0][indexMap['l']];
            } else {
                return [];
            }
        });
    }

}
