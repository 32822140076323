import React, {useEffect} from 'react';
import {Pie} from 'react-chartjs-2';


const backgroundColor5 = ['rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)'];

const backgroundColor3 = ['rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
];

const PieChart = props => {
    const {resp,type} = props;
    let data = {};
    let legend = {};



    let respKeys = Object.keys(resp);
    const respValues = respKeys.map(el => resp[el]);
    const newMapDatas = respValues.map(el => el[0]);


    const newRespKeys = respKeys.map(el =>{
        if(el === "Unknown"){
            el = "Unassigned";
        }
        if(el === "Asset Token (A)"){
            el = "Security Token (S)"
        }
        if(el === "No Category"){
            el = "No Market Cap";
        }
        return el;
    });

    if(type === "Asset"){
        data = {
            labels: newRespKeys,
            datasets: [
                {
                    label: '# of Votes',
                    data: newMapDatas,
                    backgroundColor: backgroundColor3,
                    borderColor: backgroundColor3,
                    borderWidth: 1,
                },
            ]
        }
    }else if(type === "MarketCap"){
        data = {
            labels: newRespKeys,
            datasets: [
                {
                    label: '# of Votes',
                    data: newMapDatas,
                    backgroundColor: backgroundColor5,
                    borderColor: backgroundColor5,
                    borderWidth: 1,
                },
            ],
        }
    }

    legend={
        display: true,
        position: 'right',
        fullWidth: true,
        reverse: false,
        labels: {
            boxWidth: 20,
            fontColor: "white",
            fontSize:20
        }
    }



    useEffect(()=>{
    },[])

    return (
        <div style={{padding:"0px 50px 10px"}}>
            <Pie data={data} legend={legend}/>
        </div>

    );
};


export default PieChart;


