import React, {useEffect} from 'react';
import {Route, Switch, withRouter, Redirect, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import Dashboard from '../../pages/Dashboard';
import Privacy from '../../pages/Privacy';
import Terms from '../../pages/Terms';
import {getAllCoins, getConnectableExchanges, getExchanges, metaData, newsTags} from '../../actions/metaAction';
import {FullLoading} from '../../components/FullLoading/FullLoading';
import {setTranslations} from 'redux-i18n';
import {translations} from '../../translations';
import {setLoading} from '../../actions/generalAction';
import GoogleAnalytics from "../../components/Analytics/GoogleAnalytics";
import {useAuth0} from "../../helpers/Auth0";
import EmailVerification from "../EmailVerification/EmailVerification";
import NotAuthorizedPage from "../NotAuthorizedPage";


const AppRoutes = props => {
  const {
    setTranslations,
    metaData,
    newsTags,
    getExchanges,
    getConnectableExchanges,
    getAllCoins,
    setFullLoading,
  } = props;
  const { pathname } = useLocation();

  const { isAuthenticated, user, loading, logout, loginWithRedirect} = useAuth0();

  const navigateDashboard = () => {
    props.history.push('/');
  };

  const navigateLogin = () => {
    loginWithRedirect();
  };

  // getMessages();

  useEffect(() => {
    setTranslations(translations);
    if (isAuthenticated !== undefined) {

      if (isAuthenticated) {
        metaData();
        newsTags();
        getAllCoins();
        getExchanges();
        getConnectableExchanges();
        setFullLoading(false);
        if (['/signup', '/login', '/forgot-password'].includes(props.location.pathname)) navigateDashboard();
      } else {
        setFullLoading(false);
        // logout();
        if (!['/signup', '/privacypolicy', '/terms-and-conditions', '/verification-required', '/verification-required/'].includes(props.location.pathname)) navigateLogin();
      }
    }
  }, [isAuthenticated]);

  return (
      isAuthenticated === "denied" ?

    <NotAuthorizedPage/>
    :
    <React.Fragment>
      <FullLoading loading={props.state.general.fullLoading || loading} />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        {/*<Route path="/signup" component={isAuthenticated === false && Signup} />*/}
        {/*<Route path="/login" component={isAuthenticated === false && Login} />*/}
        {/*<Route path="/forgot-password" component={isAuthenticated === false && ForgotPassword} />*/}
        <Route path="/verification-required" component={EmailVerification} />
        <Route path="/privacypolicy" component={Privacy} />
        <Route path="/terms-and-conditions" component={Terms} />
        <Route path="/" component={user && Dashboard} />
      </Switch>
      <GoogleAnalytics/>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    state,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    metaData: () => dispatch(metaData()),
    newsTags: () => dispatch(newsTags()),
    getAllCoins: () => dispatch(getAllCoins()),
    getExchanges: () => dispatch(getExchanges()),
    getConnectableExchanges: () => dispatch(getConnectableExchanges()),
    setTranslations: data => dispatch(setTranslations(data)),
    setFullLoading: data => dispatch(setLoading(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppRoutes));
