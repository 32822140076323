import * as React from 'react';
import './index.css';
import { HttpService } from '../../httpService';
import {widget} from '../../charting_library/charting_library.min';
import SocketHelpersNew from "../../../helpers/SocketHelpersNew";
import RealtimeUtils from "../../../helpers/RealtimeUtils";
import SentimentIndicator from "../indicators/SentimentIndicator";
import Utils from "../../../helpers/Utils";

const isMobile = Utils.checkMobile();


export class TVChartContainer extends React.PureComponent {
	static defaultProps = {
		symbol: '',
		exchange: 'USDT',
		type: 'CISCALC',
		customFilter: null,
		interval: '1D',
		containerId: 'tv_chart_container',
		datafeedUrl: 'https://demo_feed.tradingview.com',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		pricescale: null,
		latest_price: null
	};

	// Reference to widget object
	tvWidget = null;
    // Service for fetching data
	apiService = new HttpService();
	// Data feed reference
	_datafeed;
	// Default timezone of the chart
	timezone = 'Etc/UTC';
	// Active ticker
	ticker = this.getTicker();
	// Cache refreshers
	cacheRefreshers = {};

	// We are saying our feed supports these aggregations
	intradayMultipliers = ['1', '5', '15', '30', '60'];

	// Supported resolutions (No following abbr means minutes)
	config = {
		supported_resolutions: ['1', '5', '15', '30', '60', '240', '360', '1D'],
		symbols_types: [
			{name: "All", value: "all"},
			{name: "Index", value: "index"},
			{name: "Crypto", value: "crypto"}
		]
	};

	// Keep reference to real time channels
	realTimeChannel = {};

	// Keep reference to last bar when a symbol is loaded
	lastBars = {};

	/**
	 * Update trading view with real time tickers
	 *
	 * @param message Message from socket
	 */
	handleRealtimeUpdate(message) {
		try {
			if (!message.startsWith("OK|")) {

				// Parse to json and extract real time data
				const jsonResp = JSON.parse(message);
				const channelData = this.realTimeChannel[jsonResp.ns];

				//Check sequence number ordered

				// Check if we interested in this symbol
				if (channelData && jsonResp.d === "TICKER") {
					//Message and sequence number control
					if((jsonResp.mt !== 'snapshot' && !channelData.seqnum) || (jsonResp.mt === 'update' && channelData.seqnum + 1 !== jsonResp.seqnum)){
						SocketHelpersNew.subscribe([channelData.symbol]);
					}
					else {
						// Extract resolution wrt minutes
						let resolution = channelData.resolution;
						if (resolution.includes("D")) {
							resolution = 1440;
						}

						// How many seconds we need per interval
						const coeff = resolution * 60;
						// Find current value of the bar that should reside as last
						const rounded = (Math.floor((jsonResp.p.lst_ts / 1000) / coeff) * coeff);
						// Our last bar second
						const lastBarSec = channelData.lastBar.time / 1000;

						// Make changes on last bar
						let newData = channelData.lastBar;

						if(jsonResp.p.lst) {
							// Means that we moved to new candle
							if (rounded > lastBarSec) {
								newData = {
									time: rounded * 1000,
									low: channelData.lastBar.close, // Last bar close is used for those since it is a new candle
									high: channelData.lastBar.close,
									open: channelData.lastBar.close,
									close: jsonResp.p.lst,
									volume: null
								};
							} else { // Still current candle, update it

								if (jsonResp.p.lst < newData.low) { // Low is changed for the candle
									newData.low = jsonResp.p.lst;
								} else if (jsonResp.p.lst > newData.high) { // High is changed for the candle
									newData.high = jsonResp.p.lst
								}

								// Update current price
								newData.close = jsonResp.p.lst;
							}
						}
						// Save last bar we generate for future messages
						channelData.lastBar = newData;

						//Save new seqnum
						channelData.seqnum = jsonResp.seqnum;

						// Update candle at the graph
						channelData.listener(newData);
					}
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

	componentDidMount() {
		this.dataFeed = {
			onReady: cb => {
				setTimeout(() => cb(this.config), 0);
			},

			searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
				this.apiService.searchSymbol(this.props.exchange, userInput, symbolType).then(res => {
					onResultReadyCallback(res);
				});
			},
			resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
				// If symbol and exchange given use it as description
				const desc = (this.props.symbol && this.props.exchange) ? this.props.symbol + "/" + this.props.exchange : symbolName
				// Default symbol configurations
				const symbolStub = {
					name: symbolName, // Name of the symbol
					ticker: symbolName, // Unique identifier for the symbol to fetch data
					description: desc, // Chart Legend
					type: 'crypto', // Type of the symbol (Don't know what is useful for maybe grouping symbols)
					session: '24x7', // Special session string for crypto
					exchange: '', // Exchange of the symbol (e.g Bitfinex), shown in legend
					listed_exchange: '', // Listed exchange same logic above required
					timezone: this.timezone, // Timezone of the chart
					format: 'price', // Formats decimal or fractional numbers based on params (minmov, pricescale)
					minmov: 1, // Did not understand what this is...
					pricescale: 1, // Number of decimal places 10's power (e.g 1.01 => 100, 1.005 => 1000)
					has_intraday: true, // E.g 1 minute, 2 minute resolutions...
					supported_resolutions:  ['1D', '60', '240', '360'], // Symbol specific resolutions
					intraday_multipliers: this.intradayMultipliers, // Aggregate multipliers that our data feed supports
					has_seconds: false, // If we are supporting second resolutions
					seconds_multipliers: [], // Second aggregate multipliers
					has_daily: true,  // Indicates if data feed supports daily aggregates (only 1 daily requested by TV)
					has_weekly_and_monthly: false, // Indicates if data feed supports these aggregates (only 1 weekly/yearly requested by TV)
					has_empty_bars: true, // Fill chart with empty bars for empty data
					has_no_volume: true, // Indicates if data has volume values
					volume_precision: 0, // Decimal places for volume (2 values after comma)
					full_name: symbolName // Not documented but required
				};

				// Check if we are checking news sentiment related symbol
				if (!this.isNewsSymbol(symbolName)) {
					// Required for CIS Native Symbols
					if (symbolName.includes("-") && symbolName.includes(".")) {
						const splitData = symbolName.split(/[-.]/);
						symbolStub.description = `${splitData[0]}/${splitData[1]}`;
					}


					if (this.props.isNews !== true) symbolStub.supported_resolutions = this.config.supported_resolutions;
					if (!this.props.pricescale && !this.props.latest_price) { // If not given get it from timeseries data
						this.apiService.loadLatestPrice(symbolName).then(price => {
							symbolStub.pricescale = this.decimalDetector(price);
							onSymbolResolvedCallback(symbolStub);
						});
					} else if (this.props.latest_price) {
						symbolStub.pricescale = this.decimalDetector(this.props.latest_price);
						onSymbolResolvedCallback(symbolStub);
					} else {
						symbolStub.pricescale = this.props.pricescale;
						onSymbolResolvedCallback(symbolStub);
					}
				} else {
					setTimeout(() => {
						symbolStub.pricescale = 100;
						onSymbolResolvedCallback(symbolStub);
					}, 0);
				}
			},
			getBars: (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) => {
				// Do not feed from/to if this is the first request
				const paramFrom = firstDataRequest ? null : from;
				const paramTo = firstDataRequest ? null : to;

				if (!this.isNewsSymbol(symbolInfo.ticker)) {
					this.apiService.getTSData(symbolInfo.name, resolution, paramFrom, paramTo, this.props.isNews, this.props.customFilter).then(bars => {
						// Store the last bar for real time updates
						if (firstDataRequest) {
							this.lastBars[symbolInfo.ticker] = bars[bars.length-1];
						}

						// If there are bars to show send to history callback and let TV handle the draw
						if (bars && bars.length) {
							onHistoryCallback(bars, {noData: false});
						} else {
							onHistoryCallback([], {noData: true});
						}
					});
				} else { // News sentiment related data
					this.apiService.getNewsData(symbolInfo.name, resolution, paramFrom, paramTo, this.props.customFilter,this.props.sentiment_type).then(bars => {

						// If there are bars to show send to history callback and let TV handle the draw
						if (bars && bars.length) {
							onHistoryCallback(bars, {noData: false});
						} else {
							onHistoryCallback([], {noData: true});
						}

						if (!this.props.symbol && bars.length > 0) {
							this.arrangeVisibleDate(bars[bars.length-1]["time"])
						}
					});
				}
			},
			subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
				this.cacheRefreshers[symbolInfo.ticker] = onResetCacheNeededCallback;
				if (!this.isNewsSymbol(symbolInfo.ticker)) {
					// Add symbol to real time channel
					const symbolChannel = {
						symbol: symbolInfo.ticker,
						resolution: resolution,
						lastBar: this.lastBars[symbolInfo.ticker],
						subscribeUID: subscribeUID,
						listener: onRealtimeCallback,
						realTimeHandler: this.handleRealtimeUpdate.bind(this)
					};
					// Keep reference to the symbol channel
					this.realTimeChannel[symbolInfo.ticker] = symbolChannel;
					// Subscribe to real time updates
					SocketHelpersNew.coinsRaw([symbolInfo.ticker + '~TICKER'], symbolChannel.realTimeHandler);
				}
			},
			unsubscribeBars: subscriberUID => {
				// Extract subscription
				const subscription = Object.values(this.realTimeChannel).find(el => el.subscribeUID === subscriberUID);

				// If defined unsubscribe
				if (subscription) {
					delete this.realTimeChannel[subscription.symbol];
					SocketHelpersNew.close([subscription.symbol + '~TICKER'], subscription.realTimeHandler);
				}
			},
			calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
				// Requesting daily resolution, fetch 12 months worth of daily data
				if (resolution.endsWith('D')) {
					return {
						resolutionBack: 'D',
						intervalBack: this.props.isNews ? 300 : 1440 // To keep same limit value with intraday resolutions
					};
				}

				// If resolution only contains digits scale wrt base 1 day worth of minutes
				if (/^\d+$/.test(resolution)) {
					const minInt = parseInt(resolution);
					return {
						resolutionBack: 'D',
						intervalBack: 1 * minInt // Base is 1 day for 1 minute data
					};
				}
				return undefined;
			}
		};

		const widgetOptions = {
			symbol: this.ticker,
			// BEWARE: no trailing slash is expected in feed URL
			interval: this.props.interval, // Default interval of the chart (1D)
			container_id: this.props.containerId, // Id of container DOM element
			datafeed: this.dataFeed, // Datafeed that will be used to provide data (has special interface check docs)
			library_path: this.props.libraryPath, // Path to the TV library
			fullscreen: this.props.fullscreen, // Enable full screen mode
			autosize: this.props.autosize, // Let chart to autosize itself wrt changes
			locale: 'en', // Chart locale
			disabled_features: isMobile ? ['border_around_the_chart', 'header_symbol_search',
				'symbol_search_hot_key', 'header_saveload', 'header_undo_redo','uppercase_instrument_names', 'left_toolbar'] : ['border_around_the_chart', 'header_symbol_search',
				'symbol_search_hot_key', 'header_saveload', 'header_undo_redo','uppercase_instrument_names'], // Features that are disabled check docs
			theme: 'Dark', // Default theme of the chart
			custom_css_url: 'https://cisfunctionsstorage.blob.core.windows.net/tv-css/custom.css', // Design css url
			overrides: { // Chart colors
				'editorFontsList': '["Roboto"]',
				'paneProperties.background': '#292E33',
				"mainSeriesProperties.style": this.props.isNews ? 2 : 1,
				'paneProperties.vertGridProperties.color': '#41464b',
				'paneProperties.horzGridProperties.color': '#41464b',
				'paneProperties.crossHairProperties.color': '#989898',
				'paneProperties.crossHairProperties.width': 1,
			},
			loading_screen: { backgroundColor: '#292E33' }, // Loading screen color
			charts_storage_url: this.props.chartsStorageUrl, // Handle user saved charts
			charts_storage_api_version: this.props.chartsStorageApiVersion, // Handle user saved charts
			client_id: this.props.clientId, // Handle user saved charts
			user_id: this.props.userId, // Handle user saved charts
			studies_overrides: {
				"Sentiment.plot.color": "#FFFFFF",
			},
			time_frames: [ // Supported timeframes (These are default)
				{ text: "1y", resolution: "D", description: "1 Year" },
				{ text: "3m", resolution: "60", description: "3 Months" },
				{ text: "1m", resolution: "30", description: "1 Month" },
				{ text: "5d", resolution: "5", description: "5 Day" },
				{ text: "1d", resolution: "1", description: "1 Day" }
			],
			custom_indicators_getter: function(PineJS) {
				return Promise.resolve([
					SentimentIndicator(PineJS)
				]);
			},
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;
		const indSymbol = this.props.symbol ? this.props.symbol : 'News';
		tvWidget.onChartReady(() => {
			if (this.props.isNews) { // Show news volume plot
				this.tvWidget.chart().createStudy("Sentiment", true, true, ['Pos', indSymbol], {"plot.color" : "#00FF00"}, { priceScale: "as-series" }).then(positive => {
					this.tvWidget.chart().createStudy("Sentiment", true, true, ['Neg', indSymbol], {"plot.color" : "#FF0000"}, { priceScale: "as-series" }).then(negative => {
						// Put series to different margins
						this.tvWidget.chart().getSeries().detachNoScale();
						this.tvWidget.chart().getSeries().bringToFront();

						// Arrange visible date
						this.arrangeVisibleDate();
					});
				});
			}

			// Set chart type
			const type = this.selectType();
			if (type)
				this.tvWidget.chart().setChartType(type);
		});
	}

	componentDidUpdate(prevProps) {
		// Generate new ticker with changed prop
		const newTicker = this.getTicker();
		const newParams = this.props.customFilter && !prevProps.customFilter;
		const paramCheck = this.props.customFilter && prevProps.customFilter && this.props.customFilter.toString() !== prevProps.customFilter.toString();
		// Update only if ticker is changed
		if(this.ticker !== newTicker && this.tvWidget && this.tvWidget._ready) {
			try {
				// Update ticker and studies (Compare, Overlay)
				this.ticker = newTicker;
				setTimeout(() => {
					if (this.tvWidget) {
						this.tvWidget.setSymbol(this.ticker, this.tvWidget.chart().resolution(), null);
						this.updateStudies();
					}
				}, 200);
			} catch(error) {
				console.error(error);
			}
		} else if ((newParams || paramCheck) && this.tvWidget && this.tvWidget._ready) {
			try {
				// Reset all data on param change
				Object.values(this.cacheRefreshers).forEach(el => el());
				this.tvWidget.chart().resetData();
			} catch(error) {
				console.error(error);
			}
		}
	}

	/**
	 * Arrange visible date for the chart wrt news and filters
	 */
	arrangeVisibleDate(in_today) {
		// Compute one day
		let today = in_today ? new Date(in_today) : new Date();
		let lastMonth = new Date(today.getTime());
		lastMonth.setDate(today.getDate() - 30);

		if (this.props.customFilter && this.props.customFilter.has) {
			if (this.props.customFilter.has('min_publication_datetime')) {
				lastMonth = new Date(parseFloat(this.props.customFilter.get('min_publication_datetime')));
				today = new Date(lastMonth.getTime());
				today.setDate(today.getDate() + 30);
			} else if (this.props.customFilter.has('max_publication_datetime')) {
				today = new Date(parseFloat(this.props.customFilter.get('max_publication_datetime')));
				lastMonth = new Date(today.getTime());
				lastMonth.setDate(today.getDate() - 30);
			}
		}

		// Check if visible range is changed
		const visibleRange = {from: Math.round(lastMonth.getTime() / 1000), to: Math.round(today.getTime() / 1000)};
		const oldVisibleRange = this.tvWidget && this.tvWidget.chart().getVisibleRange();
		if (this.tvWidget && (oldVisibleRange.from !== visibleRange.from || oldVisibleRange.to !== visibleRange.to)) {
			// Set visible range to 1 month
			this.tvWidget && this.tvWidget.chart().setVisibleRange(visibleRange, {applyDefaultRightMargin: false, percentRightMargin: false}).then(res => {});
		}
	}

	/**
	 * Called when main style ticker is changed to trigger updates on studies
	 */
	updateStudies() {
		// Get all studies and iterate
		const studies = this.tvWidget.chart().getAllStudies();
		studies.forEach(s => {
			// Get study with id and iterate it's inputs
			const study = this.tvWidget.chart().getStudyById(s.id);
			study.getInputValues().forEach(el => {
				if (el.id === "symbol" && !this.isNewsSymbol(el.value)) { // Update symbol specific stuides
					// Extract ticker related variables
					const [symbol, oldExchange, type]= el.value.split(/[-.]/);
					// Set new ticker on study
					study.setInputValues([{id: "symbol", value: RealtimeUtils.generateTicker(symbol, this.props.exchange, type === "CISIDX")}]);
				}
			});
		});
	}

	/**
	 * Check if symbol is a news sentiment related symbol
	 *
	 * @param symbol Symbol to check (ticker)
	 */
	isNewsSymbol(symbol) {
		return !symbol.includes(".") && !symbol.includes("_");
	}

	/**
	 * Return active symbol ticker
	 */
	getTicker() {
		return this.props.ticker ? this.props.ticker : this.props.symbol + '-' +  this.props.exchange + '.' + this.props.type;
	}

	/**
	 * Used to find how many decimal points will be shown for the symbol.
	 *
	 * @param num Number to analyze
	 * @param multiplier Recursive multiplier
	 * @returns {number|*}
	 */
	decimalDetector(num, multiplier = 1) {
		if (num > 1 || num === 0) {
			return 100 * multiplier;
		} else {
			return this.decimalDetector(num * multiplier, multiplier * 10);
		}
	}

	/**
	 * Select chart type
	 */
	selectType() {
		if (this.props.chart_type) {
			return this.props.chart_type;
		}

		if (this.props.type === 'CISIDX') {
			return 2;
		}

		return null;
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<div
				id={ this.props.containerId }
				className={ 'TVChartContainer' }
			/>
		);
	}
}
