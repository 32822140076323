import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import Breadcrumps from '../../constants/Breadcrumps';
import Api from '../../api';
import moment from 'moment';
import Loading from '../../components/Loading';
import NewsFilter from './NewsFilter';
import SummaryModal from './SummaryModal';
import DataMappers from "../../helpers/DataMappers";
import Select from "../../components/Forms/Select";
import Paginate from "../../components/Paginate";
import TradingView from "../../tradingview";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";
import Utils from "../../helpers/Utils";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";
import {useAuth0} from "../../helpers/Auth0";

const isMobile = Utils.checkMobile();

// Query string for initializing URLSearchParams
const filter = "page_number=1&page_size=20&language_code=en";

// Page size selections
const pages = [
  {value: 10, label: '10'},
  {value: 20, label: '20'},
  {value: 50, label: '50'}
];

const News = (props) => {
  const {} = props;
  const {user} = useAuth0();
  const [watchListShow,setWatchListShow] = useState(user.preferences.show_watchlist);
  const [data, setData] = useState({items: {}, loading: true});
  const [show, setShow] = useState(false);
  const [item, setItem] = useState({});
  const [filters, setFilters] = useState({});
  const [filterValue, setFilterValue] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  // Table related information variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [customFilter, setCustomFilter] = useState(new URLSearchParams());

  // Handles pager clicks
  const handlePageClick = d => {
    if (currentPage !== d.selected + 1) {
      setCurrentPage(d.selected + 1);
    }
  };
  useEffect(()=>{
    if(show){
      document.querySelector("body").style.overflow = 'hidden';
    }else{
      document.querySelector("body").style.overflow = 'auto';
    }

  },[show])

  useEffect(() => {
    // Fetch news on state updates
    fetchNews(filters);

//     const mq = window.matchMedia( "(min-width: 600px)" );
//
// if (mq.matches) {
//
// } else {
// var content = document.getElementById('element-should-first');
// var parent = content.parentNode;
// parent.insertBefore(content, parent.firstChild);
// }


  }, [filters, currentPage, pageSize]);

  const changeFilter = () => {
    setShowFilter(true);
  }

  // Check if object is non empty
  const nonEmpty = (field) => field && Object.keys(field).length > 0;

  // Fetch news with the filter state
  const fetchNews = () => {
    // Map filters to Url Search Parameters
    const sector = (nonEmpty(filters.selectSector) && filters.selectSector.news_tag) || null;
    const coin = (nonEmpty(filters.selectCoin) && filters.selectCoin.symbol && 'COIN_' + filters.selectCoin.symbol) || null;
    const source = (nonEmpty(filters.selectSource) && filters.selectSource.id) || null;
    const assetType = (nonEmpty(filters.selectAsset) && filters.selectAsset.news_tag) || null;
    const startDate = (filters.startDate && moment.utc(filters.startDate).unix() * 1000) || null;
    const endDate = (filters.endDate && moment.utc(filters.endDate).unix() * 1000) || null;
    const exchange = (nonEmpty(filters.selectExchange) && filters.selectExchange.id && filters.selectExchange.tag_type + "_" + filters.selectExchange.tag);
    const category = (nonEmpty(filters.selectCategory) && filters.selectCategory.id && filters.selectCategory.tag_type + "_"  + filters.selectCategory.tag);
    const nation = (nonEmpty(filters.selectNation) && filters.selectNation.id && filters.selectNation.tag_type + "_" + filters.selectNation.tag);
    const f = new URLSearchParams(filter); // UrlSearchParams used for AND ing tag filters
    let tags = [];
    if (filters.inputKeyword) f.set("keywords_search", filters.inputKeyword);
    if (startDate) f.set("min_publication_datetime", startDate);
    if (endDate) f.set("max_publication_datetime", endDate);
    if (source) f.set("news_sources", source);
    if (sector) tags = [...tags, sector];
    if (assetType) tags = [...tags, assetType];
    if (coin) tags = [...tags, coin.replace(" ", "_")];
    if (exchange) tags = [...tags, exchange.replace(" ", "_")];
    if (category) tags = [...tags, category.replace(" ", "_")];
    if (nation) tags = [...tags, nation.replace(" ", "_")];
    if (tags && tags.length > 0) tags.forEach(el => f.append("tags", el)); else f.delete("tags");
    if (filters.minSentiment === 0 || filters.minSentiment) f.set("min_sentiment", (filters.minSentiment / 100).toString());
    if (filters.maxSentiment === 0 || filters.maxSentiment) f.set("max_sentiment", (filters.maxSentiment / 100).toString());

    // Set paging parameters
    f.set("page_number", currentPage + "");
    f.set("page_size", pageSize + "");

    setData({...data, loading: true});

    // Arrange filter for TV Chart
    const tvFilter = new URLSearchParams(Array.from(f));
    tvFilter.delete("page_size");
    tvFilter.delete("page_number");
    tvFilter.delete("language_code");
    setCustomFilter(tvFilter);
    Api.getPromise('/News/NewsSearch', f).then(res => {
      // Extract paging parameters
      const pageSize = f.has("page_size") ? f.get("page_size") : 20;
      const pageCount = Math.ceil(res.total_count / pageSize) || 0;

      // Map data for visualisation
      res = res.data.map(el => {
        el.source = el.source.split("_").map(word =>  word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
        return el;
      });

      // Update states
      setTotalPage(pageCount);
      setData({items: res, loading: false});
    });
  };

  // Sentiment bubble generator
  const sentimentStyle = (value) => {
    return {
      borderRadius: "50%",
      width: "3em",
      height: "3em",
      fontSize: "12px",
      lineHeight: "3em",
      textAlign: "center",
      margin: "0.5vh 0 0.5vh 0",
      color: "white",
      fontWeight: "bold",
      background: DataMappers.mapSentimentColor(value)
    }
  };

  return (
      isMobile ?


          <div>
            <MobileNavBar/>
            <div>
              {watchListShow? <Watchlist />:<></>}
            </div>
            <div className="mobile-Div">
              <div className="row row-d" style={{paddingTop:"10px"}}>
                <div className="col-xs-10 col-filter-top-l">
                  <p style={{margin:"5px 0px"}}>Advanced Search</p>
                </div>
                <div className="col-xs-2 col-filter-top-r" onClick={changeFilter}>
                  <img style={{margin:"5px 0px"}} src="/img/search.svg" alt="" height="30px"/>
                </div>
              </div>
              <NewsFilter show={showFilter} cancel={() => setShowFilter(false)} search={setFilters}/>
              <div className="news-analysis-part">
                <div className="nap-flex">

                  <div className="nap-flex-left">
                    <div className="gray-container"
                         style={{display: "flex", flexDirection: "column", marginTop: "2em"}}>
                      <div className="table-head" style={{marginBottom: "2vh"}}>
                        <div className="table-left">
                          <div className="table-header">NEWS</div>
                        </div>
                        <div className="table-right">
                          <div className="table-select-input">
                            <div className="sm-select-input small-select">
                              {data.items.length &&
                              <Select selected={pageSize} options={pages} onChange={selected => {
                                setPageSize(selected.value);
                                setCurrentPage(1);
                              }}/>}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Loading loading={data.loading}>
                        <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1}
                             style={{overflow: 'hidden', outline: 'none'}}>
                          {data.items.length &&
                          <table className="coins enumarable-table news-table-responsive linked-table">
                            <thead>
                            <tr>
                              <th style={{width: '130px', textAlign: 'left', padding: "5px"}}>Date</th>
                              <th style={{width: '100px', textAlign: 'left', padding: "5px"}}>Source</th>
                              <th style={{textAlign: 'left', padding: "5px"}}>Coins</th>
                              <th style={{textAlign: 'left', padding: "5px"}}>Title</th>
                              <th style={{textAlign: 'left', padding: "5px"}}>Categories</th>
                              <th style={{textAlign: 'left', padding: "5px"}}>Sentiment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.items.map((d, i) => {

                              const date = moment.utc(d.publication_datetime).local();
                              const sentimentPref = user.preferences.sentiment_field;
                              let sentimentPrefValue = 0;

                              if(sentimentPref === "title_sentiment" && d.sentiment){
                                sentimentPrefValue = d.sentiment.title;
                              }else if(sentimentPref === "summary_sentiment" && d.sentiment){
                                sentimentPrefValue = d.sentiment.summary;
                              }else if(sentimentPref === "content_sentiment" && d.sentiment){
                                sentimentPrefValue = d.sentiment.content;
                              }
                              const perc = (d.sentiment && parseInt(sentimentPrefValue * 100, 10)) || 0;
                              return (
                                  <tr onClick={() => {
                                    setShow(true);
                                    setItem(d);
                                  }} key={i}>
                                    <td style={{textAlign: 'left'}}>
                                      <div className="date">{date.format('DD MMM YYYY')}</div>
                                      <div className="hour">{date.format('HH:mm:ss')}</div>
                                    </td>
                                    <td style={{textAlign: 'left'}}>
                                      <a>{d.source}</a>
                                    </td>
                                    <td style={{textAlign: 'left'}}>
                                      {d.tags
                                          .filter(t => t.includes('COIN_'))
                                          .map(t => t.replace('COIN_', ''))
                                          .join(', ')}
                                    </td>
                                    <td style={{textAlign: 'left'}}>
                                      <div className="text">{d.title}</div>
                                    </td>
                                    <td style={{textAlign: 'left'}}>
                                      {d.tags
                                          .filter(t => t.includes('OTHER_'))
                                          .map(t => t.replace('OTHER_', ''))
                                          .join(', ')}
                                    </td>
                                    <td style={{
                                      textAlign: 'left',
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center"
                                    }}>
                                      <div style={sentimentStyle(sentimentPrefValue)}>
                                        {perc}%
                                      </div>
                                    </td>
                                  </tr>
                              );
                            })}
                            </tbody>
                          </table>}
                          {!data.items.length &&
                          <p style={{color: "white"}}>No news found for the given filters...</p>}
                        </div>
                      </Loading>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: totalPage > 1 ? '0' : '2em'
                      }}>
                        {totalPage > 1 &&
                        <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1}/>}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <SummaryModal show={show} item={item} userPref={user.preferences.sentiment_field} cancel={() => setShow(false)}/>
            </div>
            <div style={{height:"80px"}}>

              <p></p>
            </div>
          </div>
          :
          <section className="news padding-sm-container">
            <div className="watchlist-all-page">
              {watchListShow? <Watchlist />:<></>}
            </div>
            <Breadcrump links={Breadcrumps.news} />
            <div id="filter-area" onClick={changeFilter} style={{display:'none'}}>
            <p>Filter <i className="down-arrow-news"/></p>
            </div>
            <div className="news-analysis-part">
              <div className="nap-flex">
                <div style={{width: '80%'}} className="nap-flex-left">
                  <div className="chart-area" style={{height: "50vh", marginTop: "0"}}>
                    <TradingView ticker={((nonEmpty(filters.selectCoin) && filters.selectCoin.symbol) || "BTC") + "-USDT.CISCALC"} customFilter={customFilter} isNews={true}/>
                  </div>
                  <div className="gray-container" style={{display: "flex", flexDirection: "column", marginTop: "2em"}}>
                    <div className="table-head" style={{marginBottom: "2vh"}}>
                      <div className="table-left">
                        <div className="table-header">NEWS</div>
                      </div>
                      <div className="table-right">
                        <div className="table-select-input">
                          <div className="sm-select-input small-select">
                            {data.items.length && <Select selected={pageSize} options={pages} onChange={selected => {setPageSize(selected.value); setCurrentPage(1);}} />}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Loading loading={data.loading}>
                      <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{overflow: 'hidden', outline: 'none'}}>
                        {data.items.length && <table className="coins enumarable-table news-table-responsive linked-table">
                          <thead>
                            <tr>
                              <th style={{width: '130px',textAlign: 'left'}}>Date</th>
                              <th style={{width: '100px',textAlign: 'left'}}>Source</th>
                              <th style={{textAlign: 'left'}}>Coins</th>
                              <th style={{textAlign: 'left'}}>Title</th>
                              <th style={{textAlign: 'left'}}>Categories</th>
                              <th style={{textAlign: 'left'}}>Sentiment</th>
                            </tr>
                          </thead>
                          <tbody>
                          {data.items.map((d, i) => {
                              const date = moment.utc(d.publication_datetime).local();
                              const sentimentPref = user.preferences.sentiment_field;
                              let sentimentPrefValue = 0;

                              if(sentimentPref === "title_sentiment" && d.sentiment){
                                sentimentPrefValue = d.sentiment.title;
                              }else if(sentimentPref === "summary_sentiment" && d.sentiment){
                                sentimentPrefValue = d.sentiment.summary;
                              }else if(sentimentPref === "content_sentiment" && d.sentiment){
                                sentimentPrefValue = d.sentiment.content;
                              }
                              const perc = (d.sentiment && parseInt(sentimentPrefValue * 100, 10)) || 0;
                              return (
                                <tr onClick={() => {setShow(true); setItem(d);}} key={i}>
                                  <td style={{textAlign: 'left'}}>
                                    <div className="date">{date.format('DD MMM YYYY')}</div>
                                    <div className="hour">{date.format('HH:mm:ss')}</div>
                                  </td>
                                  <td style={{textAlign: 'left'}}>
                                    <a>{d.source}</a>
                                  </td>
                                  <td style={{textAlign: 'left'}}>
                                    {d.tags
                                      .filter(t => t.includes('COIN_'))
                                      .map(t => t.replace('COIN_', ''))
                                      .join(', ')}
                                  </td>
                                  <td style={{textAlign: 'left'}}>
                                    <div className="text">{d.title}</div>
                                  </td>
                                  <td style={{textAlign: 'left'}}>
                                    {d.tags
                                      .filter(t => t.includes('OTHER_'))
                                      .map(t => t.replace('OTHER_', ''))
                                      .join(', ')}
                                  </td>
                                  <td style={{textAlign: 'left', display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                    <div style={sentimentStyle(sentimentPrefValue)}>
                                      {perc}%
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>}
                        {!data.items.length && <p style={{color: "white"}}>No news found for the given filters...</p>}
                      </div>
                    </Loading>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                      {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                    </div>
                  </div>
                </div>
                <div style={{width: '20%'}} className="nap-flex-right" id="element-should-first">
                  <NewsFilter search={setFilters} />
                </div>
              </div>
            </div>
            <SummaryModal show={show} item={item} userPref={user.preferences.sentiment_field} cancel={() => setShow(false)}/>
          </section>
  );
};

const mapStateToProps = state => {
  return {
    meta: state.meta,
  };
};

export default connect(mapStateToProps)(News);
