import React, {useState} from 'react';

const StateBarRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    whitepaperUrl,
    row
  } = props;

  const getVolatiliyColor = () =>{
    if(row.volatility_category === "Low"){
      return <div className={`sb-number-inner`} style={{color:"rgb(2,244,2)"}}>{row.volatility_category?row.volatility_category:"-"}</div>;
    }else if(row.volatility_category === "Normal"){
      return <div className={`sb-number-inner`} style={{color:"rgb(14,131,18)"}}>{row.volatility_category?row.volatility_category:"-"}</div>;
    }else if(row.volatility_category === "High"){
      return <div className={`sb-number-inner`} style={{color:"rgb(244,192,2)"}}>{row.volatility_category?row.volatility_category:"-"}</div>;
    }else if(row.volatility_category === "Very High"){
      return <div className={`sb-number-inner`} style={{color:"#c11e0f"}}>{row.volatility_category?row.volatility_category:"-"}</div>;
    }
  }

  const value = getVolatiliyColor();


  return (
    <div className="state-bar-inner">
      <div className="sb-text-img">
        <div className="sb-img" style={{background: '#f2921b'}}>
          <img src={`https://files.cryptoindexseries.com/cis-files/${rowImage}`} alt="" />
        </div>
        <div className="sb-text">
          <div className="sb-lg-text">{symbol}</div>
          <div className="sb-sm-text">{name}</div>
        </div>
      </div>
      <div className="sb-numbers">
        <div className="sb-number">
          <div className="sb-number-head">Price({exchange})</div>
          <div className={`sb-number-inner ${rowClass}`}>{price}</div>
        </div>
        <div className="sb-number">
          <div className="sb-number-head">MKT CAP</div>
          <div className={`sb-number-inner`}>{marketCap}</div>
        </div>
        <div className="sb-number">
          <div className="sb-number-head">24h VOL</div>
          <div className="sb-number-inner">{vol24}</div>
        </div>
        <div className="sb-number">
          <div className="sb-number-head">24h LOW ({exchange})</div>
          <div className="sb-number-inner">{low24}</div>
        </div>
        <div className="sb-number">
          <div className="sb-number-head">24h HIGH ({exchange})</div>
          <div className="sb-number-inner">{high24}</div>
        </div>
        <div className="sb-number">
          <div className="sb-number-head">CHG (24h)</div>
          <div className={`sb-number-inner ${perClass}`}>{perTd}</div>
        </div>
        {/*yazıyı kırmızı yapmak için green-text yerine red-text eklenmesi ve eğer ok işareti aşağı doğru olacaksa down arrow yapmak yeterli*/}
        <div className="clearfix" />
      </div>



      <div className={"pull-right"} style={{"marginRight" : "2.5em"}}>
        <a target="_blank" rel="noopener noreferrer" href={`https://files.cryptoindexseries.com/cis-files/${whitepaperUrl}`}>
          <img className="actionable-img" src="/img/ic_document.png" alt="" style={{"height": "50px"}}/>
        </a>
      </div>
      <div className="sb-numbers pull-right" style={{paddingRight:"10px"}}>
        <div className="sb-number">
          <div className="sb-number-head">Volume flag</div>
          <div className={`sb-number-inner`}>{row.volume_category?row.volume_category:"-"}</div>
        </div>
        <div className="sb-number pull-right">
          <div className="sb-number-head">Volatility Flag</div>
          {value}
        </div>
      </div>
      <div className="clearfix" />
    </div>
  );
};

export default StateBarRow;
