import Links from "../../constants/Links";
import Api from "../index";
import CryptoJS from 'crypto-js' ;

// Path definitions
const PROFILE_PATH = "/user_profile"
const IMAGE_PATH = PROFILE_PATH + "/image"
const WT_PATH = (wt_id) => wt_id ? "/watchlist/" + wt_id : "/watchlist"
const ALERT_PATH = (a_id) => a_id ? "/alert/" + a_id : "/alert"
const FAVORITE_PATH = (wt_id) => WT_PATH(wt_id) + '/favorite'
const PAIR_PATH = (wt_id, p_id) => WT_PATH(wt_id) + (p_id ? "/watchlist_pair/" + p_id : "/watchlist_pair")
const KEY_PATH = (k_id) => k_id ? "/exchange/" + k_id : "/exchange"

const UserApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.BASE_USER_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.BASE_USER_URL)
    },
    postMPPromise(endpoint, body){
        return Api.postMPPromise(endpoint, body, Links.BASE_USER_URL)
    },
    putPromise(endpoint, body) {
        return Api.putPromise(endpoint, body, Links.BASE_USER_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.BASE_USER_URL)
    },

    /**
     * Reads user profile
     */
    getProfile() {
        return this.getPromise(PROFILE_PATH);
    },

    /**
     * Updates user profile
     *
     * @param newProfile Updates
     */
    updateProfile(newProfile) {
        return this.putPromise(PROFILE_PATH, newProfile);
    },
    /**
     * Delete user profile
     */
    deleteProfile(){
        return this.deletePromise(PROFILE_PATH);
    },

    /**
     * Upload user profile picture to repository
     *
     * @param file File to be uploaded
     */
    uploadProfilePic(file) {
        const formData = new FormData()
        formData.append("image", file);

        return this.postMPPromise(IMAGE_PATH, formData)

    },

    /**
     * Create alert record
     *
     * @param alert Alert to create
     */
    createAlert(alert) {
        return this.postPromise(ALERT_PATH(), alert);
    },

    /**
     * Update alert record with given ID
     *
     * @param alert_id ID of the alert to update
     * @param alert Alert record
     */
    updateAlert(alert_id, alert) {
        return this.putPromise(ALERT_PATH(alert_id), alert);
    },

    /**
     * Return alerts of the user
     */
    getAlerts() {
        return this.getPromise(ALERT_PATH());
    },

    /**
     * Delete alert record with given ID
     *
     * @param alert_id Alert ID
     */
    deleteAlert(alert_id) {
        return this.deletePromise(ALERT_PATH(alert_id));
    },

    /**
     * Return user's watchlist records (w/o pairs)
     */
    getWatchlists() {
        return this.getPromise(WT_PATH());
    },

    /**
     * Create watchlist record with given name
     *
     * @param wt_name
     */
    createWatchlist(wt_name) {
        const body = { name: wt_name }
        return this.postPromise(WT_PATH(), body);
    },

    /**
     * Delete watchlist with given ID
     *
     * @param wt_id watchlist ID
     */
    deleteWatchlist(wt_id) {
        return this.deletePromise(WT_PATH(wt_id));
    },

    /**
     * Update name of the watchlist with given ID
     *
     * @param wt_id Watchlist ID
     * @param wt_name New WT name
     */
    updateWatchlist(wt_id, wt_name) {
        const body = { name: wt_name };
        return this.putPromise(WT_PATH(wt_id), body);
    },

    /**
     * Set given watchlist as favorite
     *
     * @param wt_id Watchlist ID
     */
    setFavorite(wt_id) {
        return this.putPromise(FAVORITE_PATH(wt_id));
    },

    /**
     * Reads pairs for user's favorite pairs
     */
    getFavorite() {
        return this.getPromise(FAVORITE_PATH());
    },

    /**
     * Get pairs for given watchlist
     *
     * @param wt_id Watchlist ID
     */
    getPairs(wt_id) {
        return this.getPromise(PAIR_PATH(wt_id));
    },

    /**
     * Create pairs for given watchlist
     * @param wt_id Watchlist ID
     * @param from_sym From symbol
     * @param to_sym To symbol
     */
    createPair(wt_id, from_sym, to_sym) {
        const data = { from_sym: from_sym, to_sym: to_sym };
        return this.postPromise(PAIR_PATH(wt_id), data);
    },

    /**
     * Delete pair from watchlist
     *
     * @param wt_id Watchlist ID
     * @param p_id Pair ID
     */
    deletePair(wt_id, p_id) {
        return this.deletePromise(PAIR_PATH(wt_id, p_id));
    },

    /**
     * Create exchange key at repository
     *
     * @param key_name Name of the key
     * @param exchange Exchange of the key
     * @param exchange_code Code of the exchange
     * @param token Auth0 Token
     * @param key Key itself
     */
    createKey(key_name, exchange, exchange_code, token, key) {
        // Generate record
        const record = {
            key_name: key_name,
            exchange: exchange,
            exchange_code: exchange_code,
            key: this.encryptKey(JSON.stringify(key), token)
        };

        return this.postPromise(KEY_PATH(), record);
    },

    /**
     * Update key record, no need full update only sent fields will be updated. Don't send key again
     * if you are not updating key itself (e.g. update only name of connection)
     *
     * @param k_id Id of the key to update
     * @param key_name Name of the key
     * @param exchange Exchange of the key
     * @param exchange_code Code of the exchange
     * @param token Auth0 Token
     * @param key Key itself
     */
    updateKey(k_id, key_name, exchange, exchange_code, token, key) {
        // No need full update only sent fields will be updated
        const record = {}
        if (key_name) {
            record['key_name'] = key_name;
        }
        if (exchange) {
            record['exchange'] = exchange;
            record['exchange_code'] = exchange_code;
        }
        if (key) {
            // Update record
            record['key'] = this.encryptKey(JSON.stringify(record['key']), token);
        }

        return this.putPromise(KEY_PATH(k_id), record);
    },

    /**
     * Delete exchange key of the user
     *
     * @param k_id Key ID
     */
    deleteKey(k_id) {
        return this.deletePromise(KEY_PATH(k_id));
    },

    /**
     * Read user active key records. This will not contain actual keys, only key meta information (E.g. key_name, exchange)
     */
    readKeys() {
        return this.getPromise(KEY_PATH());
    },

    /**
     * Read actual key record. This request will include the key.
     *
     * @param k_id Key ID
     * @param token Auth0 Token
     */
    readKey(k_id, token) {
        return this.getPromise(KEY_PATH(k_id)).then(res => {
            // Set decoded value to result
            res['key'] = JSON.parse(this.decryptKey(res['key'], token));

            return res;
        });
    },

    /**
     * Encrypt key with AES
     *
     * @param key Key to encode
     * @param token Auth0 Token
     */
    encryptKey(key, token) {
        // Extract token parts
        const signature = token.split('.')[2]
        const iv = CryptoJS.enc.Utf8.parse(signature.slice(0, 16))
        const secret = CryptoJS.enc.Utf8.parse(signature.slice(0, 32))

        // Decrypt response
        return CryptoJS.AES.encrypt(key, secret, { iv:iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString();
    },

    /**
     * Decrypt key with AES
     *
     * @param key Key to decrypt
     * @param token Auth0 Token
     */
    decryptKey(key, token) {
        // Extract token parts
        const signature = token.split('.')[2]
        const iv = CryptoJS.enc.Utf8.parse(signature.slice(0, 16))
        const secret = CryptoJS.enc.Utf8.parse(signature.slice(0, 32))

        // Decrypt response
        return CryptoJS.AES.decrypt(key, secret, { iv:iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoJS.enc.Utf8);
    }
}

export default UserApi;

