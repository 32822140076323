import React from 'react';
import CoinList from './CoinList';
import CoinNews from './CoinNews';
import CoinSocialMedia from './CoinSocialMedia';
import {getAccessibleComponent, useAuth0} from "../../helpers/Auth0";
import CoinMarkets from "./CoinMarkets";
import {Switch, Route} from "react-router-dom";

const MenuContent = props => {
    const {row, selectedExchange, result, setResult, setExchange} = props;
    const {user} = useAuth0();

    return (
        <Switch>
            <Route path={"/coins/:coinSymbol/detail"} render={() => {
                const cl = <CoinList setExchange={setExchange} row={row} selectedExchange={selectedExchange}
                                     setResult={setResult} result={result} taxonomy2={"false"}/>
                return getAccessibleComponent(user, null, "coin_taxanomy", cl);
            }}/>
            <Route path={"/coins/:coinSymbol/coin-news"} render={() => {
                const cn = <CoinNews selectedExchange={selectedExchange} row={row}/>;
                return getAccessibleComponent(user, null, "coin_news", cn);
            }}/>
            <Route path={"/coins/:coinSymbol/taxonomy"} render={() => {
                const clm = <CoinList setExchange={setExchange} row={row} selectedExchange={selectedExchange}
                                      setResult={setResult} result={result} taxonomy2={"true"}/>;
                return getAccessibleComponent(user, null, "coin_taxanomy", clm);
            }}/>
            <Route path={"/coins/:coinSymbol/markets"} render={() => {
                const cm = <CoinMarkets coinRow={row}/>;
                return getAccessibleComponent(user, null, "coin_markets", cm);
            }}/>
            <Route path={"/coins/:coinSymbol/social-media"} render={() => {
                const clm = <CoinSocialMedia coin={row}/>;
                return getAccessibleComponent(user, null, "coin_taxanomy", clm);
            }}/>
        </Switch>
    );
};

export default MenuContent;
