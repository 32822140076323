import React, {useEffect, useState} from 'react';
import Api from '../../../api';
import Loading from '../../../components/Loading';
import {connect} from 'react-redux';
import TradingView from "../../../tradingview";
import Utils from "../../../helpers/Utils";

const isMobile = Utils.checkMobile();

const MobileOverview = props => {
  const {
    indexCode, // Which index are we working on
    exchange // Exchange information
  } = props;

  // News related to given index
  const [news, setNews] = useState([]);
  // Index data to show
  const [indexData, setIndexData] = useState(null);
  // Indicates if there are any data fetching happens
  const [loading, setLoading] = useState(true);
  // Indicates if the news laoding
  const [newsLoading, setNewsLoading] = useState(true);

  // Fetch/Refresh index data on code or exchange changes
  useEffect(() => {
    if (indexCode && exchange) {
      setLoading(true);
      Api.readIndexFull(indexCode, exchange.value)
          .then(res => {
            console.log("Test IDX", res);
            setIndexData(res);
            setLoading(false)
          });
    }
  }, [indexCode]);

  // Fetch/Refresh news data on index data change
  useEffect(() => {
    // Generate news tags from constituent list
    if (indexData) {
      const tags = indexData.current_constituents.map(r => 'COIN_' + r).join(',');
      setNewsLoading(true);
      // Fetch index news from API
      Api.searchNewsPost({tags: [tags]})
          .then(res => {
            setNews(res);
            setNewsLoading(false);
          });
    }
  }, [indexData]);

  return (
      isMobile?
          <Loading loading={loading}>
              <div style={{"width": "100%"}}>
                  <div style={{marginTop: "1vh", marginBottom: "1vh", display: "flex", flexFlow: "row wrap"}}>
                      <div style={{"padding": "0px", "display": "flex", "flexDirection": "column"}}>
                          <div className="gray-container sector-summary" style={{"height" : "inherit", "marginBottom" : "1vh", flex: "0 0 auto", marginTop: "0"}}>
                              <div className="section-header">Index Summary</div>
                              <div className="sector-summary-text" id="beauty-scroll6" tabIndex={1} style={{ color: "white", outline: 'none'}}>
                                  <div className="summary-text" dangerouslySetInnerHTML={{__html: indexData && indexData.index_info && indexData.index_info.description}} />
                                  {indexData && indexData.index_info && indexData.index_info.document_url &&
                                  <div className="sector-summary-text"><p>Full report can be accessed from <a target="_blank" rel="noopener noreferrer" href={indexData.index_info.document_url}>here</a></p></div>}
                              </div>
                              <div className="clearfix" />
                          </div>
                      </div>
                      <div  style={{paddingRight: "0px", paddingBottom: "0px", display: "flex", flexDirection: "column",width: "100%",paddingTop: "5px"}}>
                          <div className="chart-area">
                              <TradingView symbol={indexCode} exchange={exchange.value} type={'CISIDX'} latest_price={indexData && indexData.index_ticker && indexData.index_ticker.lst}/>
                          </div>
                      </div>
                  </div>
              </div>
          </Loading>
          :
    <Loading loading={loading}>
      <div className="container" style={{"width": "100%"}}>
        <div className={"row"} style={{marginTop: "1vh", marginBottom: "1vh", display: "flex", flexFlow: "row wrap"}}>
          <div className={"col-lg-4"} style={{"padding": "0px", "display": "flex", "flexDirection": "column"}}>
              <div className="gray-container sector-summary" style={{"height" : "inherit", "marginBottom" : "1vh", flex: "0 0 auto", marginTop: "0"}}>
                <div className="section-header">Index Summary</div>
                <div className="sector-summary-text" id="beauty-scroll6" tabIndex={1} style={{overflowY: 'scroll', color: "white", outline: 'none'}}>
                  <div className="summary-text" dangerouslySetInnerHTML={{__html: indexData && indexData.index_info && indexData.index_info.description}} />
                  {indexData && indexData.index_info && indexData.index_info.document_url &&
                  <div className="sector-summary-text"><p>Full report can be accessed from <a target="_blank" rel="noopener noreferrer" href={indexData.index_info.document_url}>here</a></p></div>}
                </div>
                <div className="clearfix" />
              </div>
          </div>
          <div className={"col-lg-8 indicex-chart"} style={{paddingRight: "0px", paddingBottom: "0px", display: "flex", flexDirection: "column"}}>
              <div className="chart-area" style={{"marginTop" : "0", "marginBottom" : "1vh", "height": "59vh"}}>
                <TradingView symbol={indexCode} exchange={exchange.value} type={'CISIDX'} latest_price={indexData && indexData.index_ticker && indexData.index_ticker.lst}/>
              </div>
          </div>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = state => {
  return {
    allCoins: (state.meta && state.meta.allCoins) || [],
  };
};

export default connect(mapStateToProps)(MobileOverview);
